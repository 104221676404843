import React, { useEffect, useState } from "react";

import { Skeleton } from "@material-ui/lab";
import { IClient, isOptimetriksRole } from "fieldpro-tools";
import _ from "lodash";

import { getClientAdditionalInfosAction } from "containers/clients/redux/actions";
import { useActions } from "hooks";
import { TViewMode } from "model/application/modal/CreateEditModal";

import InternalOwnerShip from "../InternalOwnerShip";

const ClientOwnersManager: React.FC<{
  viewMode: TViewMode;
  client: Partial<IClient>;
  onMultipleSelectChange: (value: any, name: string) => void;
  shouldMarkError: (key: string) => string | undefined;
}> = ({ client, onMultipleSelectChange, shouldMarkError, viewMode }) => {
  const getClientAdditionalInfos = useActions(getClientAdditionalInfosAction);

  const { id } = client;

  const [loading, setloading] = useState(true);
  const [ownersOptions, setOwners] = useState([] as any[]);

  useEffect(() => {
    (async () => {
      setloading(true);

      if (id) {
        const owners = await getClientAdditionalInfos(id);
        const internalOwnersOptions = _.filter(owners, (owner) =>
          isOptimetriksRole(owner.role)
        );

        setOwners(internalOwnersOptions);
      }

      setloading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    const skeletons = _.times(6, (i) => (
      <Skeleton key={String(i)} animation="wave" style={{ height: "60px" }} />
    ));
    return <>{skeletons}</>;
  }

  return (
    <InternalOwnerShip
      client={client}
      onMultipleSelectChange={onMultipleSelectChange}
      ownersOptions={ownersOptions}
      shouldMarkError={shouldMarkError}
      viewMode={viewMode}
    />
  );
};

export default ClientOwnersManager;
