import { useEffect, useState } from "react";

import {
  CUSTOM_FIELD_TYPE,
  IActivity as IBEActivity,
  IClient,
  IItemSafe as IBEItem,
  IList as IBEList,
  IMatrix,
  IMetaExpressionOptions,
  IMobileUser,
  mxolParams,
  scolParams,
} from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import { getSignedInUser } from "containers/authentication/redux/selector";
import { getCustomerById } from "containers/calendar/utils/getCustomerNameById";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { allListsSelector } from "containers/lists/redux/selectors";
import { allMobileUsersSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { allActivitiesSelector } from "containers/workflows/redux/selectors";
import prepareReportStateForBackend from "containers/workflows/subcategories/activities/utils/prepareReportStateForBackend";
import ICustomer from "model/entities/Customer";
import { IList } from "model/entities/List";
import {
  IActivity,
  IActivityReportInTable,
  IWorkflow,
} from "model/entities/Workflow";

interface IUseMetaExpressionOptions {
  report: IActivityReportInTable;
  activity: IActivity;
  workflow?: IWorkflow;
  customer?: ICustomer;
}

export const useCurrentUser = () => {
  const signedInUser = useSelector(getSignedInUser);
  const mobileUsers = useSelector(allMobileUsersSelector);

  const linkedMobileUser: IMobileUser | undefined = mobileUsers.find(
    (mu) => mu.email && mu.email === signedInUser.id
  ) as IMobileUser | undefined;
  return linkedMobileUser;
};

interface IGetMetaExpressionOptions {
  report: IActivityReportInTable;
  activity: IActivity;
  workflow?: IWorkflow;
  mobileUser?: IMobileUser;
  client?: IClient;
  activities?: IActivity[];
  lists?: IList[];
  customer?: ICustomer;
}

export const getMetaExpressionOptions = ({
  report,
  activity,
  workflow,
  mobileUser,
  client,
  activities,
  lists,
  customer,
}: IGetMetaExpressionOptions): IMetaExpressionOptions => {
  const scolParamsArray: scolParams[] = [];
  const mxolParamsArray: mxolParams[] = [];

  _.forEach(activity.questionnaire?.questions, (question) => {
    const answer = report[question.tag];
    const listId = question?.list_id;
    const list = listId ? _.find(lists, { id: listId }) : undefined;

    if (question.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST) {
      scolParamsArray.push({
        scolItem: answer?.value ?? answer,
        scolList: list as IBEList,
      });
    }
    if (question.type === CUSTOM_FIELD_TYPE.MATRIX_ON_LIST && question.matrix) {
      mxolParamsArray.push({
        matrix: question.matrix as IMatrix,
        mxolItems: list?.items as unknown as IBEItem[],
        tag: question.tag,
        scolParams: undefined, // Placeholder for future implementation
      });
    }
  });

  const activityReport = prepareReportStateForBackend(
    report,
    activity?.questionnaire.questions
  );

  const itemCustomer =
    customer ??
    (activityReport && lists
      ? getCustomerById(lists, activityReport._customer_id)
      : undefined);
  const listCustomer = lists?.find((list) => list.id === "customer");
  const newMetaExpressionOptions: IMetaExpressionOptions = {
    activity_report: activityReport,
    activity: activity as IBEActivity,
    activities: activities as IBEActivity[],
    workflow,
    mxolParams: mxolParamsArray,
    scolParams: scolParamsArray,
    user: mobileUser,
    client: client,
    ...(customer
      ? {
          customer: itemCustomer as any as IBEItem,
          listCustomer: listCustomer as IBEList,
        }
      : {}),
  };
  return newMetaExpressionOptions;
};

const useMetaExpressionOptions = ({
  report,
  activity,
  workflow,
  customer,
}: IUseMetaExpressionOptions) => {
  const [metaExpressionOptions, setMetaExpressionOptions] = useState({});
  const activities = useSelector(allActivitiesSelector);
  const lists = useSelector(allListsSelector);
  const signedInUser = useSelector(getSignedInUser);
  const mobileUsers = useSelector(allMobileUsersSelector);
  const client = useSelector(getSelectedClient);

  const mobileUser: IMobileUser | undefined = mobileUsers.find(
    (mu) => mu.email && mu.email === signedInUser.id
  ) as IMobileUser | undefined;
  useEffect(() => {
    const newMetaExpressionOptions = getMetaExpressionOptions({
      report,
      activity,
      workflow,
      mobileUser,
      client,
      activities,
      customer,
      lists,
    });
    setMetaExpressionOptions(newMetaExpressionOptions);
    return () => {};
  }, [
    report,
    activity,
    lists,
    activities,
    workflow,
    mobileUser,
    client,
    customer,
  ]);

  return { metaExpressionOptions };
};

export default useMetaExpressionOptions;
