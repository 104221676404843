import {
  IActivityReport as IBEActivityReport,
  IMetaExpressionOptions,
} from "fieldpro-tools";
import _ from "lodash";

import { getTagKey } from "components/Input/InputMatrix/utils/getQuestionColumns";
import { IListSchema } from "model/entities/List";
import { IActivityReportInTable, IQuestion } from "model/entities/Workflow";

import { resolveMetaExpressionInString } from "../../workflows/utils/metaResolver";

interface IResolveDefaultValuesME {
  schema: IQuestion[] | IListSchema[];
  report: IActivityReportInTable;
  metaExpressionOptions: IMetaExpressionOptions;
}

export const resolveReportDefaultValues = ({
  schema,
  report,
  metaExpressionOptions,
}: IResolveDefaultValuesME) => {
  // resolve any default fields with metaexpression, so that the resolved answer is saved to db
  // even if user did not make any changes, ie when the report field is undefined
  const resolvedReport: IActivityReportInTable = { ...report };

  _.each<IQuestion | IListSchema>(schema, (customField) => {
    const tagKey = getTagKey(customField);
    const fieldTag = customField[tagKey];

    if (resolvedReport[fieldTag] || !customField.default_value) {
      return;
    }

    const newValue = resolveMetaExpressionInString(
      // Right now, only activity report fields have ME allowed in default values and need to resolve
      // but list do not have ME in their default values
      customField.default_value,
      {
        ...metaExpressionOptions,
        // TODO: IBEActivityReport FORCED TYPE CODE SMELL
        // NOTE 1: Use resolved Report here in case later questions depend on previous ones
        // NOTE 2: We copy resolvedReport to avoid mutating the argument passed to resolveMetaExpressionInString (it breaks the unit test...)
        activity_report: _.clone(resolvedReport) as IBEActivityReport,
      }
    );

    resolvedReport[fieldTag] = newValue;
  });

  return resolvedReport;
};
