import { FunctionComponent } from "react";

import { Box, makeStyles } from "@material-ui/core";
import { INVOICED_BY, isOptimetriksRole } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import InputBoolean from "components/Input/InputBoolean";
import InputSelect from "components/Input/InputSelect";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import { getBillingType } from "containers/authentication/utils/clientStatus";
import { getSelectedClient } from "containers/clients/redux/selectors";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { isOptimetriksAdminRole } from "model/constants/profiles";
import { BILLING_TYPE, IClient } from "model/entities/Client";
import { getOptionsInputSelect } from "utils/getOptionsInputSelect";

import styles from "../styles";

const useStyles = makeStyles(styles);
export interface ICustomBillingTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  onInputChange: any;
  onSelectChange: any;
  shouldMarkError: any;
  should_create_stripe_id?: boolean;
  userRole: string;
}

export const CustomBillingTab: FunctionComponent<ICustomBillingTabProps> = ({
  id,
  viewMode = "CREATE",
  app_user_licenses,
  billing_owner_mail,
  stripe_customer_id,
  billing_type,
  onInputChange,
  onSelectChange,
  shouldMarkError,
  should_create_stripe_id,
  userRole,
  payments_overdue,
  invoiced_by,
}) => {
  const lang = useTranslations();
  const tabLang = lang.containers.clients.subCategories.clients.createEditModal;
  const classes = useStyles();

  const client = useSelector(getSelectedClient);

  const disableNumberOfLicenses =
    getBillingType(client) === BILLING_TYPE.AUTOMATED;

  const title = tabLang.inputLicenceNb.title;
  const tooltip = disableNumberOfLicenses ? tabLang.inputLicenceNb.tooltip : "";

  const label = lang.containers.clients.subCategories.clients.createEditModal;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gridRowGap={"20px"}
      marginBottom={"10px"}
      className={classes.container}
    >
      <InputText
        key={`app_user_licenses-${id}`}
        name="app_user_licenses"
        defaultValue={app_user_licenses}
        error={shouldMarkError("app_user_licenses")}
        type={INPUT_TEXT_TYPE.INTEGER}
        lang={{ title, tooltip }}
        onChange={onInputChange}
        required={true}
        viewMode={viewMode}
        disabled={disableNumberOfLicenses}
      />

      <InputText
        defaultValue={billing_owner_mail}
        name="billing_owner_mail"
        error={shouldMarkError("billing_owner_mail")}
        type={INPUT_TEXT_TYPE.EMAIL}
        lang={tabLang.inputBillingOwnerMail}
        onChange={onInputChange}
        required={false}
        viewMode={viewMode}
      />

      <InputSelect
        viewMode={viewMode}
        lang={tabLang.inputBillingType}
        name={"billing_type"}
        value={billing_type}
        options={_.map(Object.values(BILLING_TYPE), (type) => ({
          key: type,
          label: _.capitalize(type),
        }))}
        onChange={onSelectChange}
        disabled={!isOptimetriksAdminRole(client.profile)} // Disabled for now, only clients created through sign-up can be automated
      />

      {viewMode === "CREATE" && (
        <InputBoolean
          viewMode={viewMode}
          name="should_create_stripe_id"
          onChange={onInputChange}
          defaultValue={should_create_stripe_id}
          lang={tabLang.inputShouldCreateStripeCustomer}
          error={shouldMarkError("should_create_stripe_id")}
        />
      )}

      {((viewMode === "CREATE" && !should_create_stripe_id) ||
        (viewMode === "VIEW" && stripe_customer_id) ||
        viewMode === "EDIT") && (
        <InputText
          defaultValue={stripe_customer_id}
          name="stripe_customer_id"
          error={shouldMarkError("stripe_customer_id")}
          lang={tabLang.inputStripeCustomerId}
          onChange={onInputChange}
          required={false}
          viewMode={viewMode}
          replaceSpaces
        />
      )}

      <InputSelect
        viewMode={viewMode}
        lang={label.inputClientManagementInvoicedBy}
        name={"invoiced_by"}
        error={shouldMarkError("invoiced_by")}
        value={invoiced_by}
        options={getOptionsInputSelect({
          listKeys: Object.keys(INVOICED_BY),
          lang: label.inputClientManagementInvoicedBy.options,
        })}
        onChange={onSelectChange}
      />

      {isOptimetriksRole(userRole) && (
        <InputBoolean
          viewMode={viewMode}
          name="payments_overdue"
          onChange={onInputChange}
          defaultValue={payments_overdue}
          lang={label.inputPaymentsOverdue}
          label={label.inputPaymentsOverdue.title}
          dataTestId="Payments_Overdue"
          error={shouldMarkError("payments_overdue")}
        />
      )}
    </Box>
  );
};

export default CustomBillingTab;
