import { Action } from "redux";

import { TColumnsOrganized } from "redux/reducers/tableReducer";

import * as types from "../actionTypes/tableActionTypes";

export function cleanTableColumnAction(): Action {
  return {
    type: types.CLEAN_TABLE_COLUMNS_ORDER,
  };
}

export interface ISaveTableColumnsActionSuccess extends Action {
  organizedColumns: TColumnsOrganized;
}
export function setTableColumnsSuccess(
  organizedColumns: TColumnsOrganized
): ISaveTableColumnsActionSuccess {
  return {
    type: types.SET_TABLE_COLUMNS_ORDER,
    organizedColumns,
  };
}
