import _ from "lodash";

import { CUSTOM_FIELD_TYPE } from "model/entities/Workflow";

import { getCustomFieldAttributesInSchema } from "./getCustomFieldAttributesInSchema";
import { ICustomField } from "./getSchemaForMetaExpression";

interface IIsFieldWithOptions {
  field?: string;
  schema: ICustomField[];
  includeFieldsOnList?: boolean;
}

export const isFieldWithOptions = ({
  field,
  schema,
  includeFieldsOnList = true,
}: IIsFieldWithOptions) => {
  if (!field) {
    return false;
  }

  const customField = getCustomFieldAttributesInSchema(field, schema);

  let FIELDS_WITH_OPTIONS = [
    CUSTOM_FIELD_TYPE.SINGLE_CHOICE,
    CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE,
    CUSTOM_FIELD_TYPE.BOOLEAN,
  ];

  if (includeFieldsOnList) {
    FIELDS_WITH_OPTIONS = [
      ...FIELDS_WITH_OPTIONS,
      CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST,
      CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST,
    ];
  }

  return customField && _.includes(FIELDS_WITH_OPTIONS, customField?.type);
};

export const isFieldBoolean = ({ field, schema }: IIsFieldWithOptions) => {
  if (!field) {
    return false;
  }

  const customField = getCustomFieldAttributesInSchema(field, schema);
  return customField && customField?.type == CUSTOM_FIELD_TYPE.BOOLEAN;
};
