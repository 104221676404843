import { useEffect, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import { BasicDialog, BasicDialogContent } from "components/Dialog/BasicDialog";
import { allListsSelector } from "containers/lists/redux/selectors";
import PictureDetectionView from "containers/pictures/components/modals/PictureDetectionView";
import PictureDetectionViewLayout from "containers/pictures/components/modals/PictureDetectionViewLayout";
import PictureSideBar from "containers/pictures/components/modals/PictureSideBar";
import PictureSidebarHeader from "containers/pictures/components/modals/PictureSideBar/PictureSidebarHeader";
import { IImageDetectionDataFE } from "containers/pictures/components/modals/types";
import { getIdsFromDetections } from "containers/pictures/components/modals/utils";
import { fetchPictureDetectionsAction } from "containers/pictures/redux/actions";
import { useActions } from "hooks";
import TPicture from "model/entities/Picture";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    padding: "16px 24px",
    width: "80vw",
    height: "80vh",
  },
  leftContainer: {
    width: "65%",
    position: "relative",
    borderRadius: "8px",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  rightContainer: {
    width: "35%",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    overflow: "auto",
  },
});

interface IPictureGallerySlide {
  pictures: TPicture[];
  open: boolean;
  currentIndex: number;
  onArrowClick: (newIndex: number) => void;
  onModalClose: () => void;
}

function PictureGallerySlide({
  pictures,
  open,
  currentIndex = 0,
  onArrowClick,
  onModalClose,
}: IPictureGallerySlide) {
  const classes = useStyles();

  const fetchPicturesDetections = useActions(fetchPictureDetectionsAction);

  const nextPicture = () => {
    if (currentIndex === pictures.length - 1) {
      onArrowClick(0);
    } else {
      onArrowClick(currentIndex + 1);
    }
  };
  const previousPicture = () => {
    if (currentIndex === 0) {
      onArrowClick(pictures.length - 1);
    } else {
      onArrowClick(currentIndex - 1);
    }
  };

  const [displayDetections, setDisplayDetections] = useState(false);
  const [loadingDetections, setLoadingDetections] = useState(true);
  const [detections, setDetections] = useState<IImageDetectionDataFE[]>([]);

  const [activeBox, setActiveBox] = useState<string | undefined>(undefined);
  const [highlighedBoxes, sethighlighedBoxes] = useState<string[]>([]);

  const allLists = useSelector(allListsSelector);

  function handleChangeDisplayDetections(value: boolean) {
    setDisplayDetections(value);
  }

  useEffect(() => {
    if (pictures[currentIndex] !== undefined && pictures[currentIndex]) {
      (async () => {
        setLoadingDetections(true);
        const detectionData = await fetchPicturesDetections({
          id: pictures?.at(currentIndex)?.id || "_",
        });
        setDetections(detectionData);
        sethighlighedBoxes(getIdsFromDetections(detectionData));
        setLoadingDetections(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, allLists]);

  const galleryPicture = pictures[currentIndex];

  const picture = {
    ...galleryPicture,
    label: galleryPicture.label ?? "",
    tag: galleryPicture.tag ?? "",
  };

  return (
    <BasicDialog
      open={open}
      borderRadius={"8px"}
      minWidth={"80vw"}
      minHeight={"95vh"}
      maxHeight={"100vh"}
      maxWidth={"80vw"}
    >
      <BasicDialogContent className={classes.root}>
        <Box className={classes.leftContainer}>
          <PictureDetectionViewLayout
            onNextLoad={nextPicture}
            onPrevious={previousPicture}
          >
            <PictureDetectionView
              key={detections.length}
              activeDetectionBoxIds={_.compact([activeBox, ...highlighedBoxes])}
              displayDetections={displayDetections}
              detections={detections}
              picture={picture}
              imgProps={{
                height: "auto",
                width: "100%",
                style: {
                  objectFit: "contain",
                  maxWidth: "100%",
                  maxHeight: "calc(82vh - 32px)",
                  borderRadius: "8px",
                },
              }}
              onClick={(id) => {
                if (activeBox === id) {
                  setActiveBox(undefined);
                  sethighlighedBoxes(getIdsFromDetections(detections));
                } else {
                  setActiveBox(id);
                  sethighlighedBoxes([]);
                }
              }}
            />
          </PictureDetectionViewLayout>
        </Box>

        <Box className={classes.rightContainer}>
          <PictureSidebarHeader handleClose={onModalClose} url={picture?.url} />

          <PictureSideBar
            isLoadingScanResults={loadingDetections}
            toggleDisplayDetections={handleChangeDisplayDetections}
            picture={picture}
            detections={detections}
            activeBoxIds={_.compact([activeBox])}
            key={currentIndex}
          />
        </Box>
      </BasicDialogContent>
    </BasicDialog>
  );
}

export default PictureGallerySlide;
