import { IImageDetectionData } from "fieldpro-tools";
import _ from "lodash";

import TLang from "model/application/Lang";
import { IList } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";

import { IImageDetectionDataFE, TBoxPoints } from "./types";

export interface IConvertImageDetectionData {
  data: IImageDetectionData;
}
export function convertImageDetectionData({
  data,
}: IConvertImageDetectionData): IImageDetectionDataFE {
  return {
    ...data,
    boxes: (JSON.parse(data.boxes) as TBoxPoints[]).map((box, index) => ({
      id: `${data._id}-${index}`,
      points: box,
      product: data.product as IListItem,
      sku_id: data.sku_id,
    })),
  };
}
export const getCategoryLabel = (
  detectionList?: IList,
  box?: IImageDetectionDataFE["boxes"][0]
) => {
  const categoryKey = detectionList?.key_attribute_category || "_category";

  const categoriesOptions = _.find(
    detectionList?.schema,
    (s) => s.column_tag === categoryKey
  );

  const label = categoriesOptions?.options?.find(
    (o) => o.key === box?.product?.[categoryKey ?? "_category"]
  )?.label;

  return label;
};

export function getIdsFromDetections(detections: IImageDetectionDataFE[]) {
  return _.flatten(_.map(detections, (d) => _.map(d.boxes, (b) => b.id)));
}

export function getSkuIdFromDetections(
  detections: IImageDetectionDataFE[],
  idItem: string
): string | undefined {
  for (const detection of detections) {
    const boxWithIdItem = _.find(detection?.boxes, { id: idItem });
    if (boxWithIdItem) {
      return detection.sku_id;
    }
  }
  return undefined;
}

interface IGetDetectionCategoryLabel {
  detection: IImageDetectionDataFE;
  lang: TLang;
}
export const getDetectionCategoryLabel = ({
  detection,
  lang,
}: IGetDetectionCategoryLabel) => {
  const langKey =
    lang.containers.pictures.subCategories.pictures.createEditModal;
  const noCategoryLabel = langKey.scanResultsTab.customMessage.noCategory;

  return (
    detection?.boxes?.at(0)?.product?.categoryLabel ??
    detection?.product?._category ??
    detection?.product?.category ??
    noCategoryLabel
  );
};
