import { useEffect, useState } from "react";

import { DEFAULT_LISTS_IDS, LIST_SCOPE } from "fieldpro-tools";
import { useSelector } from "react-redux";

import { allListsSelector } from "containers/lists/redux/selectors";
import { teamsComposedSelector } from "containers/teams/redux/selectors";

const useCalendarScopeFilter = (mobileUser: string) => {
  const allLists = useSelector(allListsSelector);
  const customerList = allLists.find(
    (list) => list.id === DEFAULT_LISTS_IDS.CUSTOMER
  );
  const listScope = customerList?.scope;
  const teams = useSelector(teamsComposedSelector);

  const [teamsMobileUserIsIn, setTeamsMobileUserIsIn] = useState<string[]>([]);
  const [defaultFilter, setDefaultFilter] = useState<Record<string, any>>({});

  useEffect(() => {
    const teamsUserIsIn = teams.reduce((acc, team) => {
      const mobileUserIds = team.mobile_users.map((t) => t.key);

      if (mobileUserIds.includes(mobileUser)) {
        acc.push(team.id);
      }
      return acc;
    }, [] as string[]);
    setTeamsMobileUserIsIn(teamsUserIsIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(teams), mobileUser]);

  useEffect(() => {
    let filter;
    switch (listScope) {
      case LIST_SCOPE.SINGLE_TEAM:
      case LIST_SCOPE.TEAM:
        filter = { _owners: teamsMobileUserIsIn };
        break;
      case LIST_SCOPE.MOBILE_USER:
      case LIST_SCOPE.SINGLE_MOBILE_USER:
        filter = { _owners: [mobileUser] };
        break;
      default:
        filter = {};
        break;
    }
    setDefaultFilter(filter);
  }, [listScope, teamsMobileUserIsIn, mobileUser]);

  return defaultFilter;
};

export default useCalendarScopeFilter;
