import { IAccessRightProfile, PERSONA } from "fieldpro-tools";
import _ from "lodash";

import { FILTER_TAG } from "components/Filter/TypeFilter";
import { TABLE_COLUMN_TYPE, TColumnType } from "components/Table/model";
import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import { ITeamSelector } from "model/entities/Team";
import { IMobileUser, IWebUser } from "model/entities/User";
import getFullName from "utils/formatting/getFullName";

const BUSINESS_ROLE = "business_role";

type TUser = IWebUser | IMobileUser;

interface IGetFilteredUsers {
  teams: ITeamSelector[];
  users: IWebUser[] | IMobileUser[];
  filterParams: any;
  userType: "mobile" | "web";
  showDebugUser?: boolean;
}

const DEBUG_USER_EMAIL = "debug@optimetriks.com";

export const filterTeamsByLevels = (
  teams: ITeamSelector[],
  hierarchyFilters: string[]
): ITeamSelector[] => {
  return teams.filter((team) => {
    const levels: string[] = [];
    for (let i = 0; i <= 9; i++) {
      const level = team[`level_${i}`];
      if (level && !levels.includes(level)) {
        levels.push(level);
      }
    }
    return hierarchyFilters.some((filter) => levels.includes(filter));
  });
};

export const getPersonaOptions = (): IOption[] => {
  return _.map(_.values(PERSONA), (persona) => {
    return {
      key: persona,
      label: _.upperFirst(_.lowerCase(persona)),
    };
  });
};

export const getFilteredUsers = ({
  teams,
  users,
  filterParams,
  userType,
  showDebugUser = false,
}: IGetFilteredUsers) => {
  let result = users;
  if (!showDebugUser) {
    result = _.reject<TUser>(result, { email: DEBUG_USER_EMAIL });
  }

  if (
    !Object.keys(filterParams).includes("_id") &&
    !Object.keys(filterParams).includes(FILTER_TAG.TEAMS)
  ) {
    return result;
  }

  _.keys(filterParams).forEach((att) => {
    if (att === "_id") {
      result = _.filter<TUser>(result, (u) => u.id === filterParams["_id"]);
    }

    if (att === "_teams") {
      const teamIds: string[] = filterParams[FILTER_TAG.TEAMS];
      let userIdsInTeams: string[] = [];

      _.forEach(teamIds, (teamId) => {
        const teamSelector = _.find(teams, { id: teamId });
        if (!teamSelector) {
          return;
        }

        const usersInTeam =
          userType === "mobile"
            ? teamSelector.mobile_users
            : teamSelector.web_users;

        if (!usersInTeam) {
          return;
        }

        userIdsInTeams = _.union(
          userIdsInTeams,
          _.map(usersInTeam, (user) => user.key)
        );
      });

      result = _.filter<TUser>(result, (r) => _.includes(userIdsInTeams, r.id));
    }
  });

  return result;
};

/**
 * Creates column titles for table
 * @returns {Array}
 */
export const createMobileUserColumnTypes = (
  lang: TLang,
  showInternalId: boolean,
  showRoles: boolean
): TColumnType[] => {
  const labelToDisplay =
    lang.containers.users.subCategories.mobileUsers.columns;
  return [
    {
      name: "name",
      label: labelToDisplay.name,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
    ...[
      showInternalId
        ? {
            name: "internalId",
            label: labelToDisplay.internalId,
            type: TABLE_COLUMN_TYPE.TEXT,
          }
        : (undefined as any as TColumnType),
    ],
    {
      name: "phone",
      label: labelToDisplay.phone,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
    {
      name: "teamNb",
      label: labelToDisplay.teamNb,
      type: TABLE_COLUMN_TYPE.NUMBER,
    },
    {
      name: "licensed",
      label: labelToDisplay.license,
      type: TABLE_COLUMN_TYPE.SWITCH,
    },
    {
      name: "persona",
      label: labelToDisplay.persona,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
    ...[
      showRoles
        ? {
            name: "role",
            label: labelToDisplay.role,
            type: TABLE_COLUMN_TYPE.TEXT,
          }
        : (undefined as any as TColumnType),
    ],
    {
      name: "appVersion",
      label: labelToDisplay.appVersion,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
    {
      name: "lastPullSync",
      label: labelToDisplay.lastPullSync,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
    {
      name: "lastPushSync",
      label: labelToDisplay.lastPushSync,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
  ].filter((e) => e);
};
export const createMobileUserFilterTypes = (
  lang: TLang,
  showInternalId: boolean,
  showRoles: boolean,
  mobileUsers: IMobileUser[]
): TColumnType[] => {
  const labelToDisplay =
    lang.containers.users.subCategories.mobileUsers.columns;
  return [
    {
      name: "name",
      label: labelToDisplay.name,
      type: TABLE_COLUMN_TYPE.MULTIPLE_CHOICE,
      options: getNameOptions(mobileUsers),
    },
    ...[
      showInternalId
        ? {
            name: "internalId",
            label: labelToDisplay.internalId,
            type: TABLE_COLUMN_TYPE.TEXT,
          }
        : (undefined as any as TColumnType),
    ],
    {
      name: "phone",
      label: labelToDisplay.phone,
      type: TABLE_COLUMN_TYPE.MULTIPLE_CHOICE,
      options: getOptions(mobileUsers, "phone"),
    },
    {
      name: "teamNb",
      label: labelToDisplay.teamNb,
      type: TABLE_COLUMN_TYPE.NUMBER,
    },
    {
      name: "licensed",
      label: labelToDisplay.license,
      type: TABLE_COLUMN_TYPE.SWITCH,
    },
    {
      name: "persona",
      label: labelToDisplay.persona,
      type: TABLE_COLUMN_TYPE.MULTIPLE_CHOICE,
      options: getPersonaOptions(),
    },

    ...[
      showRoles
        ? {
            name: "role",
            label: labelToDisplay.role,
            type: TABLE_COLUMN_TYPE.MULTIPLE_CHOICE,
            options: getOptions(mobileUsers, "role"),
          }
        : (undefined as any as TColumnType),
    ],
    {
      name: "appVersion",
      label: labelToDisplay.appVersion,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
    {
      name: "lastPullSync",
      label: labelToDisplay.lastPullSync,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
    {
      name: "lastPushSync",
      label: labelToDisplay.lastPushSync,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
  ].filter((e) => e);
};

const checkBusinessRoleOnAllWebUser = (webUsers: IWebUser[]) => {
  let bool = false;
  _.forEach(webUsers, (user) => {
    if (
      user[BUSINESS_ROLE] === null ||
      user[BUSINESS_ROLE] === "" ||
      user[BUSINESS_ROLE] === "no-options" ||
      user[BUSINESS_ROLE] === undefined
    ) {
      bool = true;
    } else {
      bool = false;
    }
  });
  return bool;
};

/**
 * Creates column titles for table
 * @returns {Array}
 */
export const createWebUserColumnTypes = (
  lang: TLang,
  webUsers: IWebUser[]
): TColumnType[] => {
  const langKey = lang.containers.users.subCategories.webUsers;
  const checkBusinessRole: boolean = checkBusinessRoleOnAllWebUser(webUsers);
  return [
    {
      name: "name",
      label: langKey.columns.name,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
    {
      name: "email",
      label: langKey.columns.email,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
    {
      name: "role",
      label: langKey.columns.role,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
    {
      name: "business_role",
      label: langKey.columns.businessRole,
      type: TABLE_COLUMN_TYPE.TEXT,
      hidden: checkBusinessRole,
    },
    {
      name: "last_login",
      label: langKey.columns.lastLogin,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
  ];
};

export const createWebUserFilterTypes = (
  lang: TLang,
  webUsers: IWebUser[]
): TColumnType[] => {
  const langKey = lang.containers.users.subCategories.webUsers;
  return [
    {
      name: "name",
      label: langKey.columns.name,
      type: TABLE_COLUMN_TYPE.MULTIPLE_CHOICE,
      options: getNameOptions(webUsers),
    },
    {
      name: "email",
      label: langKey.columns.email,
      type: TABLE_COLUMN_TYPE.MULTIPLE_CHOICE,
      options: getOptions(webUsers, "email"),
    },
    {
      name: "role",
      label: langKey.columns.role,
      type: TABLE_COLUMN_TYPE.MULTIPLE_CHOICE,
      options: getOptions(webUsers, "role"),
    },
    {
      name: "last_login",
      label: langKey.columns.lastLogin,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
  ];
};

const getOptions = (
  webUsers: IWebUser[],
  field: keyof IWebUser | keyof IMobileUser
): IOption[] => {
  return _(webUsers)
    .map(field as string)
    .uniq()
    .compact()
    .map((value) => {
      return {
        key: value,
        label: value,
      };
    })
    .value();
};

const getNameOptions = (webUsers: Array<IWebUser | IMobileUser>): IOption[] => {
  return _(webUsers)
    .map((webuser) => {
      const value = webuser.first_name + " " + webuser.last_name;
      return {
        key: value,
        label: value,
      };
    })
    .value();
};

export const getMobileUserName = (
  id: string,
  mobileUsers: IMobileUser[]
): string => {
  const user = _.find(mobileUsers, { id });
  return getFullName(user);
};

export const getPersonaByUserRole = (
  userRole: string,
  mobileUserBusinessRoles: IAccessRightProfile[]
): string | undefined => {
  const userPersona = mobileUserBusinessRoles?.find(
    (profile) => profile.name === userRole
  );
  return userPersona?.persona ?? PERSONA.SALES_REPRESENTATIVE;
};

export const filterMobileUserPerPersona = (
  mobileUsers: IMobileUser[],
  mobileAccessRightProfiles: IAccessRightProfile[],
  persona: PERSONA
): IMobileUser[] => {
  return mobileUsers.filter((u) => {
    const role = mobileAccessRightProfiles?.find((acc) => acc.name === u.role);
    if (role) {
      if (role.persona === persona) {
        return true;
      }
    }
  });
};

export const getMobileUsersAlreadyLinkedWithMobileUsers = (
  currMobileUserId: string,
  mobileUsers: IMobileUser[]
) => {
  return _.uniq(
    mobileUsers
      .filter((m) => m.id !== currMobileUserId)
      .reduce((acc, curr) => {
        const newUsers = [
          // there is no real need to discriminate between drivers and sales reps here.
          // we just want to know the links
          ...(curr.assigned_drivers ?? []),
          ...(curr.assigned_sales_reps ?? []),
        ] as string[];
        return [...acc, ...newUsers];
      }, [] as string[])
  );
};
