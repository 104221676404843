import { __ME_STEP_CURRENT_WORKFLOW__, STEP_TYPE } from "fieldpro-tools";

import { useTranslations } from "hooks";
import { formatString } from "lang/utils";
import { IWorkflow } from "model/entities/Workflow";

import InputBoolean from "../InputBoolean";
import InputSelect from "../InputSelect";

/**
 * Second step (facultative, only if we are in a workflow report scope): step selection
 */
export interface IMetaExpressionWizardSecondStepProps {
  step?: string;
  shouldMarkError: any;
  labelToDisplay: any;
  currentWorkflow?: IWorkflow;
  onChange: (value: any, name: string) => void;
}

export const MetaExpressionWizardSecondStep: React.FunctionComponent<
  IMetaExpressionWizardSecondStepProps
> = ({ step, shouldMarkError, labelToDisplay, currentWorkflow, onChange }) => {
  const buildOptionsFromWorkflowSteps = () => {
    const options: any[] = [];
    if (!currentWorkflow || currentWorkflow.steps.length === 0) {
      return [];
    }

    currentWorkflow.steps
      .filter((ws) =>
        [
          STEP_TYPE.ACTIVITY,
          STEP_TYPE.EXPORT_ACTIVITY_REPORT_PDF,
          STEP_TYPE.HTTP_REQUEST,
          STEP_TYPE.TRANSFORMATION,
          STEP_TYPE.STREAM,
          STEP_TYPE.SCRIPT,
          STEP_TYPE.CREATE_ITEM,
          STEP_TYPE.EDIT_ITEM,
        ].includes(ws.type)
      )
      .forEach((ws) => {
        options.push({
          key: ws.id,
          label: ws.title,
        });
      });
    return options;
  };

  const disable = step === __ME_STEP_CURRENT_WORKFLOW__;

  function handleChange(value: boolean, name: string) {
    if (value) {
      onChange(__ME_STEP_CURRENT_WORKFLOW__, name);
    } else {
      onChange(undefined, name);
    }
  }

  const lang = useTranslations();
  const langKey =
    lang.containers.workflows.subCategories.metaExpressions.createEditModal
      .inputSteps.customMessage;

  return (
    <div>
      <InputBoolean
        dataTestId="input-current-workflow"
        name="step"
        required
        onChange={handleChange}
        lang={{
          title: langKey.targetWorkflowField,
        }}
        defaultValue={disable}
      />

      <InputSelect
        dataTestId="input-workflow-steps"
        required
        viewMode="CREATE"
        error={shouldMarkError("step")}
        name="step"
        lang={labelToDisplay.inputStep}
        options={buildOptionsFromWorkflowSteps()}
        value={step == __ME_STEP_CURRENT_WORKFLOW__ ? undefined : step}
        onChange={onChange}
        disabled={disable}
        disabledReason={
          disable
            ? formatString(langKey.disabledWorkflowStepsMessage, [
                `'${langKey.targetWorkflowField}'`,
              ])
            : undefined
        }
      />
    </div>
  );
};
