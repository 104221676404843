import { useState } from "react";

import { Fade, makeStyles, TextField } from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import _ from "lodash";

import { Black } from "assets/colors";
import SmallIconButton from "components/Buttons/SmallIconButton";
import { IOption } from "model/application/components";

import InputBaseLayout from "../../InputBaseLayout";
import { IInputBaseLayout } from "../../InputBaseLayout/InputBaseLayout";
import styles from "../../styles";
import { IInputMultipleSelectOnListProps } from "../InputMultipleSelectOnList";
import ChipContainer from "./ChipContainer";
import MultipleOptionsOnListSelector, {
  IMultipleOptionsOnListSelectorProps,
} from "./MultipleOptionsOnListSelector";

const useStyles = makeStyles(styles as any);

export interface IMultipleOptionsSelectionDropdownMenu
  extends Pick<
      IInputMultipleSelectOnListProps,
      "options" | "langlabel" | "multipleSelection" | "defaultFilter"
    >,
    Pick<IMultipleOptionsOnListSelectorProps, "showFilter">,
    IInputBaseLayout {
  selectedOptions: IOption[];
  onAddOptions: (options: IOption[]) => void;
  onRemoveOptions: (options: IOption[]) => void;
  onCancel: () => void;
  onSave: (options?: IOption[]) => void;
  onClose: () => void;
  defaultValue?: IOption[];
  listId: string;
}

function InputMultipleSelectOnListBase({
  options,
  langlabel,
  selectedOptions,
  onAddOptions,
  onRemoveOptions,
  error,
  disabled,
  viewMode,
  onSave,
  onCancel,
  multipleSelection,
  listId,
  onClose,
  showFilter,
  defaultFilter,
  ...inputBaseLayoutProps
}: Readonly<IMultipleOptionsSelectionDropdownMenu>) {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);

  const chipTextFieldClass = !_.isEmpty(selectedOptions)
    ? classes.chipTextField
    : classes.chipTextFieldEmpty;

  const endAdornment = (
    <SmallIconButton
      data-testid="open-menu-button"
      size="small"
      aria-label="arrow-icon"
      onClick={() => {
        setOpenMenu(!openMenu);
      }}
      style={{
        height: "37px",
        width: "37px",
      }}
    >
      {openMenu ? (
        <ArrowDropUpIcon htmlColor={Black} />
      ) : (
        <ArrowDropDownIcon htmlColor={Black} />
      )}
    </SmallIconButton>
  );

  return (
    <InputBaseLayout
      {...inputBaseLayoutProps}
      key={JSON.stringify(options)}
      label={langlabel?.title}
      tooltip={langlabel?.tooltip}
      viewMode={viewMode}
      error={error}
      disabled={disabled}
      dataTestId="MultipleOptionsSelectionDropdownMenu"
    >
      <div>
        <TextField
          fullWidth
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
          variant="outlined"
          className={chipTextFieldClass}
          InputProps={{
            startAdornment: (
              <ChipContainer
                langlabel={langlabel}
                onRemoveOption={(option) => onRemoveOptions([option])}
                selectedOptions={selectedOptions}
              />
            ),
            inputComponent: () => <div />,
            endAdornment: endAdornment,
          }}
        />
        {openMenu && (
          <Fade in>
            <MultipleOptionsOnListSelector
              handleCancel={() => {
                onCancel();
                setOpenMenu(false);
              }}
              handleClose={() => {
                if (onClose) {
                  onClose();
                }
                setOpenMenu(false);
              }}
              handleSave={() => {
                if (onSave) {
                  onSave();
                }
                setOpenMenu(false);
              }}
              onAddOptions={(options) => {
                onAddOptions(options);
                if (!multipleSelection && onSave) {
                  onSave(options);
                  setOpenMenu(false);
                }
              }}
              onRemoveOptions={onRemoveOptions}
              listId={listId}
              selectedOptions={selectedOptions}
              multipleSelection={multipleSelection}
              showFilter={showFilter}
              defaultFilter={defaultFilter}
            />
          </Fade>
        )}
      </div>
    </InputBaseLayout>
  );
}

export default InputMultipleSelectOnListBase;
