import { Query } from "fieldpro-tools";
import _ from "lodash";

import * as currLang from "lang";
import TLang from "model/application/Lang";

const defaultLang = currLang["en"];

//this is necessary to convert code back into something tagify can understand
//it will go through the code and find any query pointer like $queryName$
//then format it into tagify format = [[value : something,...anykey]]
//this is necessary because tagify will not accept $queryName$ as a tag

export function replaceQueryPointersWithTags(
  code: string,
  queries: Query[],
  lang: TLang = defaultLang
) {
  if (!code) return "";
  const queryPointerRegex = /\$[\w\s-]+\$/gm; //matches $queryName$ or $query-name$ or $query name$
  code.match(queryPointerRegex)?.forEach((m) => {
    const queryName = m.replaceAll("$", "");
    const query = _.find(queries, {
      name: queryName,
    });

    if (query) {
      code = code.replaceAll(m, JSON.stringify(convertQueryToTag(query, lang)));
    }
  });
  return code;
}

export function convertQueryToTag(query: Query, lang: TLang = defaultLang) {
  const queryGenericTerms = lang.genericTerms.query;
  const { disabled, verified } = query;
  let status = "";

  if (disabled) {
    status = ` (${queryGenericTerms?.deactivated.title ?? "Deactivated"})`;
  }

  if (verified) {
    status = ` (${queryGenericTerms?.verified.title ?? "Verified"})`;
  }

  return [
    [
      {
        ...query,
        value: `${query?.name}${status}`,
      },
    ],
  ];
}
