import { FunctionComponent, useEffect, useState } from "react";

import "react-phone-input-2/lib/style.css";

import { FormHelperText, InputBaseProps, makeStyles } from "@material-ui/core";
import _ from "lodash";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";

import useDebouncedCallback from "hooks/useDebouncedCallback";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { isEmptyValue } from "utils/isEmptyValue";

import InputBaseLayout, {
  IInputBaseLayout,
} from "../InputBaseLayout/InputBaseLayout";
import { getCurrentCountryCode } from "../InputText/getCurrentCountryCode";
import InputViewValue from "../InputViewValue";
import styles from "../styles";

export const useStyles = makeStyles(styles as any);

export interface IInputPhone
  extends Omit<
      InputBaseProps,
      "onChange" | "lang" | "error" | "value" | "margin"
    >,
    Pick<IInputBaseLayout, "highlightContent"> {
  defaultValue?: string | number;
  onChange: (value: any, name: string) => void;
  lang?: {
    title?: string;
    tooltip?: string;
  };
  debounceDuration?: number;
  error?: string;
  required?: boolean;
  label?: string;
  viewStacked?: boolean;
  name: string;
  onBlur?: () => void;
  placeholder?: string;
  viewMode?: TViewMode;
  warning?: string;
  disabled?: boolean;
}

export const InputPhone: FunctionComponent<IInputPhone> = ({
  viewMode = "CREATE",
  name,
  defaultValue,
  debounceDuration = 0,
  highlightContent,
  warning,
  onChange,
  disabled = false,
  label,
  viewStacked,
  error,
  required,
  lang,
  onBlur,
  placeholder = "",
}) => {
  const formatValue = (value?: string | number) =>
    isEmptyValue(value) ? "" : String(value);

  const classes = useStyles();
  const initValue = formatValue(defaultValue);
  const [value, setValue] = useState<string>(initValue);
  const [currentCountry, setCurrentCountry] = useState("");

  useEffect(() => {
    if (!value) {
      (async function () {
        const countryCode = await getCurrentCountryCode();
        setCurrentCountry(_.toLower(countryCode));
      })();
    }
  }, [value]);

  const debouncedOnChange = useDebouncedCallback(
    onChange,
    debounceDuration,
    []
  );

  const onChangeFinal = debounceDuration ? debouncedOnChange : onChange;

  const handleChangePhoneInput: PhoneInputProps["onChange"] = (
    valuePhoneInput,
    _country,
    _e,
    _valueFormatted: string
  ) => {
    const newValue = valuePhoneInput ? `+${valuePhoneInput}` : "";
    setValue(newValue);
    if (onChangeFinal) {
      onChangeFinal(newValue, name);
    }
  };

  return (
    <InputBaseLayout
      disabled={disabled}
      highlightContent={highlightContent}
      label={lang ? lang.title : label}
      tooltip={lang?.tooltip}
      required={required}
      viewMode={viewMode}
      error={error}
      viewStacked={viewStacked}
      htmlFor={name}
    >
      {viewMode === "VIEW" ? (
        <InputViewValue value={value || placeholder} />
      ) : (
        <div className={classes.PhoneInput}>
          {" "}
          <PhoneInput
            placeholder={placeholder}
            enableSearch
            onBlur={onBlur}
            disabled={disabled}
            disableSearchIcon
            value={value ? _.toString(value) : ""}
            onChange={handleChangePhoneInput}
            searchPlaceholder="Search country"
            country={currentCountry}
            masks={{
              ci: ".. .. ... ...",
              sn: ".. ... .. ..",
            }}
            inputProps={{
              name: name,
            }}
            enableLongNumbers={currentCountry === "ar"}
          />
        </div>
      )}

      {_.isEmpty(error) && warning && viewMode !== "VIEW" && (
        <WarningMessage warning={warning} />
      )}
    </InputBaseLayout>
  );
};

const WarningMessage = ({ warning }: { warning?: string }) => (
  <FormHelperText
    style={{
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: "1.25",
      color: "#fbc715",
    }}
  >
    <span className="material-icons-outlined md-18">warning</span>
    {warning}
  </FormHelperText>
);

export default InputPhone;
