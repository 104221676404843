import { Box, Button, makeStyles, Theme } from "@material-ui/core";
import { Styles } from "@material-ui/core/styles/withStyles";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { IItemSafe as IBEItem, IList as IBEList } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import { Background, GreyLight } from "assets/colors";
import InputCustomField from "components/Input/InputCustomField/InputCustomField";
import { getTagKey } from "components/Input/InputMatrix/utils/getQuestionColumns";
import TabPlaceHolder from "components/Tab/TabPlaceHolder";
import { TRowType } from "components/Table/model";
import { allListsSelector } from "containers/lists/redux/selectors";
import { uploadActivityReportOrWorkflowFileAction } from "containers/workflows/redux/actions";
import useActions from "hooks/useActions";
import useFormState, { IFormState } from "hooks/useFormState";
import useTranslations from "hooks/useTranslations";
import { IList, IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import { IQuestion } from "model/entities/Workflow";

import { mapUploadedFilesToFormAnswers } from "./utils";
import { refreshMatrixComputeValues } from "./utils/refreshMatrixComputeValues";
import { resolveMatrixDefaultValues } from "./utils/resolveMatrixDefaultValues";
import { uploadMatrixFiles } from "./utils/uploadMatrixFiles";

const styles: Styles<Theme, {}, string> = {
  topBar: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "start",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.1);",
    height: "40px",
  },
  itemDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    gap: "8px",
    alignItems: "center",
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
  },
  image: {
    width: "48px",
    height: "48px",
    objectFit: "cover",
  },
  itemName: {
    fontSize: "16px",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    maxWidth: "inherit",
    width: "auto",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  container: {
    display: "grid",
    gridTemplateRows: "50px auto 50px",
    height: "inherit",
    overflow: "hidden",
  },
  submission: {
    display: "flex",
    flexDirection: "row",
    padding: "8px",
    gap: "8px",
    background: Background,
    justifyItems: "end",
    justifyContent: "end",
  },
};

const useStyles = makeStyles(styles);

export interface IMatrixEditorDrawerProps {
  list: IList;
  /** Full list item to be able to resolve meta expressions on it if needed */
  item?: IListItem;
  rowState?: IMatrixRowState;
  onCancel: () => void;
  onValidate: (answers: IMatrixAnswerForm) => void;
}

export interface IMatrixRowState {
  /** Id of the item the row is representing */
  id: string;
  /** Name of the item the row is representing */
  name: string;
  /** Picture of the item the row is representing */
  image?: string;
  customFields: IQuestion[] | IListSchema[];
  defaultAnswers?: TRowType;
}

export interface IMatrixAnswerForm {
  [key: string]: string | object | number | boolean | undefined;
}

function MatrixEditorDrawer({
  item,
  rowState,
  list,
  onCancel,
  onValidate,
}: IMatrixEditorDrawerProps) {
  const lang = useTranslations();
  const classes = useStyles();

  const formState = useFormState<IMatrixAnswerForm>({
    initAttributes: rowState?.defaultAnswers ?? {},
  });

  const inputMatrixEditorDrawer =
    lang.containers.workflows.subCategories.activities.createEditModal
      .inputMatrixEditorDrawer;

  const lists = useSelector(allListsSelector);

  const uploadFilesFunction = useActions(
    uploadActivityReportOrWorkflowFileAction
  );

  const handleValidation = async () => {
    const metaExpressionOptions = {
      list: list as IBEList,
      item: item as unknown as IBEItem,
      item_id: item?.id,
      matrix_answers: formState.attributes,
    };

    const customFields: IQuestion[] | IListSchema[] | undefined =
      rowState?.customFields;

    // We treat this matrix row "validation" like a intermediary form submission
    // (Even though no endpoint is called)
    let updatedMatrixValues: TRowType = rowState?.defaultAnswers ?? {};

    // 1. Upload files
    updatedMatrixValues = await updateMatrixWithUploadFiles(
      updatedMatrixValues,
      formState
    );

    // 2. Resolve default values, so they can be shown in the table
    updatedMatrixValues = resolveMatrixDefaultValues({
      schema: customFields ?? [],
      matrixAnswers: updatedMatrixValues,
      metaExpressionOptions,
    });

    // 3. Refresh computed values based on the default values
    updatedMatrixValues = refreshMatrixComputeValues({
      schema: customFields as IQuestion[],
      matrixAnswers: updatedMatrixValues,
      metaExpressionOptions,
    });

    // 4. Call the parent callback to validate the form
    onValidate(updatedMatrixValues);

    // 5. Reset the local form state
    formState.resetChanges();
  };

  const updateMatrixWithUploadFiles = async (
    matrixAnswers: TRowType,
    formState: IFormState<IMatrixAnswerForm>
  ) => {
    const uploadedMatrixFiles = await uploadMatrixFiles({
      filesToUpload: formState.filesToUpload,
      uploadFilesFunction,
      metadata: {},
    });

    // TODO: this part needs refactoring:
    // 1. Don't call handleInputChange within mapUploadedFilesToFormAnswers ?
    // 2. The map should already return the merged object { ...matrixAnswers, ...matrixAnswersWithUploadFiles } ?
    const matrixAnswersWithUploadFiles = mapUploadedFilesToFormAnswers({
      formState,
      uploadedFiles: uploadedMatrixFiles,
    });

    return {
      ...matrixAnswers,
      ...matrixAnswersWithUploadFiles,
    };
  };

  return (
    <Box className={classes.container} data-testid="MatrixEditorDrawer">
      <Box className={classes.topBar}></Box>
      {rowState ? (
        <Box padding={"8px"} style={{ overflowY: "auto", overflowX: "hidden" }}>
          <Box className={classes.itemDetails}>
            {rowState.image ? (
              <Box>
                <img src={rowState.image} className={classes.image} />
              </Box>
            ) : null}
            <Box className={classes.itemName}>{rowState.name}</Box>
          </Box>

          <Box className={classes.formSection}>
            {_.map<IQuestion | IListSchema>(
              rowState.customFields,
              (customField: IQuestion | IListSchema) => {
                if (customField.deprecated) return null;

                const tagKey = getTagKey(customField);
                const linkedList = _.find(lists, { id: customField.list_id });
                return (
                  <InputCustomField
                    key={`field-${rowState.id}-${customField[tagKey]}`}
                    viewMode={"CREATE"}
                    formState={formState}
                    customField={customField}
                    answer={formState.attributes[customField?.[tagKey]]}
                    list={linkedList}
                    metaExpressionOptions={{
                      list: list as IBEList,
                      item: item as unknown as IBEItem,
                      item_id: item?.id,
                      matrix_answers: formState.attributes,
                    }}
                  />
                );
              }
            )}
          </Box>
        </Box>
      ) : (
        <TabPlaceHolder
          description={
            inputMatrixEditorDrawer.customErrorMessages
              .noProductSelectedDescription
          }
          title={
            inputMatrixEditorDrawer.customErrorMessages.noProductSelectedTitle
          }
          icon={<ShoppingCartOutlinedIcon />}
          iconSize={"96px"}
          iconColor={GreyLight}
        />
      )}

      {rowState ? (
        <Box className={classes.submission}>
          <Box>
            <Button
              disableElevation
              color="secondary"
              variant="outlined"
              onClick={onCancel}
            >
              {lang.genericTerms.cancel}
            </Button>
          </Box>
          <Box>
            <Button
              onClick={handleValidation}
              disableElevation
              color="secondary"
              variant="contained"
            >
              {lang.genericTerms.validate}
            </Button>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

export default MatrixEditorDrawer;
