import _ from "lodash";

export const formatNumber = (num?: number, separator?: string) => {
  const numCast = _.toNumber(num);
  if (!_.isFinite(numCast) || _.isNaN(numCast) || !_.isNumber(numCast)) {
    return "";
  }

  return numCast.toString().replace(/^[+-]?\d+/, function (int) {
    return int.replace(/(\d)(?=(\d{3})+$)/g, `$1${separator}`);
  });
  // explanations here https://stackoverflow.com/a/58436483/6627882
  // return numCast.toString().replace(/^[+-]?\d+/, function (int) {
  //   return int.replace(/(\d)(?=(\d{3})+$)/g, "$1 ");
  // });
};
