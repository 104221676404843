import React, { useState } from "react";

import TableBody from "@material-ui/core/TableBody";
import _ from "lodash";

import { useTranslations } from "hooks/useTranslations";
import { ITableAction } from "model/application/Table";

import { TColumnType, TRowAction } from "../model";
import SyntheticRow from "../SyntheticRow";
import CustomFilterRow from "./CustomFilterRow";
import { checkFilterValueLength } from "./CustomTable";
import CustomTableRow from "./CustomTableRow";
import { getRowId } from "./CustomTableRow.utils";

export interface ICustomTableBodyProps {
  rowData: any[];
  columnTypes: TColumnType[];
  onDetailItem?: TRowAction;
  onClickItemRow?: TRowAction;
  onStopExecution?: TRowAction;
  onResumeExecution?: TRowAction;
  onContinueExecution?: TRowAction;
  onArchiveItem?: TRowAction;
  onRestoreItem?: TRowAction;
  onReplicateItem?: TRowAction;
  onEditItem?: TRowAction;
  onDeleteItem?: TRowAction;
  onViewItem?: TRowAction;
  extraActions?: ITableAction[];
  rowsPerPage?: number;
  page?: number;
  emptyMessage?: string;
  onTableRowClick?: TRowAction;
  hasCheckBox?: boolean;
  checkedRows?: string[];
  onCheckChanged?: (i: string, isChecked: boolean) => void;
  onSwitchChanged?: TRowAction;
  showSyntheticRow?: boolean;
  clickableRows?: boolean;
  attrToHide?: string[];
  onOpenPicture?: TRowAction;
  onOpenGPS?: (e: { lat: number; lng: number }) => void;
  columnsForBorder?: number;
  progressData?: any[];
  filterParams?: any;
  dbTotalRows?: number;
  handleClearFilters?: () => void;
  totalCount: number;
  dataCount: number;
  scrollValue?: {
    x: number;
    y: number;
  };
}

const CustomTableBody: React.FunctionComponent<ICustomTableBodyProps> = ({
  rowData,
  page = 0,
  columnTypes,
  rowsPerPage = 10000,
  onDetailItem,
  onClickItemRow,
  onStopExecution,
  onResumeExecution,
  onContinueExecution,
  onViewItem,
  onEditItem,
  onDeleteItem,
  onReplicateItem,
  onRestoreItem,
  onArchiveItem,
  extraActions,
  onTableRowClick,
  hasCheckBox = true,
  onCheckChanged = () => true,
  onSwitchChanged = () => true,
  checkedRows = [],
  showSyntheticRow = false,
  clickableRows = false,
  attrToHide = [],
  onOpenPicture,
  onOpenGPS,
  columnsForBorder = 0,
  progressData = [],
  scrollValue,
  filterParams,
  handleClearFilters,
  totalCount,
  dataCount,
  dbTotalRows = 0,
}) => {
  const [filterAlert, setFilterAlert] = useState(true);
  const lang = useTranslations();

  const handleFilterAlert = () => {
    setFilterAlert(false);
  };

  const pageRows = rowData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <TableBody>
      {filterAlert && checkFilterValueLength(filterParams).length > 0 && (
        <CustomFilterRow
          filteredCount={dataCount ?? 0}
          totalCount={totalCount}
          onClearFilters={handleClearFilters}
          columnTypes={columnTypes}
          onFilterAlert={handleFilterAlert}
        />
      )}

      {_.map(pageRows, (row, index) => {
        return (
          <CustomTableRow
            // NOTE: index as key can lead to performance issues; but some rows might not have an _id,
            // or even worse, _id may not be unique (if it represents a foreign key for instance...)
            // See https://optimetriks.atlassian.net/browse/FP-6170
            key={index}
            lang={lang}
            checked={_.includes(checkedRows, getRowId(row))}
            clickable={clickableRows}
            columnTypes={columnTypes}
            rowItem={row}
            onStopExecution={onStopExecution}
            onResumeExecution={onResumeExecution}
            onContinueExecution={onContinueExecution}
            onDetailItem={onDetailItem}
            onArchiveItem={onArchiveItem}
            onRestoreItem={onRestoreItem}
            onReplicateItem={onReplicateItem}
            onDeleteItem={onDeleteItem}
            onEditItem={onEditItem}
            onViewItem={onViewItem}
            onClickItemRow={onClickItemRow}
            extraActions={extraActions}
            onClick={onTableRowClick}
            hasCheckBox={hasCheckBox}
            onCheckChanged={onCheckChanged}
            onSwitchChanged={onSwitchChanged}
            attrToHide={attrToHide}
            onOpenPicture={onOpenPicture}
            onOpenGPS={onOpenGPS}
            columnsForBorder={columnsForBorder}
            progressData={progressData}
            scrollValue={scrollValue}
          />
        );
      })}

      {showSyntheticRow && dbTotalRows <= 5000 ? (
        <SyntheticRow
          columnTypes={columnTypes}
          rowData={rowData}
          hasCheckBox={hasCheckBox}
          lang={lang}
        />
      ) : null}
    </TableBody>
  );
};

export default CustomTableBody;
