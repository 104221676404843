import { DETAILED_ACTION_CODE } from "fieldpro-tools";
import moment from "moment";
import { push } from "react-router-redux";
import { bindActionCreators, Dispatch } from "redux";

import apiService from "api/apiService";
import { prepareClientForFrontend } from "containers/clients/redux/utils";
import * as lang from "lang";
import { cleanTableColumnAction } from "redux/actionCreators/tableActionCreator";
import { IDispatchAndGetState } from "redux/store/model";

import { HOME_ROUTE, LOGIN_ROUTE } from "../../../model/constants/routes";
import {
  ajaxErrorAction,
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../redux/actionCreators/ajaxActionCreator";
import { firstAppLoginAction } from "../../../redux/actionCreators/appActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../redux/actions/appActions";
import { setSelectedClientActionCreator } from "../../clients/redux/actionCreators";
import { showNotificationActionCreator } from "../../notifications/actionCreator";
import * as notificationLevels from "../../notifications/actionLevels";
import * as notificationTypes from "../../notifications/actionTypes";
import { TBackendNewTrialUser } from "../utils/prepareNewTrialUserForBackend";
import {
  changeLanguageSuccessAction,
  changePasswordBeginAction,
  changePasswordFailureAction,
  changePasswordSuccessAction,
  checkLastVersionActionSuccess,
  displaySwitchToWebMessageAction,
  loginRequestBeginAction,
  loginRequestFailureAction,
  loginRequestSuccessAction,
  logoutErrorAction,
  logoutRequestBeginAction,
  logoutSuccessAction,
  recoverPasswordBeginAction,
  recoverPasswordFailureAction,
  recoverPasswordSuccessAction,
  setAccessRightProfileSuccessAction,
  setActionsAvailableSuccessAction,
  SignUpRequestBeginAction,
  SignUpRequestFailureAction,
  SignUpRequestSuccessAction,
  stopRetryWaitAction,
} from "./actionCreator";
import {
  changePasswordApiCall,
  checkUpdateApiCall,
  googleLoginUserApiCall,
  ILoginUserApiCall,
  loginUserApiCall,
  logoutUserApiCall,
  recoverPasswordApiCall,
  SignUpUserApiCall,
} from "./authApi";
import { getLang, getLastCheckVersion } from "./selector";
interface ISignUpActionOptions {
  isSmallScreen?: boolean;
}
export function signupAction(
  userInfo: TBackendNewTrialUser,
  options?: ISignUpActionOptions
) {
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(SignUpRequestBeginAction());
    return SignUpUserApiCall(DETAILED_ACTION_CODE.Signup, userInfo)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        dispatch(
          SignUpRequestSuccessAction({
            id: data.id,
          })
        );
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.clients.subCategories.clients.createEditModal
              .trial.customMessage.accountCreatedSuccessfully
          )
        );
        loginAction(
          userInfo.email,
          userInfo.password,
          true,
          options?.isSmallScreen
        )(dispatch, getState).catch((e: any) => {
          alert(`Error in the signup action: ${e.message}`);
        });
      })
      .catch((err) => {
        dispatch(ajaxErrorAction(err, err.message));
        dispatch(SignUpRequestFailureAction(err));
        treatErrorNotification(
          dispatch,
          "SignUpAction",
          err,
          SignUpRequestFailureAction,
          currLang,
          false
        );
      });
  };
}

/**
 * Login Action which will be exposed to the Authentication container
 * This will be used to login the user given the user email and the user password
 * @param {String} userEmail User email
 * @param {String} userPassword Users's password
 * @param {Boolean} keepSignedIn, Whether to keep the user signed in
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function loginAction(
  userEmail: string,
  userPassword: string,
  keepSignedIn = true,
  displaySwitchToWebMessage = false
) {
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(loginRequestBeginAction());

    return loginUserApiCall(DETAILED_ACTION_CODE.Login, userEmail, userPassword)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const genericLogin = bindActionCreators(genericLoginAction, dispatch);
        dispatch(displaySwitchToWebMessageAction(displaySwitchToWebMessage));
        return genericLogin(data, keepSignedIn);
      })
      .catch((err) => {
        dispatch(ajaxErrorAction(err, err.message));
        dispatch(
          loginRequestFailureAction({
            error: err,
            default_error_message:
              currLang.containers.login.subCategories.login.error
                .wrongInformationProvided,
          })
        );
      });
  };
}

/**
 * Google SSO Login Action which will be exposed to the Authentication container
 * This will be used to login the user given the token given by google to client
 * @param {String} googleToken User token retrieved from google
 * @param {Boolean} keepSignedIn, Whether to keep the user signed in
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function googleLoginAction(googleToken: string, keepSignedIn = true) {
  return (dispatch: Dispatch) => {
    dispatch(ajaxRequestAction());
    dispatch(loginRequestBeginAction());
    return googleLoginUserApiCall(DETAILED_ACTION_CODE.GoogleLogin, googleToken)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const genericLogin = bindActionCreators(genericLoginAction, dispatch);
        return genericLogin(data, keepSignedIn);
      })
      .catch((err) => {
        dispatch(ajaxErrorAction(err, err.message));
        dispatch(loginRequestFailureAction(err));
      });
  };
}

const genericLoginAction = (data: ILoginUserApiCall, keepSignedIn: boolean) => {
  return (dispatch: Dispatch) => {
    const {
      token,
      id,
      name,
      created_at,
      picture,
      client,
      is_temporary: shouldChangePassword,
    } = data;

    dispatch(ajaxSuccessAction());
    dispatch(
      loginRequestSuccessAction(
        token,
        id,
        name,
        picture,
        created_at,
        id,
        keepSignedIn,
        shouldChangePassword
      )
    );

    const formattedClient = prepareClientForFrontend(client);
    dispatch(setSelectedClientActionCreator(formattedClient));

    dispatch(push(HOME_ROUTE));
  };
};

/**
 * Logs out a given user given their id
 * dispatches actions to redux store facilitating the process
 * */
export function logoutAction() {
  apiService.setClientId("");
  apiService.setToken("");
  return (dispatch: Dispatch) => {
    dispatch(ajaxRequestAction());
    dispatch(logoutRequestBeginAction());

    return logoutUserApiCall()
      .then((serverResponse: any) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(logoutSuccessAction());
        dispatch(cleanTableColumnAction());
        dispatch(setSelectedClientActionCreator(undefined));
        dispatch(push(LOGIN_ROUTE));
        // set this to true when the user logs out, this will ensure that the
        // isFirstLogin value is set to true for displaying welcom message
        dispatch(firstAppLoginAction(true));
      })
      .catch((error: any) => {
        dispatch(ajaxErrorAction(error, "LogoutError"));
        dispatch(logoutErrorAction(error));
        dispatch(push(LOGIN_ROUTE));
      });
  };
}

/**
 * Password Recovery Action which will be exposed to the Authentication container
 * This will be used to reset the password for the email provided
 * @param {String} userEmail User email
 * @param {String} token The token returned by reCAPTCHA
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function recoverPasswordAction(userEmail: string, token: string) {
  return (dispatch: Dispatch) => {
    dispatch(ajaxRequestAction());
    dispatch(recoverPasswordBeginAction());
    return recoverPasswordApiCall(
      DETAILED_ACTION_CODE.PasswordRequest,
      userEmail,
      token
    )
      .then((serverResponse: any) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(recoverPasswordSuccessAction());
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            `Succesfully recover the password`
          )
        );
      })
      .catch((err: any) => {
        dispatch(ajaxErrorAction(err, err.message));
        dispatch(recoverPasswordFailureAction(err));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_ERROR,
            notificationLevels.NOTIFICATION_LEVEL_ERROR,
            `Failed to recover the password`
          )
        );
      });
  };
}

/**
 * Edit profile Action which will be exposed to the Authentication container
 * This will be used to edit profile informations (such as password)
 * @param {String} password New password
 * @param {String} oldPassword Old password
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function changePasswordAction(password: string, oldPassword: string) {
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(changePasswordBeginAction());
    return changePasswordApiCall(
      DETAILED_ACTION_CODE.ChangePassword,
      password,
      oldPassword
    )
      .then((serverResponse: any) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(changePasswordSuccessAction());
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            `Succesfully changed password`
          )
        );
      })
      .catch((err: any) => {
        dispatch(ajaxErrorAction(err, err.message));
        dispatch(changePasswordFailureAction(err));

        const errorMessageDetails = err.message;
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_ERROR,
            notificationLevels.NOTIFICATION_LEVEL_ERROR,
            currLang.containers.login.subCategories.login.error
              .errorInPasswordUpdating,
            errorMessageDetails
          )
        );
      });
  };
}

export function changeLanguage(lang: string) {
  moment.locale(lang);
  return (dispatch: Dispatch) => {
    dispatch(changeLanguageSuccessAction(lang));
  };
}

export function resetRetryWait() {
  return (dispatch: Dispatch) => {
    dispatch(stopRetryWaitAction());
  };
}
export function setActionsAvailableAction(actionsAvailable: any) {
  return (dispatch: Dispatch) => {
    dispatch(setActionsAvailableSuccessAction(actionsAvailable));
  };
}

export function setAccessRightProfileAction(accessRightProfile: any) {
  return (dispatch: Dispatch) => {
    dispatch(setAccessRightProfileSuccessAction(accessRightProfile));
  };
}

/**
 * Check update Action
 * This will be used to login the user given the user email and the user password
 * @param {String} version
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function checkUpdateAction(
  version: string
): IDispatchAndGetState<boolean> {
  return (dispatch, getState) => {
    dispatch(ajaxRequestAction());
    return new Promise(function (resolve, reject) {
      // A bit dirty fix to ensure this endpoint is not spammed too much in case of infinity loop (otherwise it'll block my gitlab account...)
      if (
        new Date(getLastCheckVersion(getState())).getTime() >
        new Date(Date.now() - 5000).getTime()
      ) {
        reject(`Not allowed to spam this endpoint`);
      } else {
        checkUpdateApiCall(version)
          .then((serverResponse) => {
            const data = extractDataAndCheckErrorStatus(serverResponse);
            const { has_update } = data;
            dispatch(checkLastVersionActionSuccess(has_update));
            resolve(has_update);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };
}
