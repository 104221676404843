import { FormHelperText, makeStyles } from "@material-ui/core";
import _ from "lodash";

import safeStringify from "utils/formatting/safeStringify";

const styles = () =>
  ({
    errorMessage: {
      fontSize: "14px",
      "& > span": {
        verticalAlign: "middle",
        marginRight: "4px",
      },
    },
  } as const);

const useStyles = makeStyles(styles);

const ErrorMessage = ({ error }: { error?: string }) => {
  const classes = useStyles();

  if (_.isEmpty(error)) {
    return null;
  }

  // Avoid bugs with object errors...
  if (_.isObject(error)) {
    error = safeStringify(error);
  }

  return (
    <FormHelperText
      error
      className={classes.errorMessage}
      data-testid="input-error"
    >
      <span className="material-icons-outlined md-18">error</span>
      {error}
    </FormHelperText>
  );
};

export default ErrorMessage;
