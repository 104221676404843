/**
 * @notes: Api wrapper to communicate with apiService to handle api calls for Client account management
 */
import {
  DETAILED_ACTION_CODE,
  IBillingOwner,
  IClient as IBEClient,
  IClientBilling,
  IClientLicensePlan,
  IClientSupportPack,
  ILicensePlan,
  ILicensePlanPrice,
  ISupportPack,
  ISupportPackPrice,
  PACK_ID,
  PLAN_ID,
  TPaymentMethod,
} from "fieldpro-tools/dist/src/types";

import apiService from "api/apiService";
import { IClient, TLightClient } from "model/entities/Client";
import { IServerResponse } from "redux/actions/appActions";

import * as urls from "./endpoints";
import { prepareClientForBackend } from "./utils";

/**
 * Creates a Client account by making a POST request to api with the given parameters
 * @param {Object} body details of the new created client
 * @return {Promise}
 * */
export type TCreateClientResponse = {
  actionName: string;
  id: string;
  dbname: string;
};
export type TCreateClientFunc = (
  actionName: string,
  body: IClient
) => Promise<IServerResponse<TCreateClientResponse>>;
export const createClientApiCall: TCreateClientFunc = (actionName, body) => {
  return apiService.post(
    urls.CLIENT_ENDPOINT,
    prepareClientForBackend(body),
    actionName
  );
};

/**
 * Replicates a Client account by making a POST request to api with the given parameters
 * @param {Object} body details of the new created client
 * @return {Promise}
 * */
export type TReplicateClientResponse = {
  actionName: string;
  id: string;
  dbname: string;
};
export type TReplicateClientFunc = (
  actionName: string,
  old_client_id: string,
  new_client_name: string
) => Promise<IServerResponse<TReplicateClientResponse>>;
export const replicateClientApiCall: TReplicateClientFunc = (
  actionName,
  old_client_id,
  new_client_name
) => {
  return apiService.post(
    `${urls.CLIENT_ENDPOINT}/replicate`,
    {
      old_client_id,
      new_client_name,
    },
    actionName
  );
};

/**
 * Fetch a single client api call
 * @param {String} id If of the client to fetch from API
 * @return {Promise}
 * */
export type TFetchClientResponse = { client: IBEClient };
export type TFetchClientFunc = (
  id: string
) => Promise<IServerResponse<TFetchClientResponse>>;
export const fetchClientApiCall: TFetchClientFunc = (id) => {
  return apiService.get(`${urls.CLIENT_ENDPOINT}/${id}`);
};

/**
 * Fetch all clients api call
 * @return {Promise}
 * */
export type TFetchAllClientsResponse = {
  clients: TLightClient[];
};

export type TFetchAllClientsFunc = () => Promise<
  IServerResponse<TFetchAllClientsResponse>
>;

export const fetchAllClientsApiCall: TFetchAllClientsFunc = () => {
  return apiService.get(urls.CLIENT_ENDPOINT);
};

interface ILightTemplateClient {
  id: string;
  name: string;
  dbname: string;
}
export type TLightTemplateClients = Array<ILightTemplateClient>;
export type TFetchAllTemplateClientsReponse = {
  template_clients: TLightTemplateClients;
};
export type TFetchAllTemplateClientsFunc = () => Promise<
  IServerResponse<TFetchAllTemplateClientsReponse>
>;
export const fetchAllTemplateClientsApiCall: TFetchAllTemplateClientsFunc =
  () => {
    return apiService.get(`${urls.CLIENT_ENDPOINT}/template`);
  };

/**
 * Updates the client
 * @param {String} id Id of client to update
 * @param {Object} body Detail of the client updated
 * @return {Promise}
 * */
export type TUpdateClientFunc = (
  actionName: string,
  id: string,
  body: IClient
) => Promise<IServerResponse<{ write_time: string }>>;
export const updateClientApiCall: TUpdateClientFunc = (
  actionName: string,
  id,
  body
) => {
  return apiService.patch(
    `${urls.CLIENT_ENDPOINT}/${id}`,
    prepareClientForBackend(body),
    actionName
  );
};

/**
 * Force the maintenance
 * @param {String} id Id of client to set live
 * @return {Promise}
 * */
export type TSetForceMaintenanceFunc = (
  actionName: string,
  id: string,
  runAll?: boolean
) => Promise<IServerResponse<{ write_time: string; dbname: string }>>;
export const setForceMaintenanceApiCall: TSetForceMaintenanceFunc = (
  actionName: string,
  id,
  runAll
) => {
  return apiService.post(
    `${urls.CLIENT_ENDPOINT}/${id}/force_maintenance`,
    runAll ? { run_all: runAll } : {},
    actionName
  );
};

/**
 * Set the client to live mode
 * @param {String} id Id of client to set live
 * @return {Promise}
 * */
export type TSetClientLiveFunc = (
  id: string
) => Promise<IServerResponse<{ write_time: string; dbname: string }>>;
export const setClientLiveApiCall: TSetClientLiveFunc = (id) => {
  return apiService.post(
    `${urls.CLIENT_ENDPOINT}/${id}/go_live`,
    {},
    DETAILED_ACTION_CODE.GoLive
  );
};

/**
 * Delete Client API call makes a POST request to delete a single Client
 * @param {String} id Id of the Client to delete
 * @return {Promise}
 * */
export type TDeleteClientFunc = (
  actionName: string,
  id: string
) => Promise<IServerResponse<{}>>;
export const deleteClientApiCall: TDeleteClientFunc = (
  actionName: string,
  id
) => {
  return apiService.delete(`${urls.CLIENT_ENDPOINT}/${id}`, {}, actionName);
};

/**
 * Archive client api call
 * @param {String} clientId Client id
 */
export type TArchiveClientFunc = (
  actionName: string,
  clientId: string
) => Promise<IServerResponse<{}>>;
export const archiveClientApiCall: TArchiveClientFunc = (
  actionName,
  clientId
) => {
  return apiService.patch(
    `${urls.CLIENT_ENDPOINT}/${clientId}/archive`,
    {},
    actionName
  );
};

/**
 * Restore client api call
 * @param {String} clientId Client id
 */
export type TRestoreClientFunc = (
  actionName: string,
  clientId: string
) => Promise<IServerResponse<{}>>;
export const restoreClientApiCall: TRestoreClientFunc = (
  actionName,
  clientId
) => {
  return apiService.patch(
    `${urls.CLIENT_ENDPOINT}/${clientId}/restore`,
    {},
    actionName
  );
};

/**
 * Upload image
 * @param {}
 * @returns {Promise}
 */
export type TUploadFileResponse = {
  uploaded: boolean;
  urls: { filename: string; url: string }[];
};
export type TUploadFileFunc = (
  actionName: string,
  file: any,
  fileName: string
) => Promise<IServerResponse<TUploadFileResponse>>;
export const uploadFileApiCall: TUploadFileFunc = (
  actionName,
  file,
  fileName
) => {
  return apiService.multipartUpdate(
    `/upload/client`,
    [{ file, fileName }],
    {},
    actionName
  );
};

/**
 * get the client stripe session link
 * @return {Promise}
 * */
export type TGetStripeSessionLinkFunc = (
  clientId: string
) => Promise<IServerResponse<any>>;
export const getStripeSessionLinkApiCall: TGetStripeSessionLinkFunc = (
  clientId
) => {
  return apiService.get(`clients/${clientId}/get_session_link`);
};

export type TGetStripeInvoicesFunc = (parameters?: {
  client_id: string;
  starting_after?: string;
}) => Promise<IServerResponse<any>>;

export const getStripeInvoicesApiCall: TGetStripeInvoicesFunc = (parameters?: {
  client_id: string;
  starting_after?: string;
}) => {
  return apiService.get(`/billing/invoices`, { ...parameters });
};

/**
 * Get the readonly postgres db user
 * @return {Promise}
 * */
export type TGetDBAccFunc = (id: string) => Promise<IServerResponse<any>>;
export const getDBAccessCall: TGetDBAccFunc = (id: string) => {
  return apiService.get(`clients/${id}/dbuser`);
};

/**
 * Get the ftp user
 * @return {Promise}
 * */
export type TGetFTPAccessFunc = (id: string) => Promise<IServerResponse<any>>;
export const getFTPAccessCall: TGetFTPAccessFunc = (id: string) => {
  return apiService.get(`clients/${id}/ftp_credentials`);
};

/**
 * Get additionnal info for a client (the potential owners)
 * @return {Promise}
 * */
export type TGetClientAdditionalInfosApiCall = (
  clientId: string
) => Promise<IServerResponse<{ owners: IOwner[] }>>;

export const getClientAdditionalInfosApiCall: TGetClientAdditionalInfosApiCall =
  (clientId: string) => {
    return apiService.get(`clients/${clientId}/additionnal_infos`);
  };

interface IOwner {
  name?: string;
  role?: string;
  email?: string;
}

/**
 * get the sales/operations owners
 * @param {String} clientId id of the client to fetch from API
 * @return {Promise}
 * */
export type TFetchOwnersFunc = (
  clientId: string
) => Promise<IServerResponse<any>>;

export const fetchOwnersApiCall: TFetchOwnersFunc = (clientId) => {
  return apiService.get(`/owners/${clientId}`);
};

/****************************************************************************
 * Client Plans, Subscriptions and Billing
 ****************************************************************************/

export type TCreateCheckoutSession = ({
  clientId,
}: // numberOfLicenses,
// priceId,
{
  clientId: string;
  withTrial?: boolean;
  // numberOfLicenses: number;
  // priceId: string;
}) => Promise<IServerResponse<{ url: string }>>;

/**
 * Create a Stripe checkout session
 */
export const createCheckoutSessionApiCall: TCreateCheckoutSession = ({
  clientId,
  withTrial,
  // numberOfLicenses,
  // priceId,
}) => {
  return apiService.post(
    `/clients/${clientId}/billing/create_checkout_session`,
    {
      with_trial: withTrial,
      // number_of_licenses: numberOfLicenses,
      // stripe_price_id: priceId,
    },
    DETAILED_ACTION_CODE.CreateSubscription
  );
};

/**
 * Create the Client's subscription, with a plan, plan options and optional pack,
 * billing owner details, and payment details.
 */
export const createSubscriptionApiCall: TCreateSubscriptionFunc = (
  clientId,
  subscriptionDetails
) => {
  return apiService.post(
    `/clients/${clientId}/billing/create_subscription`,
    subscriptionDetails,
    DETAILED_ACTION_CODE.CreateSubscription
  );
};

export type TCreateSubscriptionFunc = (
  clientId: string,
  subscriptionDetails: ISubscriptionDetails
) => Promise<IServerResponse<{ billing: IClientBilling }>>;

export type TDowngradeToStarterFunc = (
  clientId: string,
  atPeriodEnd?: boolean
) => Promise<IServerResponse<{ success: boolean }>>;

/**
 * Downgrade the client to starter
 * - switch the status to "starter"
 * - destroy all the "Pro" customisations (TODO)
 */
export const downgradeToStarterApiCall: TDowngradeToStarterFunc = (
  clientId
) => {
  return apiService.post(
    `/clients/${clientId}/billing/downgrade_to_starter`,
    {},
    "DOWNGRADE_TO_STARTER"
  );
};

export type TCancelSubscriptionFunc = (
  clientId: string,
  atPeriodEnd?: boolean
) => Promise<IServerResponse<{ success: boolean }>>;

/**
 * Cancel the Client's subscription
 */
export const cancelSubscriptionApiCall: TCancelSubscriptionFunc = (
  clientId,
  atPeriodEnd = false
) => {
  return apiService.post(
    `/clients/${clientId}/billing/cancel_subscription`,
    {
      at_period_end: atPeriodEnd,
    },
    DETAILED_ACTION_CODE.CancelSubscription
  );
};

export type TResumeSubscriptionFunc = (
  clientId: string
) => Promise<IServerResponse<{ success: boolean }>>;

export const resumeSubscriptionApiCall: TResumeSubscriptionFunc = (
  clientId
) => {
  return apiService.post(
    `/clients/${clientId}/billing/stop_cancel_subscription`,
    {},
    DETAILED_ACTION_CODE.ResumeSubscription
  );
};

export interface ISubscriptionDetails {
  license_plan: {
    id: string;
    price: ILicensePlanPrice;
    number_of_licenses: number;
  };
  support_pack?: {
    id: string;
    price: ISupportPackPrice;
  };
  payment_method: TPaymentMethod;
  owner: IBillingOwner;
}

export const getApiKeyApiCall: TReturnApiKey = () => {
  return apiService.get(`/apikeys`);
};

export const createApiKeyApiCall: TReturnApiKey = () => {
  return apiService.post(`/apikeys`);
};

export const updateApiKeyApiCall: TReturnApiKey = () => {
  return apiService.patch(`/apikeys`);
};

export const deleteApiKeyApiCall: TReturnApiKey = () => {
  return apiService.delete(`/apikeys`);
};

export type TReturnApiKey = () => Promise<IServerResponse<{ apiKey: string }>>;

/**
 * Fetch available Plans and Packs from Stripe via our back-end
 */
export const getBillingPlansApiCall: TGetBillingPlans = () => {
  return apiService.get(`/billing/plans`);
};

export type TGetBillingPlans = () => Promise<IServerResponse<IBillingPlans>>;

export interface IBillingPlans {
  plans: {
    license_plans: ILicensePlan[];
    support_packs: ISupportPack[];
  };
}

/**
 * Set or edit the client's Plan and number_of_licenses
 */
export const editClientPlanApiCall: TEditClientPlan = (clientId, plan) => {
  return apiService.patch(`/clients/${clientId}/billing/plans`, {
    license_plan: plan,
  });
};

export type TEditClientBillingInfo = (
  clientId: string,
  owner?: IBillingOwner,
  payment_method?: TPaymentMethod
) => Promise<IServerResponse<{ billing: IClientBilling }>>;
/**
 * Set or edit the client's Billing Info
 */
export const editClientBillingInfoApiCall: TEditClientBillingInfo = (
  clientId,
  owner,
  paymentMethod
) => {
  return apiService.patch(`/clients/${clientId}/billing`, {
    owner,
    payment_method: paymentMethod,
  });
};

export type TEditClientPlan = (
  clientId: string,
  plan: TPlanReference
) => Promise<IServerResponse<{ license_plan: IClientLicensePlan }>>;

export type TPlanReference = {
  id: PLAN_ID;
  price: ILicensePlanPrice;
  number_of_licenses: number;
};

/**
 * Set or edit the client's Pack
 */
export const editClientPackApiCall: TEditClientPack = (
  clientId,
  packReference
) => {
  return apiService.patch(`/clients/${clientId}/billing/pack`, {
    support_pack: packReference,
  });
};

export type TEditClientPack = (
  clientId: string,
  pack: TPackReference
) => Promise<IServerResponse<{ support_pack: IClientSupportPack }>>;

export type TPackReference = {
  id: PACK_ID;
  price: ISupportPackPrice;
};

export type TActivateClientOverdue = (
  clientIds: string[]
) => Promise<IServerResponse<{ success: boolean }>>;

export const activateClientOverdueApiCall: TActivateClientOverdue = (
  clientIds
) => {
  return apiService.patch("/clients/activate_overdue", {
    client_ids: clientIds,
  });
};
