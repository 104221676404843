import { CUSTOM_FIELD_TYPE } from "fieldpro-tools";
import _ from "lodash";

export enum FieldTypes {
  WORKFLOW_REPORT_FIELDS = "WORKFLOW_REPORT_FIELDS",
  ACTIVITY_REPORT_FIELDS = "ACTIVITY_REPORT_FIELDS",
  ITEM_FIELDS = "ITEM_FIELDS",
  USER_FIELDS = "USER_FIELDS",
  CLIENT_FIELDS = "CLIENT_FIELDS",
  JOB_FIELDS = "JOB_FIELDS",
}

interface FieldConfig {
  [key: string]: string[];
}

const ITEM_FIELDS: FieldConfig = {
  [CUSTOM_FIELD_TYPE.DATE_PICKER]: ["_created_at", "_updated_at"],
  [CUSTOM_FIELD_TYPE.TEXT]: [
    "_id",
    "_name",
    "_created_by",
    "_updated_by",
    "_increment_id",
  ],
};

const WORKFLOW_REPORT_FIELDS: FieldConfig = {
  [CUSTOM_FIELD_TYPE.DATE_PICKER]: ["_created_at", "_updated_at"],
  [CUSTOM_FIELD_TYPE.TEXT]: [
    "_id",
    "_name",
    "_workflow_id",
    "_created_by",
    "_completed_by",
    "_updated_by",
    "_status",
    "_customer_id",
  ],
};

const ACTIVITY_REPORT_FIELDS: FieldConfig = {
  [CUSTOM_FIELD_TYPE.DATE_PICKER]: [
    "_created_at",
    "_updated_at",
    "_started_at",
    "_due_date",
    "_completed_at",
  ],
  [CUSTOM_FIELD_TYPE.TEXT]: [
    "_id",
    "_name",
    "_activity_id",
    "_step_id",
    "_created_by",
    "_completed_by",
    "_updated_by",
    "_assigned_user_id",
    "_duration",
    "_visit_id",
    "_status",
    "_customer_id",
  ],
};

const USER_FIELDS: FieldConfig = {
  [CUSTOM_FIELD_TYPE.TEXT]: [
    "_id",
    "_name", // NOTE: _first_name, _last_name are not available on the mobile side. Only the pseudo or "_name" is.
    "_phone",
    "_role",
    "_daily_status",
    "_team_leader",
    "_persona",
    "_email",
  ],
};

const CLIENT_FIELDS: FieldConfig = {
  [CUSTOM_FIELD_TYPE.TEXT]: ["_id", "_name", "_description", "_country"],
};

const JOB_FIELDS: FieldConfig = {
  [CUSTOM_FIELD_TYPE.TEXT]: ["result"],
};

const FIELDS_MAP: Record<FieldTypes, FieldConfig> = {
  [FieldTypes.WORKFLOW_REPORT_FIELDS]: WORKFLOW_REPORT_FIELDS,
  [FieldTypes.ACTIVITY_REPORT_FIELDS]: ACTIVITY_REPORT_FIELDS,
  [FieldTypes.ITEM_FIELDS]: ITEM_FIELDS,
  [FieldTypes.USER_FIELDS]: USER_FIELDS,
  [FieldTypes.CLIENT_FIELDS]: CLIENT_FIELDS,
  [FieldTypes.JOB_FIELDS]: JOB_FIELDS,
};

export function getFieldsByType(
  fieldType: FieldTypes,
  type?: CUSTOM_FIELD_TYPE
): string[] {
  const selectedFields = FIELDS_MAP[fieldType];
  if (type) {
    return (selectedFields && selectedFields[type]) || [];
  }
  return _.flatMap(Object.values(selectedFields));
}

export function getFieldsOfFieldType(fieldType: FieldTypes): FieldConfig {
  return FIELDS_MAP[fieldType];
}
