import { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { ComputedDatum, MayHaveLabel, ResponsivePie } from "@nivo/pie";
import { LEGEND_DISPLAY_POSITION } from "fieldpro-tools";

import { IKPI, INivoConfiguration } from "model/entities/Dashboard";

import Chart from "./Chart";
import { ChartDataUtils } from "./ChartDataUtils";
import styles from "./styles";

interface IProps {
  classes?: any;
  chart: IKPI;
  configuration: INivoConfiguration;
}

interface IState {
  chart: object;
  configuration: object;
}

export class OptimetriksPieChart extends Component<IProps, IState> {
  props: IProps;

  constructor(props: IProps) {
    super(props);
    this.props = props;
  }

  public render() {
    const { chart, configuration } = this.props;
    let legends: any[] = [];
    if (!chart.legend_position) {
      chart.legend_position = LEGEND_DISPLAY_POSITION.AS_ARC_ON_CHART;
    }
    if (chart.data) {
      chart.data = chart.data.map((data: any) => {
        data.value = data.value ? data.value : data.y;
        data.label = data.label ? data.label : data.x;
        data.id = data.label;
        return data;
      });
    }
    if (
      chart.legend_position &&
      chart.legend_position != LEGEND_DISPLAY_POSITION.AS_ARC_ON_CHART
    ) {
      legends = [
        {
          anchor: chart.legend_position,
          direction:
            chart.legend_position === LEGEND_DISPLAY_POSITION.BOTTOM
              ? "row"
              : "column",
          ...(chart.legend_position === LEGEND_DISPLAY_POSITION.BOTTOM && {
            translateX: 0,
            translateY: 30,
          }),
          itemDirection: "left-to-right",
          symbolSize: 15,
          itemWidth:
            chart.legend_position === LEGEND_DISPLAY_POSITION.BOTTOM
              ? 120
              : 150,
          itemHeight: 25,
        },
      ];
    }

    const useCustomColors = ChartDataUtils.hasColorField(chart.data);

    return (
      <ResponsivePie
        data={chart.data}
        colors={
          useCustomColors
            ? (
                d: Omit<ComputedDatum<MayHaveLabel>, "color" | "fill" | "arc">
              ) => (d.data as { color?: string }).color || "defaultColor"
            : configuration.colors
        }
        margin={Chart.settings["PIE_CHART"].margin}
        isInteractive={true}
        innerRadius={0.6}
        padAngle={0.5}
        cornerRadius={5}
        arcLinkLabelsOffset={0}
        arcLinkLabelsTextOffset={5}
        arcLinkLabelsDiagonalLength={25}
        arcLinkLabelsStraightLength={30}
        arcLinkLabelsSkipAngle={5}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={(item) => {
          return item.color;
        }}
        arcLinkLabelsTextColor={(item) => {
          return item.color;
        }}
        enableArcLinkLabels={
          chart.legend_position === LEGEND_DISPLAY_POSITION.AS_ARC_ON_CHART
        }
        arcLabelsSkipAngle={15}
        enableArcLabels={true}
        animate={true}
        motionConfig={{
          mass: 1,
          tension: 170,
          friction: 26,
          clamp: false,
          precision: 0.01,
          velocity: 0,
        }}
        transitionMode="outerRadius"
        activeOuterRadiusOffset={8}
        label={(e: any) => e.formattedValue}
        valueFormat={(v: any) => {
          return chart.is_percentage
            ? `${ChartDataUtils.addThousandSeparator(
                v,
                configuration.thousandSeparator
              )}%`
            : ChartDataUtils.addThousandSeparator(
                v,
                configuration.thousandSeparator
              );
        }}
        {...(legends.length != 0 && { legends: legends })}
        {...(chart.value_format &&
          chart.value_format.enabled && {
            valueFormat: chart.value_format?.format,
            label: (e: any) => e.formattedValue,
          })}
      />
    );
  }
}
export default withStyles(styles)(OptimetriksPieChart);
