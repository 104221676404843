import { ChartDataUtils } from "../Chart/ChartDataUtils";

export const formatVariationPercent = (
  currentPeriodValue: number,
  previousPeriodValue: number,
  is_unit: boolean = true,
  thousandSeparator?: string
) => {
  let returnVal = "";
  const diff = currentPeriodValue - previousPeriodValue;

  if (is_unit) {
    returnVal = ` ${diff < 0 ? "" : "+"}${
      previousPeriodValue === 0 && currentPeriodValue !== 0
        ? 100
        : previousPeriodValue === 0 && currentPeriodValue === 0
        ? 0
        : Number.isInteger((diff / previousPeriodValue) * 100)
        ? (diff / previousPeriodValue) * 100
        : ChartDataUtils.formatValue(
            (diff / previousPeriodValue) * 100,
            thousandSeparator
          )
    }%`;
  }

  return returnVal;
};

export const formatVariationAbsolute = (
  currentPeriodValue: number,
  previousPeriodValue: number,
  is_unit: boolean = true,
  thousandSeparator?: string
) => {
  if (is_unit) {
    const diff = currentPeriodValue - previousPeriodValue;
    const roundedDiff = ChartDataUtils.formatValue(diff, thousandSeparator);

    return ` (${diff < 0 ? roundedDiff : "+" + roundedDiff})`;
  }
};
