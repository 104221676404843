import React from "react";

import { Box, makeStyles } from "@material-ui/core";
import { IClient } from "fieldpro-tools";
import _ from "lodash";

import InputMultipleSelect from "components/Input/InputMultipleSelect";
import {
  isCategoryPartner,
  isCategorySaas,
} from "containers/authentication/utils/clientStatus";
import { useTranslations } from "hooks";
import { TViewMode } from "model/application/modal/CreateEditModal";

import styles from "./styles";

const useStyles = makeStyles(styles);

const InternalOwnerShip: React.FC<{
  client: Partial<IClient>;
  viewMode: TViewMode;
  shouldMarkError: (key: string) => string | undefined;
  onMultipleSelectChange: (value: any, name: string) => void;
  ownersOptions: Array<{
    email: string;
    name: string;
  }>;
}> = ({
  client,
  viewMode,
  shouldMarkError,
  onMultipleSelectChange,
  ownersOptions,
}) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;
  const { sales_owner, operations_owner, data_vis_owner, category } = client;

  const isSaasCategory = isCategorySaas(category);
  const isPartnerCategory = isCategoryPartner(category);

  const classes = useStyles();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gridRowGap={"20px"}
      className={classes.container}
    >
      <InputMultipleSelect
        viewMode={viewMode}
        name={"sales_owner"}
        error={shouldMarkError("sales_owner")}
        required={
          viewMode !== "CREATE" && (isSaasCategory || isPartnerCategory)
        }
        multipleSelection={false}
        onChange={onMultipleSelectChange}
        options={_.map(ownersOptions, (u) => ({
          key: u.email,
          label: u.name,
        }))}
        defaultValue={ownersOptions
          .filter((u) => u.email === sales_owner)
          .map((u) => ({
            key: u.email,
            label: u.name,
          }))}
        lang={lang}
        langlabel={label.inputClientManagementSalesOwner}
      />

      <InputMultipleSelect
        viewMode={viewMode}
        name={"operations_owner"}
        required={
          viewMode !== "CREATE" && (isSaasCategory || isPartnerCategory)
        }
        error={shouldMarkError("operations_owner")}
        multipleSelection={false}
        onChange={onMultipleSelectChange}
        options={_.map(ownersOptions, (u) => ({
          key: u.email,
          label: u.name,
        }))}
        defaultValue={ownersOptions
          .filter((u) => u.email === operations_owner)
          .map((u) => ({
            key: u.email,
            label: u.name,
          }))}
        lang={lang}
        langlabel={label.inputClientManagementOperationsOwner}
      />

      <InputMultipleSelect
        viewMode={viewMode}
        name={"data_vis_owner"}
        error={shouldMarkError("data_vis_owner")}
        multipleSelection={false}
        onChange={onMultipleSelectChange}
        options={_.map(ownersOptions, (u) => ({
          key: u.email,
          label: u.name,
        }))}
        defaultValue={ownersOptions
          .filter((u: any) => u.email === data_vis_owner)
          .map((u: any) => ({
            key: u.email,
            label: u.name,
          }))}
        lang={lang}
        langlabel={label.inputClientManagementDataVis}
      />
    </Box>
  );
};

export default InternalOwnerShip;
