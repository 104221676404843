import { removeNextOperator } from "containers/lists/redux/utils";
import { IQuestion } from "model/entities/Workflow";

export function prepareQuestionConditionsAndOperationsForBE(
  question: IQuestion
) {
  const newQuestion = { ...question };

  if (question.items_restrictions_conditions) {
    newQuestion.items_restrictions_conditions = removeNextOperator(
      question.items_restrictions_conditions
    );
  }

  if (question.conditions) {
    newQuestion.conditions = removeNextOperator(question.conditions);
  }

  if (question.operations) {
    newQuestion.operations = removeNextOperator(question.operations);
  }

  return newQuestion;
}
