import { File } from "filepond";
import { detect } from "jschardet";

import { FileExtensions } from "../UploadFileInput";

export const getExcelOrCsvFileContent = async (fileItem: File) => {
  const blobUrl = window.URL.createObjectURL(fileItem.file);
  const response = await fetch(blobUrl);

  if (fileItem.fileExtension === FileExtensions.Excel) {
    return response.blob();
  }
  if (!response.arrayBuffer) {
    return await response.text();
  }
  const buffer = await response.arrayBuffer();
  const uint8Array = new Uint8Array(buffer);
  const textContent = new TextDecoder("utf-8").decode(uint8Array);
  const detectedEncoding = detect(textContent).encoding || "utf-8";
  // Detect encoding using jschardet
  const decoder = new TextDecoder(detectedEncoding);
  return decoder.decode(uint8Array);
};
