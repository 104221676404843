import { IActivityReport as IBEActivityReport } from "fieldpro-tools";

import { IActivityReportInTable, IQuestion } from "model/entities/Workflow";

import prepareCustomFieldDataForBackend from "./prepareCustomFieldDataForBackend";

const prepareReportStateForBackend = (
  report: IActivityReportInTable,
  activitySchema: IQuestion[]
) => {
  const backendReport = Object.fromEntries(
    Object.entries(report).map(([key, value]) => [
      key,
      prepareCustomFieldDataForBackend({
        question: activitySchema.find((q) => q.tag == key),
        answer: value,
        simpleMatrixFormat: true,
      }),
    ])
  );

  return backendReport as IBEActivityReport;
};

export default prepareReportStateForBackend;
