import { useEffect } from "react";

import { WORKFLOW_TYPE } from "fieldpro-tools";

import InputSelect from "components/Input/InputSelect";
import useFormState from "hooks/useFormState";
import TLang from "model/application/Lang";
import { IWorkflow } from "model/entities/Workflow";
import { getOptionsInputSelect } from "utils/getOptionsInputSelect";

interface IDeliveryWorkflow {
  workflow_id: string;
  workflow_type: WORKFLOW_TYPE;
}

interface IDeliveryWorkflowModalProps {
  onChange: (o: IDeliveryWorkflow) => void;
  element: IDeliveryWorkflow;
  lang: TLang;
  additionnalProps: {
    workflows: IWorkflow[];
  };
}

export const DeliveryWorkflowModal = ({
  element,
  lang,
  onChange,
  additionnalProps: { workflows },
}: IDeliveryWorkflowModalProps) => {
  const formStateHook = useFormState({
    initAttributes: element,
  });

  const labelToDisplay =
    lang.containers.clients.subCategories.clients.createEditModal
      .inputDeliveryWorkflows;

  const handleChange = (value: any, name: string) => {
    formStateHook.handleInputChange(value, name);
  };

  useEffect(() => {
    onChange(formStateHook.attributes);
  }, [formStateHook.attributes, onChange]);

  return (
    <div>
      <InputSelect
        viewMode={"EDIT"}
        name="workflow_type"
        lang={labelToDisplay.createEditModal.inputWorkflowType}
        onChange={handleChange}
        value={formStateHook.attributes.workflow_type}
        options={getOptionsInputSelect({
          listKeys: Object.keys(WORKFLOW_TYPE),
          lang: labelToDisplay.createEditModal.inputWorkflowType.options,
        })}
      />
      <InputSelect
        viewMode={"EDIT"}
        name="workflow_id"
        lang={labelToDisplay.createEditModal.inputWorkflowId}
        onChange={handleChange}
        value={formStateHook.attributes.workflow_id}
        options={getOptionsInputSelect({
          listKeys: workflows.map((w) => w.id),
          lang: workflows.reduce((prev, curr) => {
            return {
              ...prev,
              [curr.id]: curr.name,
            };
          }, {}),
        })}
      />
    </div>
  );
};

export default DeliveryWorkflowModal;
