import { INotification } from "fieldpro-tools";
import _ from "lodash";

export function prepareNotificationForBE(notification: INotification) {
  const recipients: string[] = notification.recipients || [];

  if (notification.web_users && !_.isEmpty(notification.web_users)) {
    recipients.push(...notification.web_users);
    delete notification.web_users;
  }

  const finalRecipients = _.compact(
    _.map(recipients, (r) => {
      if (!_.isString(r) && _.has(r, "key")) {
        return r["key"];
      }
      return r;
    })
  );
  notification.recipients = finalRecipients;
  return notification;
}
