import _ from "lodash";

import {
  getMatrixFieldsSchema,
  getTagKey,
} from "components/Input/InputMatrix/utils/getQuestionColumns";
import { IListSchema } from "model/entities/List";
import { IQuestion } from "model/entities/Workflow";

interface ISimpleMatrixFormat {
  [key: string]: any;
}

interface IRowHeader {
  tag: string;
  typed_answers: ISimpleMatrixFormat | undefined;
}

interface IMatrixFormatForBackend {
  headers: IRowHeader[]; // Array of row headers
}

interface IConvertMatrixValue {
  customField: IListSchema | IQuestion;
  value: any;
  subElementsCallback?: ({
    customField,
    value,
  }: {
    customField: any;
    value: any;
  }) => any;
  simpleMatrixFormat?: boolean;
}

export const convertMatrixValue = ({
  customField,
  value,
  subElementsCallback,
  simpleMatrixFormat,
}: IConvertMatrixValue): ISimpleMatrixFormat | IMatrixFormatForBackend => {
  if (simpleMatrixFormat) return convertMatrixValueToSimpleFormat(value);
  else
    return convertMatrixValueToBackendFormat({
      customField,
      value,
      subElementsCallback,
    });
};

//The simple format is used for Meta expression resolution
export const convertMatrixValueToSimpleFormat = (
  value: any
): ISimpleMatrixFormat => {
  if (value?.rows) {
    return value?.rows.reduce((acc: any, item: any) => {
      const { _item_id, ...rest } = item;
      acc[_item_id] = rest;
      return acc;
    }, {} as Record<string, any>);
  } else {
    return {};
  }
};

// the format of matrix sent to backend has headers
export const convertMatrixValueToBackendFormat = ({
  customField,
  value,
  subElementsCallback,
}: IConvertMatrixValue): IMatrixFormatForBackend => {
  const subCustomFields = getMatrixFieldsSchema(customField);
  const tagKey = getTagKey(
    subCustomFields.length > 0 ? subCustomFields[0] : customField
  );
  const matrix_questions_tags = _.map(subCustomFields, (m) => m[tagKey]);

  if (!value?.rows) {
    return {
      headers: [],
    };
  }

  const headers = _.map(value?.rows, (row: any) => {
    return {
      tag: row._item_id,
      typed_answers: _.filter(Object.keys(row), (key) =>
        _.includes(matrix_questions_tags, key)
      ).map((tag: string) => {
        const q = _.find(subCustomFields, (tp) => tp[tagKey] === tag);
        if (!subElementsCallback) return undefined;
        return {
          key: tag,
          value: subElementsCallback({
            customField: q as IListSchema | IQuestion,
            value: row[q?.[tagKey]],
          }),
        };
      }),
    };
  });

  return { headers };
};
