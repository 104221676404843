import _ from "lodash";

import { TColumnType } from "components/Table/model";
import { IAction } from "redux/store/model";

import { ISaveTableColumnsActionSuccess } from "./../actionCreators/tableActionCreator";
import * as types from "./../actionTypes/tableActionTypes";
import initialState from "./tableInitialState";

export type TColumnsOrganized = {
  path: string;
  columnTypes: TColumnType[];
};

export interface ITableState {
  columns: TColumnsOrganized[];
}

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.SET_TABLE_COLUMNS_ORDER: {
      const { columns: stateColumns } = state;
      const {
        organizedColumns: { columnTypes, path },
      } = action as ISaveTableColumnsActionSuccess;

      const newColumns = _.map(stateColumns, (item) =>
        item.path === path ? { ...item, columnTypes } : item
      );

      const columns = _.some(stateColumns, { path })
        ? newColumns
        : [...newColumns, { columnTypes, path }];

      return {
        ...state,
        columns,
      };
    }

    case types.CLEAN_TABLE_COLUMNS_ORDER: {
      return {
        ...state,
        columns: [],
      };
    }

    default:
      return state;
  }
}
