import { IWorkflowReport } from "fieldpro-tools";

import { useTranslations } from "hooks";
import { IOption } from "model/application/components";

import InputSelect from "../InputSelect";

const WORKFLOW_REPORT_FIELDS: Array<keyof IWorkflowReport> = [
  "id",
  "name",
  "workflow_id",
  "completed_at",
  "completed_by",
  "status",
  "version",
  "updated_by",
  "updated_at",
  "updated_locally_at",
  "created_locally_at",
  "created_by",
  "created_at",
  "created_source",
  "updated_source",
  "completed_source",
];

interface IWorkflowReportFieldSelectionProps {
  onChange: (value: any, name: string) => void;
  value?: string;
}

const WorkflowReportsFieldSelection: React.FC<
  IWorkflowReportFieldSelectionProps
> = ({ onChange, value }) => {
  const lang = useTranslations();

  function getWorflowReportFieldOptions() {
    const options: IOption<keyof IWorkflowReport>[] = [];

    WORKFLOW_REPORT_FIELDS.forEach((field) => {
      options.push({
        key: field,
        label: field,
      });
    });

    return options;
  }

  return (
    <div data-testid="workflow-report-field-selection">
      <div>
        {
          lang.containers.workflows.subCategories.metaExpressions
            .createEditModal.inputSteps.customMessage.selectWorkflowFields
        }
      </div>

      <InputSelect
        name="field"
        options={getWorflowReportFieldOptions()}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default WorkflowReportsFieldSelection;
