import {
  IActivityReport as IBEActivityReport,
  IMetaExpressionOptions,
} from "fieldpro-tools";

import { IActivityReportInTable, IWorkflow } from "model/entities/Workflow";

import { resolveMetaExpressionInString } from "../../workflows/utils/metaResolver";

interface IResolveReportChainings {
  workflow: IWorkflow;
  stepId: string;
  report: IActivityReportInTable;
  metaExpressionOptions: IMetaExpressionOptions;
}

export const resolveReportChainings = ({
  workflow,
  stepId,
  report,
  metaExpressionOptions,
}: IResolveReportChainings) => {
  const resolvedReport: IActivityReportInTable = { ...report };

  const chainings = (workflow.chainings ?? []).filter(
    (ch) => ch.destination_step === stepId
  );

  for (const chaining of chainings) {
    for (const params of chaining.destination_params) {
      resolvedReport[params.key] = resolveMetaExpressionInString(params.value, {
        ...metaExpressionOptions,
        activity_report: resolvedReport as IBEActivityReport,
      });
    }
  }
  return resolvedReport;
};
