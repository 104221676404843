import React, { ReactNode, useEffect, useState } from "react";

import {
  InputAdornment,
  InputBase,
  InputBaseProps,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// WARNING: we should not use "@mui/" packages, only "@material-ui/"
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import { useSelector } from "react-redux";
import sqlFormatter from "sql-formatter";

import { getThousandSeparator } from "containers/authentication/redux/selector";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { isEmptyValue } from "utils/isEmptyValue";
import { formatNumber } from "utils/numbers/formatNumber";

import WarningMessage from "../Base/WarningMessage";
import ErrorMessage from "../ErrorMessage";
import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import InputViewValue from "../InputViewValue";
import styles from "../styles";
import { getStringWithoutSpaces } from "../utils";

export const useStyles = makeStyles(styles as any);

export enum INPUT_TEXT_TYPE {
  TEXT = "TEXT",
  SQL = "SQL",
  INTEGER = "INTEGER",
  DECIMAL = "DECIMAL",
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD",
}
export interface ICustomInputTextProps
  extends Omit<
      InputBaseProps,
      "onChange" | "lang" | "error" | "value" | "margin"
    >,
    Pick<IInputBaseLayout, "highlightContent"> {
  lang?: {
    title?: string;
    tooltip?: string;
  };
  label?: string;
  defaultValue?: string | number;
  onChange: (value: any, name: string) => void;
  onBlur?: () => void;
  type?: INPUT_TEXT_TYPE;
  required?: boolean;
  isSearchField?: boolean;
  isUndetermined?: boolean;
  error?: string;
  name: string;
  darkMode?: boolean;
  showPasswordButton?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  multiline?: boolean;
  disabled?: boolean;
  pattern?: string;
  min?: number; // for numeric inputs
  max?: number; // for numeric inputs
  maxLength?: number;
  warning?: string;
  viewMode?: TViewMode;
  replaceSpaces?: boolean;
  lowercase?: boolean;
  uppercase?: boolean;
  viewStacked?: boolean;
  disabledReason?: string;
  debounceDuration?: number; // Add a debounce of ~300-400ms in big forms to avoid slow down
  additionalMessage?: ReactNode;
  labelIcons?: ReactNode[];
}

const formatValue = (value?: string | number) =>
  isEmptyValue(value) ? "" : String(value);

export const InputText: React.FunctionComponent<ICustomInputTextProps> = ({
  viewMode = "CREATE",
  onChange,
  onBlur,
  name,
  defaultValue,
  isUndetermined,
  type = INPUT_TEXT_TYPE.TEXT,
  error = "",
  lang,
  label = "",
  required = false,
  isSearchField = false,
  placeholder = isSearchField ? "Search" : "",
  darkMode = false,
  showPasswordButton = false,
  multiline = false,
  disabled = false,
  fullWidth = true,
  pattern,
  min,
  max,
  warning,
  replaceSpaces = false,
  lowercase = false,
  uppercase = false,
  viewStacked = false,
  debounceDuration = 0,
  maxLength,
  highlightContent,
  disabledReason,
  autoComplete,
  additionalMessage,
  labelIcons,
  ...rest
}: ICustomInputTextProps) => {
  const classes = useStyles();

  const initValue = formatValue(defaultValue); // do not replace 0 with ""
  const [value, setValue] = useState(initValue);
  const thousandSeparator = useSelector(getThousandSeparator);

  const [showPassword, setShowPassword] = useState(false);
  const [isUndeterminedState, setIsUndeterminedState] =
    useState(isUndetermined);

  useEffect(() => {
    setValue(formatValue(defaultValue));
  }, [defaultValue]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const debouncedOnChange = useDebouncedCallback(
    onChange,
    debounceDuration,
    []
  );

  const handleChange = (event: any) => {
    if (
      type === INPUT_TEXT_TYPE.INTEGER &&
      !Number.isInteger(Number(event.target.value))
    ) {
      // error
      return;
    }

    let value: string =
      lowercase && _.isString(event.target.value)
        ? _.toLower(event.target.value)
        : uppercase && _.isString(event.target.value)
        ? _.toUpper(event.target.value)
        : event.target.value;

    if (type === INPUT_TEXT_TYPE.SQL && value[value.length - 1] === ";") {
      value = sqlFormatter.format(value);
    }

    if (replaceSpaces) {
      value = getStringWithoutSpaces({ str: value });
    }

    if (type === INPUT_TEXT_TYPE.INTEGER || type === INPUT_TEXT_TYPE.DECIMAL) {
      if (min !== undefined && min !== null && Number.parseFloat(value) < min)
        value = min.toString();
      if (max !== undefined && max !== null && Number.parseFloat(value) > max)
        value = max.toString();
    }

    setValue(value);
    setIsUndeterminedState(false);

    if (!debouncedOnChange) {
      return;
    }

    if (type === INPUT_TEXT_TYPE.DECIMAL) {
      debouncedOnChange(Number.parseFloat(value), name);
      return;
    }

    if (type === INPUT_TEXT_TYPE.INTEGER) {
      debouncedOnChange(Number.parseInt(value), name);
      return;
    }

    debouncedOnChange(value, name);
  };

  const getAutoCompleteValue = () => {
    if (autoComplete) return autoComplete;
    if (_.includes([INPUT_TEXT_TYPE.EMAIL, INPUT_TEXT_TYPE.PASSWORD], type)) {
      /** We are using autoComplete="new-password" instead of autoComplete="off"
       * because some browsers (like Edge) tend to ignore "off" for certain input types,
       * such as emails and passwords, to improve user experience by suggesting stored data.
       * Setting autoComplete to "new-password" acts as a workaround to prevent
       * unwanted suggestions while maintaining compatibility across different browsers.
       * This is crucial for preventing unexpected recalculations of MixedTags values when
       * an email suggestion is selected from the browser's autocomplete dropdown.
       **/
      return "new-password";
    }
    return undefined;
  };

  const buildInput = () => {
    if (isSearchField && type === INPUT_TEXT_TYPE.TEXT) {
      return (
        <InputBase
          classes={{
            root: classes.searchInput,
          }}
          placeholder={placeholder}
          className={classes.Input}
          name={name}
          onChange={handleChange}
          required={required}
          disabled={disabled}
          fullWidth={fullWidth ? fullWidth : false}
          inputProps={{ pattern: pattern || null, maxLength }}
          onBlur={onBlur}
          value={isUndeterminedState ? "Several values..." : value}
          autoComplete={getAutoCompleteValue()}
          endAdornment={
            isSearchField && (
              <InputAdornment position="end">
                <IconButton
                  style={{
                    marginLeft: "-47px",
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }
          {...rest}
        />
      );
    }
    const inputType =
      !isUndeterminedState &&
      (type === INPUT_TEXT_TYPE.INTEGER || type === INPUT_TEXT_TYPE.DECIMAL)
        ? "number"
        : type === INPUT_TEXT_TYPE.PASSWORD
        ? showPassword
          ? "text"
          : "password"
        : type.toLowerCase();
    const inputClasses = {
      root:
        error && error.length > 0
          ? classes.errorEditInput
          : warning && warning.length > 0
          ? classes.warningEditInput
          : classes.editInput,
    };

    // TODO: extract separate InputPhone component ?

    switch (viewMode) {
      case "INLINE":
      case "CREATE": {
        return (
          <InputBase
            name={name}
            classes={inputClasses}
            className={classes.Input}
            onChange={handleChange}
            onBlur={onBlur}
            fullWidth={fullWidth}
            id={name}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            autoComplete={getAutoCompleteValue()}
            inputProps={{ pattern: pattern || null, min, max, maxLength }}
            value={isUndeterminedState ? "Several values..." : value}
            type={inputType}
            endAdornment={
              type === INPUT_TEXT_TYPE.PASSWORD || showPasswordButton ? (
                <InputAdornment
                  position="end"
                  style={{
                    position: "absolute",
                    right: "-10px",
                  }}
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    style={{
                      color: darkMode ? "white" : "grey",
                      marginLeft: "-55px",
                    }}
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : null
            }
            multiline={type === INPUT_TEXT_TYPE.SQL || multiline}
            {...rest}
          />
        );
      }

      case "EDIT": {
        return (
          <Typography noWrap component={"div"}>
            <InputBase
              classes={inputClasses}
              className={classes.Input}
              onChange={handleChange}
              onBlur={onBlur}
              fullWidth
              id={name}
              required={required}
              disabled={disabled}
              placeholder={placeholder}
              inputProps={{ pattern: pattern || null, maxLength }}
              value={isUndeterminedState ? "Several values..." : value}
              type={inputType}
              multiline={type === INPUT_TEXT_TYPE.SQL || multiline}
              autoComplete={getAutoCompleteValue()}
              name={name}
              {...rest}
            />
          </Typography>
        );
      }
      case "VIEW": {
        let formattedValue = value;
        if (
          type === INPUT_TEXT_TYPE.INTEGER ||
          type === INPUT_TEXT_TYPE.DECIMAL
        ) {
          formattedValue = formatNumber(
            Number.parseFloat(value),
            thousandSeparator
          );
        }

        if (type === INPUT_TEXT_TYPE.PASSWORD) {
          const PASSWORD_MAX_SIZE = 50;
          formattedValue = "•".repeat(PASSWORD_MAX_SIZE);
        }

        return <InputViewValue value={formattedValue || placeholder} />;
      }
    }
  };

  if (isSearchField) {
    return (
      <>
        {buildInput()}

        {error && <ErrorMessage error={error} />}

        {(!error || !error.length) &&
          warning &&
          warning.length > 0 &&
          viewMode !== "VIEW" &&
          !isSearchField && <WarningMessage warning={warning} />}
      </>
    );
  }

  return (
    <InputBaseLayout
      highlightContent={highlightContent}
      label={lang ? lang.title : label}
      tooltip={disabledReason ? undefined : lang?.tooltip}
      required={required}
      viewMode={viewMode}
      disabled={disabled}
      error={error}
      viewStacked={viewStacked}
      htmlFor={name}
      disabledReason={disabledReason}
      labelIcons={labelIcons}
    >
      {buildInput()}

      {additionalMessage}

      {(!error || !error.length) &&
        warning &&
        warning.length > 0 &&
        viewMode !== "VIEW" &&
        !isSearchField && <WarningMessage warning={warning} />}
    </InputBaseLayout>
  );
};

export default InputText;
