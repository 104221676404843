import React, { ChangeEvent, useEffect, useState } from "react";

import { AutofillRetrieveResponse } from "@mapbox/search-js-core";
import { AddressAutofill } from "@mapbox/search-js-react";
import { Button, makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import _ from "lodash";
import { LngLat } from "mapbox-gl";

import MapWithMarker, { ILngLat } from "components/Map/MapWithMarker";
import useBrowserGeolocation from "hooks/useBrowserGeolocation";
import { TLang } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";

import CustomDialogForm from "../../Dialog/CustomDialogForm";
import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import InputText from "../InputText/InputText";
import CoordinatesForm, { DEFAULT_GPS_PRECISION } from "./CoordinatesForm";
import LocationInputSwitch, { TLocationInputType } from "./LocationInputSwitch";
import styles from "./styles";
import { isValidGPSPosition } from "./utils/isValidGPSPosition";

const useStyles = makeStyles(styles as any);

export interface IGPSStructure {
  lat: number | undefined;
  lng: number | undefined;
  acc: number | undefined;
}

export interface IInputGPSPropsBase {
  defaultValue?: IGPSStructure;
  name: string;
  error?: string;
  onChange: (value: IGPSStructure, name: string) => void;
  required?: boolean;
  isUndetermined?: boolean;
  lang: TLang;
  langlabel?: {
    title?: string;
    tooltip?: string;
  };
  viewMode?: TViewMode;
  viewStacked?: boolean;
}
export interface IInputGPSProps
  extends IInputGPSPropsBase,
    Omit<IInputBaseLayout, keyof IInputGPSPropsBase> {}

const EMPTY_COORDS = { lat: undefined, lng: undefined, acc: undefined };

export const InputGPS: React.FunctionComponent<IInputGPSProps> = ({
  defaultValue = EMPTY_COORDS,
  isUndetermined: isUndeterminedProps = false,
  name,
  error,
  onChange,
  required,
  lang,
  langlabel,
  viewMode = "CREATE",
  viewStacked = false,
  ...rest
}) => {
  const langKey = lang.components.inputGPSButton;
  const [autofillValue, setAutofillValue] = useState("");
  const [addressInputType, setAddressInputType] =
    useState<TLocationInputType>("address");

  const classes = useStyles();

  const [isUndetermined] = useState(isUndeterminedProps);

  const [isOpen, setIsOpen] = useState(false);

  // local state, for the modal
  // only call onChange to change parent's state when confirming the gpsValue
  const [gpsValue, setGpsValue] = useState(
    isUndetermined ? EMPTY_COORDS : defaultValue
  );

  // Useful to update state after onChange is called (e.g in reset)
  useEffect(() => {
    setGpsValue(defaultValue);
  }, [defaultValue]);

  const browserLocation = useBrowserGeolocation();
  useEffect(() => {
    if (
      browserLocation &&
      isValidGPSPosition(browserLocation) &&
      !isValidGPSPosition(defaultValue) // using gpsValue here causes bugs (e.g we can't clear the value)
    ) {
      setGpsValue(browserLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserLocation]);

  /**
   * Handles input changes
   * @param {Object} e Event Object
   */
  const handleLocationChange = (coords: { lng: number; lat: number }) => {
    setGpsValue({
      lng: _.round(coords.lng, DEFAULT_GPS_PRECISION),
      lat: _.round(coords.lat, DEFAULT_GPS_PRECISION),
      acc: DEFAULT_GPS_PRECISION,
    });
  };

  const confirmGPSButtonEnabled = (gps: IGPSStructure) => {
    return !_.every(_.values(gps), _.isUndefined) || !required;
  };

  /**
   * Handles confirmation of editing the GPS
   */
  const handleConfirmEditGPS = () => {
    if (!isValidGPSPosition(gpsValue)) {
      return;
    }
    onChange(gpsValue, name);
    setIsOpen(false);
  };

  /**
   * Handles cancel GPS form
   */
  const handleCancelEditGPS = () => {
    setIsOpen(false);
  };

  const onOpenClose = () => {
    if (isOpen) setIsOpen(false);
    else setIsOpen(true);
  };

  const removeGpsValue = () => {
    onChange(EMPTY_COORDS, name);
  };

  const onRetrieveAutofillAddress = (res: AutofillRetrieveResponse) => {
    const fullAddress = res.features[0]?.properties.full_address;
    const coordinates = res.features[0]?.geometry.coordinates;

    if (!coordinates) {
      return;
    }

    if (fullAddress) {
      setAutofillValue(fullAddress);
    }

    const [lng, lat] = coordinates;
    const ll = new LngLat(lng ?? 0, lat ?? 1);
    const newValue = {
      lat: _.round(ll.lat, DEFAULT_GPS_PRECISION),
      lng: _.round(ll.lng, DEFAULT_GPS_PRECISION),
      acc: DEFAULT_GPS_PRECISION,
    };

    setGpsValue(newValue);
  };

  const buildGPS = () => {
    switch (viewMode) {
      // CREATE or EDIT Mode
      case "CREATE":
      case "EDIT": {
        return (
          <div
            className={
              viewMode === "EDIT" || isValidGPSPosition(gpsValue)
                ? classes.editOption
                : undefined
            }
          >
            {(viewMode === "EDIT" || isValidGPSPosition(gpsValue)) && (
              <p style={{ flexShrink: 0 }}>
                {`
                  LAT: ${gpsValue?.lat}
                  LNG: ${gpsValue?.lng}
                  ACC: ${gpsValue?.acc}
                `}
              </p>
            )}
            <div
              style={{
                display: "flex",
                columnGap: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button onClick={onOpenClose} className={classes.button}>
                {isUndetermined ? (
                  <div className={classes.buttonTextIcon}>
                    <span>{langKey.options.severalValues}</span>
                  </div>
                ) : (
                  <>
                    {viewMode === "CREATE" && !isValidGPSPosition(gpsValue) && (
                      <div
                        className={classes.buttonTextIcon}
                        data-testid="add-gps-button"
                      >
                        <span className="material-icons-outlined">
                          ads_click
                        </span>
                        <span>{langKey.options.selectGpsValue}</span>
                      </div>
                    )}

                    {(viewMode === "EDIT" || isValidGPSPosition(gpsValue)) &&
                      !isUndetermined && (
                        <div
                          className={classes.buttonTextIcon}
                          data-testid="edit-gps-button"
                        >
                          <span className="material-icons-outlined">edit</span>
                          <span>{langKey.options.editGpsValue}</span>
                        </div>
                      )}
                  </>
                )}
              </Button>

              {(viewMode === "EDIT" || isValidGPSPosition(gpsValue)) && (
                <Button onClick={removeGpsValue} className={classes.button}>
                  <div
                    className={classes.buttonTextIcon}
                    data-testid="remove-gps-button"
                  >
                    <span className="material-icons-outlined">
                      highlight_off
                    </span>
                    <span>{langKey.options.removeGpsValue}</span>
                  </div>
                </Button>
              )}
            </div>
          </div>
        );
      }

      // View Mode
      case "VIEW": {
        return (
          <div>
            <p style={{ flexShrink: 0 }}>
              {`
                LAT: ${gpsValue?.lat}
                LNG: ${gpsValue?.lng}
                ACC: ${gpsValue?.acc}
              `}
            </p>
          </div>
        );
      }
    }
  };

  const handleChangeAddressInputType = (
    _event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setAddressInputType(value as TLocationInputType);
  };

  const handleChangeCoordinatesForm = (value: [lat: number, lng: number]) => {
    if (value) {
      const newValue = {
        lat: value[0],
        lng: value[1],
        acc: gpsValue.acc || 10,
      };

      setGpsValue(newValue);
    }
  };

  return (
    <div style={{ fontFamily: "BasierCircle" }}>
      <InputBaseLayout
        {...rest}
        label={langlabel?.title}
        tooltip={langlabel?.tooltip}
        required={required}
        viewMode={viewMode}
        error={error}
        viewStacked={viewStacked}
      >
        <Box
          style={
            viewMode === "EDIT" ||
            viewMode === "VIEW" ||
            isValidGPSPosition(gpsValue)
              ? {
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                }
              : { fontWeight: 500 }
          }
        >
          <div
            style={
              viewMode === "CREATE" && !isValidGPSPosition(gpsValue)
                ? {
                    padding: "32px",
                    borderStyle: "dashed",
                    borderWidth: "1px",
                    textAlign: "center",
                    borderRadius: "5px",
                  }
                : undefined
            }
          >
            {buildGPS()}
          </div>
        </Box>
      </InputBaseLayout>

      <CustomDialogForm
        isOpen={isOpen}
        onClose={onOpenClose}
        title={lang.components.inputGPSButton.title}
        onConfirmAction={handleConfirmEditGPS}
        onCancel={handleCancelEditGPS}
        confirmBtnText={lang.modal.confirm}
        isDisabled={!confirmGPSButtonEnabled(gpsValue)}
        lang={lang}
        data-testid="map-dialog"
      >
        <Box>
          <Box data-testid="map" height="360px">
            <MapWithMarker
              markerPosition={
                isValidGPSPosition(gpsValue) ? (gpsValue as ILngLat) : undefined
              }
              onMarkerDragEnd={handleLocationChange}
            />
          </Box>

          <Box margin="12px 0px">
            <LocationInputSwitch
              locationInputType={addressInputType}
              handleChangeAddressInputType={handleChangeAddressInputType}
            />
          </Box>

          {addressInputType == "address" ? (
            <form className={classes.autoCompleteForm}>
              <AddressAutofill
                accessToken={process.env.REACT_APP_MAPBOX_API ?? ""}
                onRetrieve={onRetrieveAutofillAddress}
                popoverOptions={{ placement: "top-start" }}
                options={{ streets: false }} // Without this, the suggestions that have the "road icon" are proposed, but they don't trigger onRetrieve (because not a specific location ?)
              >
                <InputText
                  key={autofillValue}
                  name="autocomplete"
                  // Docs and examples: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#administrative_levels_in_addresses
                  autoComplete="street-address"
                  defaultValue={autofillValue}
                  onChange={() => {}}
                  placeholder={
                    lang.components.inputGPSButton.options
                      .inputAddressPlaceholder
                  }
                />
              </AddressAutofill>
            </form>
          ) : (
            <CoordinatesForm
              name={"coordinatesform"}
              coordinates={gpsValue}
              onChange={handleChangeCoordinatesForm}
            />
          )}
        </Box>
      </CustomDialogForm>
    </div>
  );
};

export default InputGPS;
