import { CUSTOM_FIELD_TYPE, IMetaExpressionOptions } from "fieldpro-tools";
import _ from "lodash";

import { IQuestion } from "model/entities/Workflow";
import { resolveOperations } from "utils/expressions/operationSolver";
import { resolveMetaExpressionInOperation } from "utils/metaExpressions/metaExpressionResolution";

import { IMatrixAnswerForm } from "../MatrixEditorDrawer";

interface IRefreshMatrixComputeValues {
  schema: IQuestion[];
  matrixAnswers: IMatrixAnswerForm;
  metaExpressionOptions: IMetaExpressionOptions;
}

export const refreshMatrixComputeValues = ({
  schema,
  matrixAnswers,
  metaExpressionOptions,
}: IRefreshMatrixComputeValues) => {
  const result: IMatrixAnswerForm = { ...matrixAnswers };

  _.forEach(schema, (field) => {
    if (field.type !== CUSTOM_FIELD_TYPE.COMPUTE) {
      return;
    }

    const rawOperations = _.map(field.operations, (operation) => {
      return resolveMetaExpressionInOperation(operation, {
        ...metaExpressionOptions,
        matrix_answers: result,
      });
    });

    result[field.tag] = resolveOperations(rawOperations);
  });

  return result;
};
