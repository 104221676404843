import React from "react";

import TLang from "model/application/Lang";

import CustomTableRow from "./CustomTable/CustomTableRow";
import { TColumnType } from "./model";
import { buildSyntheticRow } from "./utils";

interface SyntheticRowProps {
  columnTypes: TColumnType[];
  rowData: unknown[];
  hasCheckBox: boolean;
  lang: TLang;
}

const SyntheticRow: React.FC<SyntheticRowProps> = ({
  columnTypes,
  rowData,
  hasCheckBox,
  lang,
}) => {
  const syntheticRow = buildSyntheticRow(columnTypes, rowData);

  if (Object.values(syntheticRow).filter((v) => v !== "").length > 0) {
    return (
      <CustomTableRow
        rowItem={syntheticRow}
        columnTypes={columnTypes}
        hasCheckBox={hasCheckBox}
        isFooterRow={true}
        lang={lang}
      />
    );
  }

  return null;
};

export default SyntheticRow;
