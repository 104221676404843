import _ from "lodash";

import { IMobileUser } from "model/entities/User";

import { TCalendarFilterObject } from "../types";
import { CALENDAR_FILTERS } from "./prepareFilters";

export function getCurrentFieldUser(
  mobileUser: IMobileUser | undefined,
  isManager: boolean,
  filterQuery: TCalendarFilterObject
) {
  if (isManager) {
    const users = filterQuery[CALENDAR_FILTERS.USERS] || [];
    return {
      currentFieldUser: _.first(users),
      allSelectedUsers: users,
    };
  }
  return {
    currentFieldUser: mobileUser?.id,
    allSelectedUsers: _.compact([mobileUser?.id]),
  };
}
