import _ from "lodash";

import { IErpOrder } from "containers/erp/types";
import { updateItemInArray } from "containers/workflows/subcategories/jobs/Jobscreen/notification/EmailNotificationConfigForm/utils/updateItemInArray";
import { IAction } from "redux/store/model";
import { updateItemsInArray } from "utils/reducerUtils";

import {
  FetchAllOrdersSuccessAction,
  IUpdateOrderStatusSuccessAction,
} from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface IErpOrdersState {
  orders: IErpOrder[];
  isLoading: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isDeletingOrderLines: boolean;
  isUpdatingOrderLines: boolean;
  count: number;
}

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.FETCH_ALL_ERP_ORDERS_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_ALL_ERP_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.FETCH_ALL_ERP_ORDERS_SUCCESS:
      const { orders, count, append } = action as FetchAllOrdersSuccessAction;

      let newOrders: IErpOrder[] = orders;

      if (append) {
        newOrders = _.concat(state.orders, orders);
      }

      return {
        ...state,
        isLoading: false,
        orders: newOrders,
        count,
      };
    case types.UPDATE_ERP_ORDER_STATUS_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };
    case types.UPDATE_ERP_ORDER_STATUS_FAILURE:
      return {
        ...state,
        isUpdating: false,
      };
    case types.UPDATE_ERP_ORDER_STATUS_SUCCESS: {
      const { orders } = action as IUpdateOrderStatusSuccessAction;

      const newOrders = _.forEach(state.orders, (order) => {
        const updatedOrder = _.find(orders, { id: order.id });
        if (updatedOrder) {
          order.status = updatedOrder.status;
        }
      });

      const updatedOrder = updateItemsInArray(state.orders, newOrders);
      return {
        ...state,
        isUpdating: false,
        orders: updatedOrder,
      };
    }
    case types.FETCH_SFA_DATA_SUCCESS: {
      const { dataKey, data } = action as any;
      return {
        ...state,
        isUpdating: false,
        [dataKey]: data,
      };
    }

    case types.DELETE_ORDER_LINES_SUCCESS: {
      const { orders, count } = action as FetchAllOrdersSuccessAction;

      let newOrders = state.orders;

      orders.forEach((order) => {
        newOrders = updateItemInArray(newOrders, order, "id");
      });

      return {
        ...state,
        isDeletingOrderLines: false,
        orders: newOrders,
        count,
      };
    }

    case types.DELETE_ORDER_LINES_BEGIN:
      return {
        ...state,
        isDeletingOrderLines: true,
      };

    case types.DELETE_ORDER_LINES_FAILURE:
      return {
        ...state,
        isDeletingOrderLines: false,
      };

    case types.UPDATE_ORDER_LINES_BEGIN:
      return {
        ...state,
        isUpdatingOrderLines: true,
      };

    case types.UPDATE_ORDER_LINES_SUCCESS: {
      const { orders } = action as FetchAllOrdersSuccessAction;

      let newOrders = state.orders;

      orders.forEach((order) => {
        newOrders = updateItemInArray(newOrders, order, "id");
      });

      return {
        ...state,
        isUpdatingOrderLines: false,
        orders: newOrders,
      };
    }

    case types.UPDATE_ORDER_LINES_FAILURE:
      return {
        ...state,
        isUpdatingOrderLines: false,
      };

    default:
      return state;
  }
}
