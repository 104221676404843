import {
  IAccessRightProfile,
  IClient as IBEClient,
} from "fieldpro-tools/dist/src/types";
import { IHierarchyDependency, ISecret } from "fieldpro-tools/src/types";
import _ from "lodash";

import { ERP_CONFIG_TYPES, IClient } from "model/entities/Client";
import { clone } from "utils/utils";

export const DEFAULT_GO_BACK_DAYS_FILTER = 365;

const prepareClientForFrontend = (client: IBEClient) => {
  client = client ?? {};

  const result: IClient = {
    // Clean backend values we don't use in frontend
    ..._.omit(
      client,
      "archived_at",
      "version",
      "to_live_timestamp",
      "oldest_kept_data"
    ),

    // Fill empty values
    go_back_days_filter:
      client.go_back_days_filter || DEFAULT_GO_BACK_DAYS_FILTER,
    gps_tracking_days: client.gps_tracking_days || [],
    dataset_name: client.bigquery_connection?.dataset_name || "",
    client_email: client.bigquery_connection?.client_email || "",
    private_key: client.bigquery_connection?.private_key || "",
    msql_connection: client.msql_connection,
    is_fieldpro_connect_enabled: client.is_fieldpro_connect_enabled ?? false,

    // Format hierarchy
    hierarchy_dependencies: _.map(
      client.hierarchy_dependencies,
      (h: IHierarchyDependency) => ({
        level_type_number: h.level_type_number,
        level_name: h.level_name,
        level_father: h.level_father,
      })
    ),

    // Format meta_hierarchy_dependencies if need be
    meta_hierarchy_dependencies:
      !client.meta_hierarchy_dependencies ||
      Array.isArray(client.meta_hierarchy_dependencies)
        ? {}
        : client.meta_hierarchy_dependencies,

    // Add active:true if no "active" attribute
    active: client.hasOwnProperty("active") ? client.active : true,

    // Add max download to 50 if this property is not defined
    max_downloads: client.hasOwnProperty("max_downloads")
      ? client.max_downloads
      : 50,
    erp_config:
      client.is_fieldpro_connect_enabled && !client.erp_config?.type
        ? { type: ERP_CONFIG_TYPES.FIELDPRO }
        : client.erp_config,

    profile: (client as IClient)?.profile, // TODO: FIX
    secrets: client.secrets?.map((secret, index) => {
      return {
        ...secret,
        index: index,
      };
    }),
  };

  // Remove all the "old" "level_x" attributes
  Object.keys(result).forEach((k) => {
    if (k.includes("level_")) delete result[k];
  });

  return result;
};

const prepareClientForBackend = (client: IClient): IBEClient => {
  const result = clone(client);
  // correct profiles
  result.web_access_right_profiles = prepareProfilesForBackend(
    result.web_access_right_profiles
  );

  result.mobile_access_right_profiles = prepareProfilesForBackend(
    result.mobile_access_right_profiles
  );

  // remove the attributes not asked by the backend
  delete result.access_right_profiles;
  delete result.id;
  delete result.use_dedicated_db;
  delete result.status;
  delete result.migration_hash;
  delete result.version;

  delete result.capacity_quota;
  delete result.capacity_used;

  delete result.license_status;
  delete result?.billing?.license_plan?.stripe_default_payment_method_id;

  if (!result.language && result.webapp_language) {
    result["language"] = result.webapp_language;
  }
  delete result.webapp_language;

  if (result.is_gps_tracking_enabled) {
    if (!result.gps_tracking_days || result.gps_tracking_days.length === 0)
      result["gps_tracking_days"] = ["mo", "tu", "we", "th", "fr", "sa", "su"];
    if (!result.time_slots_start)
      result["time_slots_start"] = new Date(new Date().setHours(9, 0, 0));
    if (!result.time_slots_stop)
      result["time_slots_stop"] = new Date(new Date().setHours(17, 0, 0));
    result.geo_checkin_distance = result.geo_checkin_distance || 100;
    result.geo_checkout_distance = result.geo_checkout_distance || 500;
  } else {
    if (result.gps_tracking_days) result["gps_tracking_days"] = [];
    delete result.time_slots_stop;
    delete result.time_slots_start;
    delete result.geo_checkin_distance;
  }

  // remove the config not set

  if (result.max_downloads === "") delete result.max_downloads;
  if (result.logo === "") delete result.logo;
  if (result.dashboard_folders) delete result.dashboard_folders;
  if (result.document_folders) delete result.document_folders;
  if (result.old_sensible_data_password === "")
    delete result.old_sensible_data_password;
  if (result.new_sensible_data_password === "")
    delete result.new_sensible_data_password;

  if (_.isEmpty(result.main_contact)) delete result.main_contact;
  if (_.isEmpty(result.country)) delete result.country;
  if (_.isEmpty(result.region)) delete result.region;
  if (_.isEmpty(result.sector)) delete result.sector;

  // remove quota download status attributes
  delete result.downloads_quota_period;
  delete result.downloads_count;
  // remove the hierarchy graph (it is another endpoint managing it)
  delete result.hierarchy_dependencies;
  // remove the profile attribute (is used only for displaying/hiding features on the frontend)
  delete result.profile;
  if (result.hasOwnProperty("_is_loading")) delete result._is_loading;
  if (result.dataset_name && result.client_email && result.private_key) {
    const cleanedKey = result.private_key.replace(/\\n/g, "\n");
    result.bigquery_connection = {
      dataset_name: result.dataset_name,
      client_email: result.client_email,
      private_key: cleanedKey,
    };
  }
  delete result.dataset_name;
  delete result.client_email;
  delete result.private_key;

  if (result.secrets) {
    result.secrets = result.secrets.map(
      (secret: ISecret & { index: number }) => {
        const { index: _, ...rest } = secret;
        return rest;
      }
    );
  }
  return result;
};

const prepareProfilesForBackend = (
  profiles: IAccessRightProfile[]
): IAccessRightProfile[] => {
  // add "s" to all actions in profile (except the "FETCH" actions: should already be set correctly)
  profiles = profiles?.map((p) => {
    // clean fields not present in the joi validation in the backend (like lockChip and _error)
    p = _.pick(p, [
      "name",
      "actions",
      "action_details",
      "access_all_resources",
      "persona",
    ]);
    Object.keys(p.actions).forEach((act) => {
      if (act[act.length - 1] !== "S" && !act.startsWith("FETCH")) {
        p.actions[`${act}S`] = p.actions[act];
      }
    });
    if (p.action_details) {
      Object.keys(p.action_details).forEach((act) => {
        if (
          p.action_details &&
          act[act.length - 1] !== "S" &&
          !act.startsWith("FETCH")
        ) {
          p.action_details[`${act}S`] = p.action_details[act];
        }
      });
    }

    // add "Assign teams to mobile users" if "EDIT_TEAMS"
    if (p.actions["EDIT_TEAMS"]) {
      p.actions["ASSIGN_MOBILE_USERS_TO_TEAMS"] = true;
    }
    delete p["index"];
    return p;
  });
  return profiles;
};

export { prepareClientForBackend, prepareClientForFrontend };
