import { FormFieldBackground } from "components/Forms/Form";
import InputBoolean from "components/Input/InputBoolean";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputSelect from "components/Input/InputSelect";
import { getErpConfigCurrencyOptions } from "containers/clients/utils";
import { useTranslations } from "hooks";
import { IOption } from "model/application/components";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { ERP_CONFIG_TYPES, IClient } from "model/entities/Client";

import DeliveryManagement from "./DeliveryManagement";

export interface IOrdersManagement {
  viewMode?: TViewMode;
  client: IClient;
  onChange: (
    value: boolean | string | object | unknown[],
    name: string
  ) => void;
  errors?: { [key: string]: string };
  onChangeAttributeValues: (
    name: string,
    value: unknown[],
    shouldRerender?: boolean
  ) => void;
}

function OrdersManagement({
  client,
  onChange,
  viewMode,
  errors = {},
  onChangeAttributeValues,
}: Readonly<IOrdersManagement>) {
  const defaultValue = client.is_fieldpro_connect_enabled;
  const lang = useTranslations();
  const langKey = lang.containers.clients.subCategories.clients.createEditModal;

  const handleInputFieldproConnectChange = (
    value: boolean | object,
    name: string
  ) => {
    onChange(value, name);
    onChange(value ? { type: ERP_CONFIG_TYPES.FIELDPRO } : {}, "erp_config");
  };

  const handleErpAttChange = (value: IOption[] | string, name: string) => {
    let updatedErpConfig = {};
    if (name === "currencies" && Array.isArray(value)) {
      updatedErpConfig = {
        ...client.erp_config,
        distributor: {
          ...client.erp_config?.distributor,
          currencies: value.map((item: { key: string }) => item.key),
        },
      };
    }

    if (name === "type") {
      updatedErpConfig = {
        ...client.erp_config,
        type: value,
      };
    }

    onChange(updatedErpConfig, "erp_config");
  };
  return (
    <FormFieldBackground viewMode={viewMode ?? "EDIT"}>
      <InputBoolean
        defaultValue={defaultValue}
        onChange={handleInputFieldproConnectChange}
        viewMode={viewMode}
        lang={langKey.inputFieldproConnect}
        name="is_fieldpro_connect_enabled"
        dataTestId="is_fieldpro_connect_enabled"
      />
      {client.is_fieldpro_connect_enabled && (
        <>
          <InputSelect
            viewMode={viewMode}
            title={`\u00A0\u00A0${langKey.inputErpConfigType.title}`}
            error={errors?.erp_config_type}
            name={"type"}
            value={client.erp_config?.type}
            options={[
              {
                key: "FIELDPRO",
                label: "Fieldpro",
              },
            ]}
            onChange={handleErpAttChange}
            dataTestId="erp_config_type"
          />
          <InputMultipleSelect
            viewMode={viewMode}
            dataTestId="erp_config_currencies"
            langlabel={{
              ...langKey.inputErpConfigCurrencies,
              title: `\u00A0\u00A0${langKey.inputErpConfigCurrencies.title}`,
            }}
            forceDropDownMenu={true}
            name={"currencies"}
            options={getErpConfigCurrencyOptions()}
            multipleSelection={true}
            onChange={handleErpAttChange}
            error={errors?.erp_config_currencies}
            defaultValue={client.erp_config?.distributor?.currencies?.map(
              (currency: string) => ({
                key: currency,
                label: currency,
              })
            )}
            lang={lang}
          />
          <DeliveryManagement
            client={client}
            onChangeDeliveryWorkflows={(values: IOption<string>[]) => {
              // remove indexes
              const valuesFormatted = values.map((value: any) => {
                const { index: _, ...rest } = value;
                return rest;
              });
              onChangeAttributeValues("delivery_workflows", valuesFormatted);
            }}
            onChange={onChange}
            viewMode={viewMode}
          />
        </>
      )}
    </FormFieldBackground>
  );
}

export default OrdersManagement;
