import { makeStyles, Popover, PopoverProps } from "@material-ui/core";
import classNames from "classnames";

import { defaultBackground } from "assets/colors";

interface IPopoverWithArrow extends PopoverProps {
  withoutArrow?: boolean;
  hPosition?: "left" | "right" | "center";
  orientation?: "top" | "bottom";
  noPointerEvents?: boolean;
}

const styles = {
  PaperWithoutArrow: {
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.1), 0 20px 40px 0 rgba(0, 0, 0, 0.1)",
  },
  PaperWithArrow: {
    margin: "12px 0",
    zIndex: 1,
    overflow: "unset",
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.1), 0 20px 40px 0 rgba(0, 0, 0, 0.1)",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      zIndex: -1,
      top: 0,
      width: 24,
      height: 24,
      backgroundColor: defaultBackground,
      transform: "scale(1, 0.8) translateY(-60%) rotate(-135deg)",
      boxShadow: "1px 1px 0 0 rgba(0, 0, 0, 0.1)",
    },
  },

  LeftArrow: {
    "&:before": {
      left: 24,
    },
  },

  RightArrow: {
    "&:before": {
      right: 24,
    },
  },

  CenterArrow: {
    "&:before": {
      left: "50%",
      transform:
        "translateX(-50%) scale(1, 0.8) translateY(-60%) rotate(-135deg)",
    },
  },

  Bottom: {
    "&:before": {
      top: "100%",
      transform: "scale(1, 0.8) translateY(-60%) rotate(-135deg)",
    },
  },
  noShadowArrow: {
    "&:before": {
      boxShadow: "none",
    },
  },
};

const useStyles = makeStyles(styles as any);

const getArrowClass = (
  hPosition: "left" | "right" | "center",
  orientation: "top" | "bottom" | undefined,
  classes: any
) => {
  let finalClass: string;

  switch (hPosition) {
    case "left":
      finalClass = classNames(classes.PaperWithArrow, classes.LeftArrow);
      break;
    case "right":
      finalClass = classNames(classes.PaperWithArrow, classes.RightArrow);
      break;
    case "center":
      finalClass = classNames(
        classes.PaperWithArrow,
        classes.CenterArrow,
        classes.noShadowArrow
      );
      break;
    default:
      finalClass = classes.PaperWithArrow;
      break;
  }

  return classNames(finalClass, orientation === "bottom" && classes.Bottom);
};

const PopoverWithArrow = ({
  anchorEl,
  anchorOrigin,
  transformOrigin,
  onClose,
  hPosition = "left",
  orientation,
  withoutArrow,
  children,
  noPointerEvents = false,
}: IPopoverWithArrow) => {
  const classes = useStyles();
  return (
    <Popover
      open={Boolean(anchorEl)}
      style={{
        ...(noPointerEvents && {
          pointerEvents: "none",
        }),
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={
        anchorOrigin
          ? anchorOrigin
          : {
              vertical: "bottom",
              horizontal: hPosition,
            }
      }
      transformOrigin={
        transformOrigin
          ? transformOrigin
          : {
              vertical: "top",
              horizontal: hPosition,
            }
      }
      elevation={0}
      PaperProps={{
        classes: {
          root: withoutArrow
            ? classes.PaperWithoutArrow
            : getArrowClass(hPosition, orientation, classes),
        },
      }}
    >
      {children}
    </Popover>
  );
};

export default PopoverWithArrow;
