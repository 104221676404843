import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Marker } from "react-map-gl";
import { useSelector } from "react-redux";

import { primaryColor } from "assets/colors";
import { ChartDataUtils } from "components/Dashboard/Chart/ChartDataUtils";
import { getThousandSeparator } from "containers/authentication/redux/selector";

import { TClusterFeature } from "./ClusterLayer";

const styles = {
  customCluster: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: primaryColor,
    borderRadius: "50%",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
} as const;

const useStyles = makeStyles(styles);

export interface IClusterMarkerProps {
  cluster: TClusterFeature;
  onClusterClick: () => void;
  size: number;
  pieChartColors: string;
}

export type TClusterMarker = React.FunctionComponent<IClusterMarkerProps>;

export const ClusterMarker: TClusterMarker = (props) => {
  const classes = useStyles();

  const { cluster, onClusterClick, size: dimension, pieChartColors } = props;
  const [longitude, latitude] = cluster.geometry.coordinates;
  const thousandSeparator = useSelector(getThousandSeparator);

  return (
    <Marker
      key={cluster.properties.cluster_id}
      latitude={latitude}
      longitude={longitude}
      draggable={false}
      anchor="center"
    >
      <div
        style={{
          width: dimension,
          height: dimension,
          borderRadius: "50%",
          backgroundImage: `${pieChartColors}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          onClick={onClusterClick}
          className={classes.customCluster}
          style={{
            width: dimension - 12,
            height: dimension - 12,
          }}
        >
          {ChartDataUtils.addThousandSeparator(
            cluster.properties.point_count,
            thousandSeparator
          )}
        </div>
      </div>
    </Marker>
  );
};

export default ClusterMarker;
