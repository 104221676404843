import "@yaireo/tagify/dist/tagify.css";

import { Theme } from "@material-ui/core";

import * as colors from "assets/colors";

const styles = (theme: Theme) => ({
  // NOTE: tagify lib seems buggy and poorly customisable, so we wrap the component and apply styles manually
  TagifyInputWrapper: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
    flexGrow: 1,
    "&> .tags-input": {
      width: "100%",
    },
    "& .tagify--mix": {
      "--tagify-dd-color-primary": theme.palette.primary.light,
      "--tag-bg": theme.palette.primary.light,
      "--tag-text-color": "white",
      "--tag-invalid-color": "red",
      "--tag-text-color--edit": theme.palette.primary.light,
      "--tag-hover": theme.palette.primary.light,
      "--tag-remove-btn-color": "white",
      "--tags-bg": "#EEE",
      "--placeholder-color": colors.GreyBorder,
      "--placeholder-color-focus": colors.GreyBorder,
      "--tags-disabled-bg": "#F1F1F1",
      borderRadius: "5px",
      position: "relative",
      padding: 0,
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "1.25",
      display: "flex",
      flexGrow: 1,
    },
    "&.default .tagify--mix": {
      "--tags-hover-border-color": theme.palette.primary.light,
      "--tags-focus-border-color": theme.palette.primary.light,
      "--tagify-dd-bg-color": theme.palette.primary.light,
      "tagify--focus": theme.palette.primary.light,
      "tagify__dropdown__wrapper-color": theme.palette.primary.light,
      "tagify__dropdown__item:active": theme.palette.primary.light,
      border: `1px solid ${colors.GreyBorder}`,
      "&:hover, &:focus, &:blur, &:active": {
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
    "& .tagify--mix span.tagify__input": {
      padding: "9px 8px",
      color: colors.Black,
      fontWeight: "400",
      lineHeight: 1.25,
    },
    /* Not needed anymore, and causes input to be higher before typing
     * // Prevent placeholder from overflowing in ConditionBuilder
     * // (text is cropped... but better than overlapping)
     * "& .tagify--empty .tagify__input:before": {
     *   overflow: "hidden",
     *   textOverflow: "ellipsis",
     *   width: "100%",
     * },
     */
    "&.error .tagify--mix": {
      "--tags-hover-border-color": "red",
      "--tags-focus-border-color": "red",
      "--tagify-dd-bg-color": "red",
      border: `1px solid red`,
      "&:hover, &:focus, &:blur, &:active": {
        border: `1px solid red`,
      },
    },
  },
});

export default styles;
