import React from "react";

import { Button, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { CalendarEventStatus } from "fieldpro-tools";
import _ from "lodash";

import * as colors from "assets/colors";
import SmallIconButton from "components/Buttons/SmallIconButton";
import DefaultChip from "components/Chip/DefaultChip";
import { useTranslations } from "hooks";
import { useMomentTimeZone } from "hooks/useMomentTimeZone";

import { TCalendarEventFE } from "./redux/types";
import { EventColors } from "./styles";

const useStyles = makeStyles({
  popoverPaper: {
    divShadow: `0px 6px 10px 0px ${colors.GreyLight}`,
    paddingBottom: "6px",
  },
  div: {
    minWidth: "300px",
  },
  gridContainer: {
    gridTemplateRows: "auto 1fr",
    display: "grid",
    padding: "6px 12px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gridColumnGap: 16,
  },
  titlediv: {
    flexGrow: 2,
    display: "flex",
    alignItems: "center",
    gridColumnGap: 5,
  },
  title: {
    maxWidth: "200px",
    fontSize: 16,
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chip: {
    height: 20,
    fontSize: 12,
    borderRadius: 4,
  },
  iconButtonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "16px",
  },
  customerName: {
    fontWeight: 500,
    fontSize: 16,
  },
  dateString: {
    color: colors.GreyDark,
    fontSize: 14,
    fontWeight: 500,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "right",
    gridColumnGap: 8,
    paddingTop: "16px",
  },
});

export interface BoardItemMenuProps {
  id: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleClickEdit: () => void;
  handleClickDelete: () => void;
  handleClickDecline: () => void;
  handleClickApprove: () => void;
  event?: TCalendarEventFE;
  isManager: boolean;
}

const BoardItemMenu: React.FC<BoardItemMenuProps> = React.memo(
  ({
    id,
    open,
    anchorEl,
    handleClose,
    handleClickEdit,
    handleClickDelete,
    handleClickDecline,
    handleClickApprove,
    event,
    isManager,
  }) => {
    const classes = useStyles();
    const lang = useTranslations();
    const { moment } = useMomentTimeZone();

    const customerName = event?.customer_name;

    if (!event) return null;

    const small =
      moment(event.end_time).diff(event.start_time, "minutes") <= 30;
    const startTime = moment(event.start_time).format("hh:mm");
    const endTime = moment(event.end_time).format(`hh:mm ${small ? "" : "A"}`);
    const eventTimeSlotString = `${startTime}-${endTime}`;
    const dateString = moment(event.start_time).format("dddd, Do MMMM");
    const status = event.status ?? CalendarEventStatus.PENDING;

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={{
          className: classes.popoverPaper,
        }}
      >
        <div className={classes.div}>
          <div className={classes.gridContainer}>
            <div className={classes.titleContainer}>
              <div className={classes.titlediv}>
                <div className={classes.title}>{event.title}</div>
                <div>
                  <DefaultChip
                    label={_.upperFirst(
                      _.lowerCase(
                        lang.containers.calendar.subCategories.calendar
                          .createEditModal.inputStatus.options?.[status] ??
                          event.status
                      )
                    )}
                    style={{
                      background: EventColors.statusText[status],
                      color: EventColors.fillColor[status],
                    }}
                    className={classes.chip}
                  />
                </div>
              </div>
              <div className={classes.iconButtonContainer}>
                {status !== CalendarEventStatus.COMPLETED && (
                  <SmallIconButton onClick={handleClickEdit}>
                    <EditIcon htmlColor={colors.GreyDark} fontSize="small" />
                  </SmallIconButton>
                )}
                <SmallIconButton onClick={handleClickDelete}>
                  <DeleteIcon htmlColor={colors.red} fontSize="small" />
                </SmallIconButton>
                <SmallIconButton onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </SmallIconButton>
              </div>
            </div>
            <div className={classes.descriptionContainer}>
              {customerName && (
                <div className={classes.customerName}>{customerName}</div>
              )}
              <div className={classes.dateString}>
                {`${dateString} ${eventTimeSlotString}`}
              </div>
              <div>{event.description}</div>
              {[
                CalendarEventStatus.PENDING,
                CalendarEventStatus.DECLINED,
              ].includes(status) &&
                isManager && (
                  <div
                    className={classes.buttonContainer}
                    data-testid="event-manager-actions"
                  >
                    <Button
                      color="secondary"
                      variant="outlined"
                      disableElevation
                      onClick={handleClickDecline}
                      disabled={status === CalendarEventStatus.DECLINED}
                      data-testid="event-decline-button"
                    >
                      {_.upperFirst(lang.genericTerms.decline)}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      disableElevation
                      onClick={handleClickApprove}
                      data-testid="event-approve-button"
                    >
                      {_.upperFirst(lang.genericTerms.accept)}
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </Popover>
    );
  }
);

export default BoardItemMenu;
