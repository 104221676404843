import { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";
import { ResponsiveCalendar } from "@nivo/calendar";
import { object } from "prop-types";

import Chart from "./Chart";
import Colors from "./Colors";
import SliceTooltip from "./SliceTooltip";
import styles from "./styles";

export class OptimetriksCalendar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      chart: {},
      nivoConfiguration: {},
    };
  }

  render() {
    const { chart, nivoConfiguration } = this.props;
    const days = chart.data ? chart.data.map((d) => d.x) : undefined;
    const from =
      chart.data && days && days.length > 0
        ? days.reduce(function (a, b) {
            return a < b ? a : b;
          })
        : null;
    const to =
      chart.data && days && days.length > 0
        ? days.reduce(function (a, b) {
            return a > b ? a : b;
          })
        : null;
    let colors = nivoConfiguration.colors
      ? nivoConfiguration.colors
      : [Colors.strColorToHex("black")[0]];
    colors =
      colors.length === 1
        ? Colors.createPalette(
            "#ffffff",
            colors[0],
            Chart.defaultNumberInPalette
          )
        : colors;
    return (
      <ResponsiveCalendar
        data={this.formatData(chart.data)}
        from={from}
        to={to}
        emptyColor={
          nivoConfiguration.emptyColor
            ? nivoConfiguration.emptyColor
            : Colors.strColorToHex("lightgray")[0]
        }
        colors={colors}
        margin={{ top: 10, right: 20, bottom: 30, left: 20 }}
        yearSpacing={20}
        monthLegendPosition={"after"}
        monthBorderColor={
          nivoConfiguration.monthBorderColor
            ? Colors.strColorToHex(nivoConfiguration.monthBorderColor)[0]
            : "#ffffff"
        }
        dayBorderColor={
          nivoConfiguration.dayBorderColor
            ? Colors.strColorToHex(nivoConfiguration.dayBorderColor)[0]
            : "#ffffff"
        }
        monthBorderWidth={5}
        daySpacing={0}
        dayBorderWidth={1}
        legends={[
          {
            anchor: "bottom-right",
            direction: "row",
            translateY: 40,
            itemCount: 5,
            itemWidth: 40,
            itemHeight: 40,
            itemsSpacing: 10,
            itemDirection: "right-to-left",
          },
        ]}
        tooltip={({ day, _date, value, _color, _x, _y, _size }) => {
          /* istanbul ignore next */
          return (
            <SliceTooltip
              id={day}
              value={value}
              color={nivoConfiguration.colors[0]}
              separator={nivoConfiguration.thousandSeparator}
            ></SliceTooltip>
          );
        }}
      />
    );
  }

  formatData = (data) => {
    if (!data) return [];
    return data.map((d) => {
      d.day = d.x;
      d.value = d.y;
      return d;
    });
  };
}
OptimetriksCalendar.propTypes = {
  classes: object,
  chart: object,
  nivoConfiguration: object,
};

export default withStyles(styles)(OptimetriksCalendar);
