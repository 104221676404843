import React, { useState } from "react";

import { Box, Button } from "@material-ui/core";
import { isOptimetriksRole } from "fieldpro-tools";
import _ from "lodash";

import {
  ErrorColor,
  GreyDark,
  red,
  White,
  YellowSecondary,
} from "assets/colors";
import InputBoolean from "components/Input/InputBoolean";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import InputTime from "components/Input/InputTime";
import PopoverWithArrow from "components/Popover/PopoverWithArrow";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { CLIENT_STATUS, IClient } from "model/entities/Client";
import { isEmptyValue } from "utils/isEmptyValue";

import { useTranslations } from "./../../../../../hooks/useTranslations";
import { ClientFormWrapper } from "./ClientFormWrapper";

export interface IClientManagementTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  viewOnly?: boolean;
  isEnterprise?: boolean;
  ignoreThreshold?: boolean;
  shouldCreateStripeCustomer?: boolean;
  userRole: string;
  onInputChange: any;
  onChangeAvailableJobs: any;
  onSelectChange: (optSelected: any, variableName: string) => void;
  shouldMarkError: any;
  onChangeDailyMaintenance: any;
  onChangeGoBackDays: any;
  onMultipleSelectChange: any;
  onSave?: () => void;
  onDiscard?: () => void;
  onCreate?: () => void;
  onForceMaintenance: (clientId: string, runAll?: boolean) => void;
  onSetClientLive: any;
  client?: IClient;
  hour_daily_maintenance?: number;
  minute_daily_maintenance?: number;
  data_vis_owner?: string;
  payments_overdue?: boolean;
  disableGoLive?: boolean;
}

export const ClientManagementTab: React.FunctionComponent<
  IClientManagementTabProps
> = ({
  id,
  dbname,
  viewMode = "CREATE",
  go_back_days_filter,
  max_downloads,
  hour_daily_maintenance,
  minute_daily_maintenance,
  onInputChange,
  shouldMarkError,
  userRole,
  onChangeDailyMaintenance,
  onChangeGoBackDays,
  onDiscard,
  onCreate,
  onForceMaintenance,
  status,
  onSetClientLive,
  ignoreThreshold,
  disableGoLive,
}) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  const isCreationMode = viewMode === "CREATE";

  const [anchorEl, setAnchorEl] = useState(null);

  const getMaintenanceTime = (): Date | undefined => {
    return !isEmptyValue(hour_daily_maintenance)
      ? new Date(
          new Date().setUTCHours(
            hour_daily_maintenance as number,
            minute_daily_maintenance
          )
        )
      : undefined;
  };

  const displayGoLiveButton =
    isOptimetriksRole(userRole) && _.includes([CLIENT_STATUS.PILOT], status);

  const langKeyGoLive =
    lang.containers.clients.subCategories.clients.createEditModal.inputGoLive
      .customMessage;

  return (
    <Box display="flex" flexDirection="column" gridRowGap="20px">
      <ClientFormWrapper
        viewMode={viewMode}
        onCreate={onCreate}
        onDiscard={onDiscard}
        label={label.inputClientManagement.title}
      >
        <InputText
          lang={label.inputClientManagementHistoricalDaysOnReports}
          onChange={onChangeGoBackDays}
          defaultValue={go_back_days_filter}
          name="go_back_days_filter"
          required={false}
          autoComplete={"off"}
          disabled={!(isOptimetriksRole(userRole) || isCreationMode)}
          viewMode={viewMode}
        />
        <InputText
          defaultValue={max_downloads}
          error={shouldMarkError("max_downloads")}
          name="max_downloads"
          key={`max_downloads`}
          type={INPUT_TEXT_TYPE.INTEGER}
          lang={label.inputClientManagementMaxDownload}
          onChange={onInputChange}
          required={false}
          viewMode={viewMode}
        />

        {isOptimetriksRole(userRole) && (
          <InputBoolean
            viewMode={viewMode}
            name="ignore_threshold"
            onChange={onInputChange}
            defaultValue={ignoreThreshold}
            lang={{
              title: label.clientManagement.options.ignoreThreshold,
            }}
            error={shouldMarkError("ignore_threshold")}
          />
        )}

        <InputTime
          viewMode={viewMode}
          defaultValue={getMaintenanceTime()}
          name="time_daily_maintenance"
          lang={label.inputClientManagementHourDailyMaintenance}
          onChange={onChangeDailyMaintenance}
          required={false}
          clearable
        />

        <PopoverWithArrow
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          hPosition={"center"}
          noPointerEvents={true}
          anchorOrigin={{
            horizontal: 30,
            vertical: 30,
          }}
          transformOrigin={{
            horizontal: "center",
            vertical: 120,
          }}
          orientation={"bottom"}
        >
          <div
            style={{
              padding: "6px 12px",
              textAlign: "center",
              color: GreyDark,
            }}
          >
            {langKeyGoLive.allRequiredFields}
            <br /> <span style={{ color: red }}>*</span>{" "}
            {langKeyGoLive.indicatesRequiredField}
          </div>
        </PopoverWithArrow>

        {viewMode !== "CREATE" && !!id && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            gridGap="16px"
            margin="60px 0 -72px 0"
          >
            {displayGoLiveButton ? (
              <div
                onMouseEnter={(e) => {
                  if (disableGoLive) {
                    setAnchorEl(e.currentTarget as any);
                  }
                }}
                onMouseLeave={() => setAnchorEl(null)}
              >
                <Button
                  onClick={() => onSetClientLive({ id })}
                  disabled={viewMode === "VIEW" || disableGoLive}
                  data-testid="go-live-button"
                  ref={anchorEl}
                  variant="outlined"
                  color="secondary"
                >
                  {label.inputGoLive.title}
                </Button>
              </div>
            ) : null}

            <Button
              style={{ background: YellowSecondary, color: White }}
              onClick={() => onForceMaintenance(id)}
            >
              {label.inputForceMaintenance.title}
            </Button>

            {dbname === "admin" ? (
              <Button
                style={{ background: ErrorColor, color: White }}
                onClick={() => onForceMaintenance(id, true)}
              >
                {`Force maintenance to all clients`}
              </Button>
            ) : null}
          </Box>
        )}
      </ClientFormWrapper>
    </Box>
  );
};

export default ClientManagementTab;

export const MANAGEMENT_TAB_FIELDS: Array<keyof IClient> = [
  "app_user_licenses",
  "billing_owner_mail",
  "billing_type",
  "stripe_customer_id",
  "invoiced_by",
  "payments_overdue",
  "main_contact",
  "status",
  "category",
  "is_enterprise",
  "country",
  "region",
  "sector",
  "sales_owner",
  "operations_owner",
  "data_vis_owner",
  "max_downloads",
  "ignore_threshold",
];
