import { META_EXPRESSION_SCOPE } from "fieldpro-tools";
import _ from "lodash";

import { TExpressionType } from "model/entities/Expression";
import { CUSTOM_FIELD_TYPE } from "model/entities/Workflow";

import { ICustomField } from "./getSchemaForMetaExpression";

const OPERATION_FIELD_TYPES = [
  CUSTOM_FIELD_TYPE.INTEGER,
  CUSTOM_FIELD_TYPE.DECIMAL,
  CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST,
  CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_INTEGER,
  CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_DECIMAL,
  CUSTOM_FIELD_TYPE.MATRIX_ON_LIST,
  CUSTOM_FIELD_TYPE.COMPUTE,
  CUSTOM_FIELD_TYPE.RATING,
  CUSTOM_FIELD_TYPE.SINGLE_CHOICE,
  CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE,
];

const CONDITION_FIELD_TYPES = [
  ...OPERATION_FIELD_TYPES,
  CUSTOM_FIELD_TYPE.TEXT,
  CUSTOM_FIELD_TYPE.PLAIN_TEXT,
  CUSTOM_FIELD_TYPE.BOOLEAN,
  CUSTOM_FIELD_TYPE.PHONE_NUMBER,
  CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_TEXT,
  CUSTOM_FIELD_TYPE.SINGLE_CHOICE,
  CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE,
  CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST,
];

export const getCustomFieldOptions = (
  schema: ICustomField[],
  scope?: META_EXPRESSION_SCOPE,
  expressionType?: TExpressionType,
  fieldType?: CUSTOM_FIELD_TYPE,
  includeMatrix = true
) => {
  return schema
    .filter((e) => {
      if (expressionType === "OPERATION") {
        return _.includes(OPERATION_FIELD_TYPES, e.type);
      }
      if (expressionType === "CONDITION") {
        return _.includes(CONDITION_FIELD_TYPES, e.type);
      }
      return true;
    })
    .filter((e) => {
      // if we are filtering schema items by field type check the rule on including matrix
      if (fieldType && e.type === CUSTOM_FIELD_TYPE.MATRIX_ON_LIST) {
        return includeMatrix;
      }
      return (
        !(e.tag === "_name" && scope === META_EXPRESSION_SCOPE.CUSTOMER) &&
        (!fieldType || e.type === fieldType)
      );
    })
    .map((e: any) => ({
      key: e.tag,
      label: e.tag,
    }));
};
