import React, { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import _ from "lodash";

import { userCanViewClientTab } from "containers/clients/utils";
import { useTranslations } from "hooks";
import TLang from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient, INVOICED_BY } from "model/entities/Client";

import ClientAndBusinessDetails from "./ClientAndBusinessDetails";
import { ClientSettingsTabs } from "./ClientForm";
import ClientManagementTab from "./Tabs/ClientManagementTab";
import ClientOwnersManager from "./Tabs/ClientOwnersManager";
import CustomBillingTab from "./Tabs/CustomBillingTab";
import TitledSection from "./TitledSection";

export const REQUIRED_GOLIVE_FIELDS: Array<keyof IClient> = [
  "stripe_customer_id",
  "invoiced_by",
  "billing_owner_mail",
  "region",
  "country",
  "sector",
];

const ClientManagementForm: React.FC<{
  user_role: string;
  viewOnly: boolean;
  viewMode: TViewMode;
  client: Partial<IClient>;
  userRole: string;
  should_create_stripe_id?: boolean;
  errors: Record<string, string>;
  showGoLiveErrors: boolean;
  handleChangeDailyMaintenance: (value: any, name: string) => void;
  handleChangeGoBackDays: (value: any, name: string) => void;
  handleChangeAvailableJobs: (value: any, name: string) => void;
  handleInputChange: (value: any, name: string) => void;
  handleMultipleSelectChange: (value: any, name: string) => void;
  handleSelectChange: (value: any, name: string) => void;
  handleForceMaintenance: (clientId: string, runAll?: boolean) => void;
  handleSetClientLive: (args: any) => void;
  onChangeAvailableJobs: (value: any, name: string) => void;
  onMultipleSelectChange: (value: any, name: string) => void;
  shouldMarkError: (key: string) => string | undefined;
  onSetClientLive: (args: any) => void;
  onInputChange: (value: any, name: string) => void;
  getFieldError: (key: string) => string | undefined;
  onSave: () => void;
  onDiscard: () => void;
  onCreate: () => void;
}> = ({
  user_role,
  viewMode,
  viewOnly,
  client,
  handleInputChange,
  handleMultipleSelectChange,
  handleSelectChange,
  getFieldError,
  userRole,
  should_create_stripe_id,
  onSave,
  onDiscard,
  onCreate,
  handleForceMaintenance,
  handleSetClientLive,
  handleChangeAvailableJobs,
  handleChangeDailyMaintenance,
  handleChangeGoBackDays,
}) => {
  const {
    status,
    app_user_licenses,
    invoiced_by,
    billing_owner_mail,
    billing_type,
    stripe_customer_id,
    language,
    id,
    category,
    is_enterprise,
    ignore_threshold,
    payments_overdue,
    dbname,
    max_downloads,
    hour_daily_maintenance,
    minute_daily_maintenance,
    sales_owner,
    available_jobs,
    operations_owner,
    data_vis_owner,
    go_back_days_filter,
  } = client;

  const lang = useTranslations();
  const langKey =
    lang.containers.clients.subCategories.clients.createEditModal
      .clientManagement.options;

  const [disableGoLiveButton, setDisableGoLiveButton] = useState(false);

  const [localErrors, setErrors] = useState<Record<string, string>>({});

  function shouldDisplayError(key: string) {
    if (viewMode == "VIEW") return;

    const error = getFieldError(key);

    if (!error && disableGoLiveButton) {
      return localErrors[key];
    }

    return error;
  }

  function handleClickGoLive(args: any) {
    if (_.isEmpty(localErrors)) {
      handleSetClientLive(args);
    } else {
      setDisableGoLiveButton(true);
    }
  }

  useEffect(() => {
    const goLiveErrors = validateGoLiveFields(client, lang);

    setErrors(goLiveErrors);

    if (_.isEmpty(goLiveErrors)) {
      setDisableGoLiveButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(client)]);

  return (
    <Box display={"flex"} flexDirection={"column"} gridRowGap={"20px"}>
      {userCanViewClientTab({
        tabId: ClientSettingsTabs.BILLING,
        client: client as IClient,
        role: user_role,
      }) && (
        <TitledSection title={langKey.billingAndPayments}>
          <CustomBillingTab
            viewMode={viewMode}
            id={id}
            status={status}
            app_user_licenses={app_user_licenses}
            should_create_stripe_id={should_create_stripe_id}
            billing_owner_mail={billing_owner_mail}
            billing_type={billing_type}
            stripe_customer_id={stripe_customer_id}
            language={language}
            category={category}
            onInputChange={handleInputChange}
            onSelectChange={handleSelectChange}
            shouldMarkError={shouldDisplayError}
            userRole={userRole}
            invoiced_by={invoiced_by}
          />
        </TitledSection>
      )}

      <TitledSection title={langKey.clientAndBusinessDetails}>
        <ClientAndBusinessDetails
          client={client}
          handleInputChange={handleInputChange}
          handleMultipleSelectChange={handleMultipleSelectChange}
          handleSelectChange={handleSelectChange}
          shouldMarkError={shouldDisplayError}
          user_role={user_role}
          viewMode={viewOnly ? "VIEW" : viewMode}
        />
      </TitledSection>

      <TitledSection title={langKey.internalOwnership}>
        <ClientOwnersManager
          client={client}
          onMultipleSelectChange={handleMultipleSelectChange}
          shouldMarkError={shouldDisplayError}
          viewMode={viewOnly ? "VIEW" : viewMode}
        />
      </TitledSection>

      <TitledSection title={langKey.reportsDataAndMaintenance}>
        <ClientManagementTab
          id={id}
          client={client as IClient}
          isEnterprise={is_enterprise}
          ignoreThreshold={ignore_threshold}
          payments_overdue={payments_overdue}
          dbname={dbname}
          viewMode={viewMode}
          max_downloads={max_downloads}
          hour_daily_maintenance={hour_daily_maintenance}
          minute_daily_maintenance={minute_daily_maintenance}
          onChangeDailyMaintenance={handleChangeDailyMaintenance}
          onChangeGoBackDays={handleChangeGoBackDays}
          onMultipleSelectChange={handleMultipleSelectChange}
          category={category}
          sales_owner={sales_owner}
          operations_owner={operations_owner}
          data_vis_owner={data_vis_owner}
          invoiced_by={invoiced_by}
          go_back_days_filter={go_back_days_filter}
          userRole={user_role}
          language={language}
          available_jobs={available_jobs}
          onSelectChange={handleSelectChange}
          onInputChange={handleInputChange}
          onChangeAvailableJobs={handleChangeAvailableJobs}
          shouldMarkError={shouldDisplayError}
          onSave={onSave}
          onDiscard={onDiscard}
          onCreate={onCreate}
          onForceMaintenance={handleForceMaintenance}
          viewOnly={viewOnly}
          onSetClientLive={handleClickGoLive}
          status={status}
          disableGoLive={disableGoLiveButton}
        />
      </TitledSection>
    </Box>
  );
};

export default ClientManagementForm;

function validateGoLiveFields(client: Partial<IClient>, lang: TLang) {
  const errors: Record<string, string> = {};

  REQUIRED_GOLIVE_FIELDS.forEach((field) => {
    if (_.isEmpty(client[field])) {
      errors[field] = lang.components.inputErrors.empty;
    }
  });

  if (client.invoiced_by != INVOICED_BY.OPTIMETRIKS) {
    delete errors.stripe_customer_id;
  }

  return errors;
}
