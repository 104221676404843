import { CUSTOM_FIELD_TYPE, IMetaExpressionOptions } from "fieldpro-tools";
import _ from "lodash";

import { IActivityReportInTable, IQuestion } from "model/entities/Workflow";
import { resolveOperations } from "utils/expressions/operationSolver";
import { resolveMetaExpressionInOperation } from "utils/metaExpressions/metaExpressionResolution";

import prepareReportStateForBackend from "./prepareReportStateForBackend";

interface IRefreshReportComputeValues {
  schema: IQuestion[];
  report: IActivityReportInTable;
  metaExpressionOptions?: IMetaExpressionOptions;
}

export const refreshReportComputeValues = ({
  schema,
  report,
  metaExpressionOptions,
}: IRefreshReportComputeValues) => {
  const result: IActivityReportInTable = { ...report };

  _.forEach(schema, (field: IQuestion) => {
    if (field.type !== CUSTOM_FIELD_TYPE.COMPUTE) {
      return;
    }
    const backendReport = prepareReportStateForBackend(result, schema);
    const rawOperations = _.map((field as IQuestion).operations, (operation) =>
      resolveMetaExpressionInOperation(operation, {
        ...(metaExpressionOptions ?? {}),
        activity_report: backendReport,
      })
    );
    result[field.tag] = resolveOperations(rawOperations);
  });
  return result;
};
