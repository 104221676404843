import {
  CUSTOM_FIELD_TYPE,
  IActivity as IBEActivity,
  IChaining,
  IQuestion as IBEQuestion,
  IWorkflow as IBEWorkflow,
  NOTIFICATION_JOB_PARAMETER,
} from "fieldpro-tools/dist/src/types";
import { IJobParam } from "fieldpro-tools/src/types";
import _ from "lodash";

import {
  archiveDashboardsSuccessActionCreator,
  restoreDashboardsSuccessActionCreator,
} from "containers/dashboards/redux/actionCreators";
import {
  archiveListSuccessAction,
  restoreListSuccessAction,
} from "containers/lists/redux/actionCreators";
import { IOption } from "model/application/components/index";
import { IActivity, IQuestion, IWorkflow } from "model/entities/Workflow";
import { questionTypeToColumnType } from "utils/businessUtils";
import { clone } from "utils/utils";

import {
  archiveActivitySuccessActionCreator,
  restoreActivitySuccessActionCreator,
} from "../actionCreators";

/*
 *
 * IMPORTANT: WE MUST AVOID MUTATIONS HERE, OTHERWISE WE ARE DIRECTLY CHANGING
 * OBJECTS THAT COULD BE STORED IN REACT / REDUX STATE
 *
 */

//UTILS
const isClearValue = (value: string | object) => {
  if (_.isString(value)) {
    return value === "__CLEAR";
  }
  if (_.isObject(value)) {
    return (value as any)?.key === "__CLEAR";
  }
  return false;
};

export const removeClearValues = (params: IJobParam[]): IJobParam[] => {
  const paramsFilter = _.filter(params, (el) => {
    if (isClearValue(el.value)) return false;
    return true;
  });
  return paramsFilter;
};

export const transformValueByType = (value: any, type?: CUSTOM_FIELD_TYPE) => {
  if (_.isString(value)) return value;
  switch (type) {
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST: {
      return value.key;
    }

    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST: {
      const keys = value.map((v: any) => v.key);
      return _.join(keys, ",");
    }

    default:
      return value;
  }
};

//FRONTEND
const prepareDestinationParamForFrontend = (params: IJobParam[]) => {
  const { MULTIPLE_CHOICE, MULTIPLE_CHOICE_ON_LIST } = CUSTOM_FIELD_TYPE;
  return _.map(params, (param) => {
    let value;

    const splitArrayCondition =
      param.key === NOTIFICATION_JOB_PARAMETER.RECIPIENTS ||
      _.includes([MULTIPLE_CHOICE, MULTIPLE_CHOICE_ON_LIST], param.type);

    if (splitArrayCondition) {
      const splitValue = _.split(param.value, ",");
      value = _.map(splitValue, (v: string) => ({ key: v }));
    } else {
      value = param.value;
    }

    return { ...param, value };
  });
};
const formatFrontendChaining = (chaining: IChaining) => {
  if (chaining.destination_params) {
    const cleanDestinationParams = prepareDestinationParamForFrontend(
      chaining.destination_params
    );
    return {
      ...chaining,
      destination_params: cleanDestinationParams,
    };
  }
  return chaining;
};
const prepareWorkflowForFrontend = (workflow: IBEWorkflow): IWorkflow => {
  const result: IWorkflow = _.cloneDeep(workflow);
  // Add 'active: true' if no "active" attribute
  const isActive = workflow.active ?? true;
  const chainings = _.map(result.chainings, formatFrontendChaining);
  return { ...result, active: isActive, chainings };
};
const prepareWorkflowsForFrontend = (workflows: IBEWorkflow[]): IWorkflow[] => {
  return _.map(workflows, prepareWorkflowForFrontend);
};
//BACKEND
const formatRecipientsForBackend = (value: any[]) => {
  //recipients should be an array always in the backend
  const keys = _.map(value, (v: any) => v.key ?? v);
  return keys;
};
export const prepareDestinationParamForBackend = (params: IJobParam[]) => {
  const cleanParams = removeClearValues(params);
  return _.map(cleanParams, (param) => {
    let value;
    if (param.key === NOTIFICATION_JOB_PARAMETER.RECIPIENTS) {
      value = formatRecipientsForBackend(param.value);
    } else {
      value = transformValueByType(param.value, param.type);
    }
    return { ...param, value };
  });
};
const formatBackendChaining = (chaining: IChaining) => {
  if (chaining.destination_params) {
    const cleanDestinationParams = prepareDestinationParamForBackend(
      chaining.destination_params
    );
    return {
      ...chaining,
      destination_params: cleanDestinationParams,
    };
  }
  return chaining;
};
const prepareWorkflowForBackend = (workflow: IWorkflow): IBEWorkflow => {
  const result: IBEWorkflow = _.cloneDeep(workflow); // clone deep to avoid mutating input

  delete result["error"];
  delete result["is_nplet"];

  delete (result as Partial<IWorkflow>)["created_at"];
  delete (result as Partial<IWorkflow>)["created_by"];
  delete (result as Partial<IWorkflow>)["actif"];
  delete (result as Partial<IWorkflow>)["latest_report"];

  const steps = _.map(result.steps, (step) =>
    _.omit(step, "title", "description", "_chaining_ids")
  );
  const chainings = _.map(result.chainings, formatBackendChaining);

  return { ...result, steps, chainings };
};

const prepareActivityForBackend = (activity: IActivity): IBEActivity => {
  delete activity["error"];
  delete activity["report_count"];
  delete activity["report_count_since_seven_days"];
  delete activity["last_report_date"];

  if (!activity.hasOwnProperty("active")) {
    activity.active = true;
  }

  const result: any = clone(activity);

  // TODO: these could be allowed, need to update joi validation in back-end
  // activity stats
  delete result["last_report_date"];
  delete result["report_count"];
  delete result["report_count_since_seven_days"];

  // remove the "_locked" property from the questions
  delete result["deprecated_questions"];
  delete result["no_deprecated_questions"];
  delete result["report_count"];
  delete result["report_count_since_seven_days"];
  delete result["last_report_date"];

  type TChangeQuestion = (q: IQuestion) => { question: IQuestion };

  // remove "index" attributes from options, transform value to an object with a STRING type
  const removeIndexAttributesForOptions: TChangeQuestion = (q) => {
    const convertOption = (o: any) => {
      if (!(o.value && o.value.value)) {
        o.value = o.label ? o.label : o.value;
        delete o.label;
        delete o.index;
      }
      return o;
    };
    if (q?.options) q.options = q.options.map(convertOption);
    return { question: q };
  };

  // set column_type attribute
  const setColumnType: TChangeQuestion = (q) => {
    q.column_type = q.column_type
      ? q.column_type
      : questionTypeToColumnType(q.type);
    return { question: q };
  };

  // remove key an _warning from question
  const removeKey: TChangeQuestion = (q) => {
    delete q["key"];
    delete q["_warning"];
    return { question: q };
  };

  // set default values (required = false)
  const setDefaultValues: TChangeQuestion = (q) => {
    if (!q.hasOwnProperty("required")) q.required = false;
    return { question: q };
  };

  // remove "_viewdetail" attribute from question groups, questions and typed questions
  const removeViewDetail: TChangeQuestion = (q) => {
    delete q._viewdetail;
    return { question: q };
  };

  // remove "question" attribute from sub-questions
  const removeQuestionAttributes: TChangeQuestion = (q) => {
    delete q.matrixQuestion;
    return { question: q };
  };

  // remove matrix_question_tag from the conditions / operations
  const removeMatrixQuestionTagFromConditions: TChangeQuestion = (q) => {
    if (q.conditions) {
      q.conditions = q.conditions.map((c) => {
        delete c.matrix_question_tag;
        return c;
      });
    }
    return { question: q };
  };

  const applyQuestionChanges = (changes: TChangeQuestion[]) => {
    result.questionnaire.questions = result.questionnaire.questions.map(
      (q: IQuestion) => {
        for (const change of changes) {
          q = change(q).question;

          // remove empty "default_value" attribute
          if (_.isEmpty(q.default_value)) delete q.default_value;
        }
        if (q.matrix) {
          q.matrix.typed_questions = q.matrix.typed_questions.map(
            (tq: IQuestion) => {
              for (const change of changes) {
                tq = change(tq).question;
              }
              return tq;
            }
          );
        }

        delete (q as any).deprecatedOptions;
        return q;
      }
    );
  };

  // put all the deprecated questions to the questionnaire directly
  //result.questionnaire.questions = activity.deprecated_questions.concat(result.questionnaire.questions);
  delete result.deprecated_questions;
  applyQuestionChanges([
    removeKey,
    setColumnType,
    setDefaultValues,
    removeIndexAttributesForOptions,
    removeViewDetail,
    removeMatrixQuestionTagFromConditions, // apparently, I did not have to add this one...
    removeQuestionAttributes,
  ]);

  // remove matrix_question_tag in the sub-questions (but not in the questions!! (c'est Malik qui demande...))
  result.questionnaire.questions.map((q: IQuestion) => {
    if (q.matrix) {
      q.matrix.typed_questions = q.matrix.typed_questions.map((tq) => {
        if (q.conditions) {
          q.conditions = q.conditions.map((c) => {
            delete c.matrix_question_tag;
            return c;
          });
        }

        if (q.operations) {
          q.operations = q.operations.map((o) => {
            delete o.matrix_question_tag;
            return o;
          });
        }
        return tq;
      });
    }
    return q;
  });

  return result as IBEActivity;
};

const prepareQuestionOptionForFrontend = (o: any, index: number): IOption => {
  o.label = `${o.value ? o.value : "undefined"}`;
  o.index = index;
  delete o.value;
  return o;
};

const prepareQuestionsForFrontend = (
  questions: IBEQuestion[],
  matrixQuestion?: string
): IQuestion[] => {
  // FIXME: to remove when no more user is using 2.2 version
  // set "param" property to the operand of compute for no breaking change
  let res: any[] = questions.map((q) => {
    if (q.type === CUSTOM_FIELD_TYPE.COMPUTE) {
      q.operations = _.map(q.operations, (o: any) => {
        if (o.first.hasOwnProperty("attr")) o.first.param = o.first.attr;
        if (o.second.hasOwnProperty("attr")) o.second.param = o.second.attr;
        delete o.first.attr;
        delete o.second.attr;
        return o;
      });
    }
    return q;
  });
  // add "_viewdetail" attribute to question groups, questions and typed questions
  res = res.map((q: IQuestion) => {
    q._viewdetail = false;
    if (matrixQuestion) q.matrixQuestion = matrixQuestion;
    if (q.matrix) {
      q.matrix.typed_questions = q.matrix.typed_questions.map((tq: any) => {
        tq._viewdetail = false;
        return tq;
      });
    }
    return q;
  });
  // change option value.value to label, add index
  res = res.map((q: any) => {
    if (q.options) {
      const deprecatedOptions = q.options.filter(
        (o: any) => o.hasOwnProperty("deprecated") && o.deprecated !== 0
      );
      if (deprecatedOptions.length > 0)
        q.deprecatedOptions = deprecatedOptions.map(
          prepareQuestionOptionForFrontend
        );
      q.options = q.options
        .filter(
          (o: any) =>
            !o.hasOwnProperty("deprecated") ||
            (o.hasOwnProperty("deprecated") && o.deprecated === 0)
        )
        .map(prepareQuestionOptionForFrontend);
    }

    if (q.matrix) {
      q.matrix.typed_questions = q.matrix.typed_questions.map((sq: any) => {
        if (sq.options)
          sq.options = sq.options
            .filter(
              (o: any) =>
                !o.hasOwnProperty("deprecated") ||
                (o.hasOwnProperty("deprecated") && o.deprecated === 0)
            )
            .map(prepareQuestionOptionForFrontend);
        return sq;
      });
    }
    return q;
  });
  // add matrix_question_tag to the conditions / operations if linked to a matrix on list question
  const subQuestionTags: { tag: string; matrixTag: string }[] = res.reduce(
    (acc, curr) => {
      if (curr.matrix) {
        acc = acc.concat(
          curr.matrix.typed_questions.map((sq: IQuestion) => ({
            tag: sq.tag,
            matrixTag: curr.tag,
          }))
        );
      }
      return acc;
    },
    []
  );
  res = res.map((q: IQuestion) => {
    if (q.conditions) {
      q.conditions = q.conditions.map((cond: any) => {
        const subQTagsDetected = subQuestionTags.find(
          (e) => e.tag === cond.first.tag
        )
          ? subQuestionTags.find((e) => e.tag === cond.first.tag)
          : subQuestionTags.find((e) => e.tag === cond.second.tag);
        if (subQTagsDetected) {
          cond.matrix_question_tag = subQTagsDetected.matrixTag;
        }
        return cond;
      });
    }
    if (q.operations) {
      q.operations = q.operations.map((op: any) => {
        const subQTagsDetected = subQuestionTags.find(
          (e) => e.tag === op.first.tag
        )
          ? subQuestionTags.find((e) => e.tag === op.first.tag)
          : subQuestionTags.find((e) => e.tag === op.second.tag);
        if (subQTagsDetected) {
          op.matrix_question_tag = subQTagsDetected.matrixTag;
        }
        return op;
      });
    }

    if (q.matrix) {
      q.matrix.typed_questions = q.matrix.typed_questions.map((sq: any) => {
        const subQuestionTags = _.map(
          q.matrix?.typed_questions,
          (tq: IQuestion) => tq.tag
        );
        if (sq.conditions) {
          sq.conditions = sq.conditions.map((cond: any) => {
            if (
              subQuestionTags.includes(cond.first.tag) ||
              subQuestionTags.includes(cond.second.tag)
            ) {
              cond.matrix_question_tag = q.tag;
            }
            return cond;
          });
        }
        if (sq.operations) {
          sq.operations = sq.operations.map((op: any) => {
            if (
              subQuestionTags.includes(op.first.tag) ||
              subQuestionTags.includes(op.second.tag)
            ) {
              op.matrix_question_tag = q.tag;
            }
            return op;
          });
        }
        return sq;
      });
    }
    return q;
  });
  return res;
};

const prepareActivitiesForFrontend = (
  activities: IBEActivity[]
): IActivity[] => {
  const results: any[] = activities.map((a) => {
    const result: any = a;
    // LEGACY FIELDS
    // delete result.type;
    delete result.scope;
    delete result.is_submission_editable;
    delete result.edit_submission_offset;

    // add active:true if no "active" attribute
    if (!result.hasOwnProperty("active")) {
      result["active"] = true;
    }
    // put all the deprecated questions in a separate attribute
    result.deprecated_questions = a.questionnaire.questions.filter(
      (q: any) => q.deprecated
    );
    result.deprecated_questions = prepareQuestionsForFrontend(
      result.deprecated_questions
    );

    result.questionnaire.questions = result.questionnaire.questions.filter(
      (q: any) => !q.hasOwnProperty("deprecated")
    );
    // add "matrixQuestion" to subquestions
    result.questionnaire.questions.map((q: any) => {
      if (q.matrix) {
        q.matrix.typed_questions = q.matrix.typed_questions.map((sq: any) => {
          sq.matrixQuestion = q.tag;
          return sq;
        });
      }
      return prepareQuestionsForFrontend([q])[0];
    });
    return result;
  });
  return results as IActivity[];
};

export const updateActivityQuestionByTag = (
  activity: IActivity,
  newTag: string,
  oldTag: string,
  parent?: string
): IActivity => {
  const activityCopy = { ...activity };

  const updatedTypedQuestions = (schema?: IQuestion[]): IQuestion[] => {
    return _.map(schema ?? [], (sch) => ({
      ...sch,
      tag: sch.tag === oldTag ? newTag : sch.tag,
    }));
  };

  if (!parent) {
    return {
      ...activity,
      questionnaire: {
        ...activityCopy.questionnaire,
        questions: updatedTypedQuestions(activity.questionnaire.questions),
      },
    };
  }

  const parentSchema = _.find(activityCopy.questionnaire.questions, {
    tag: parent,
  });
  if (!parentSchema) return activityCopy;
  const typed_questions = updatedTypedQuestions([
    ...(parentSchema.matrix?.typed_questions as IQuestion[]),
  ]);
  const newParentSchema = {
    ...parentSchema,
    matrix: {
      ...parentSchema.matrix,
      typed_questions,
    },
  };

  return {
    ...activity,
    questionnaire: {
      ...activity.questionnaire,
      questions: _.map(activityCopy.questionnaire.questions, (sch) => ({
        ...(sch.tag === parent ? newParentSchema : sch),
      })),
    },
  } as IActivity;
};

export const archivedAllResourcesLinkedToWf = (
  dispatch: any,
  {
    dashboardIds,
    activitiesIds,
    listIds,
  }: { dashboardIds?: string[]; activitiesIds?: string[]; listIds?: string[] }
) => {
  if (!_.isEmpty(dashboardIds) && _.isArray(dashboardIds))
    dispatch(archiveDashboardsSuccessActionCreator(dashboardIds));

  if (!_.isEmpty(activitiesIds) && _.isArray(activitiesIds)) {
    for (const activityId of activitiesIds) {
      dispatch(archiveActivitySuccessActionCreator(activityId));
    }
  }

  if (!_.isEmpty(listIds) && _.isArray(listIds)) {
    for (const listId of listIds) {
      dispatch(archiveListSuccessAction(listId));
    }
  }
};

export const restoreAllResourcesLinkedToWf = (
  dispatch: any,
  {
    dashboardIds,
    activitiesIds,
    listIds,
  }: { dashboardIds?: string[]; activitiesIds?: string[]; listIds?: string[] }
) => {
  if (!_.isEmpty(dashboardIds) && _.isArray(dashboardIds))
    dispatch(restoreDashboardsSuccessActionCreator(dashboardIds));

  if (!_.isEmpty(activitiesIds) && _.isArray(activitiesIds)) {
    for (const activityId of activitiesIds) {
      dispatch(restoreActivitySuccessActionCreator(activityId));
    }
  }

  if (!_.isEmpty(listIds) && _.isArray(listIds)) {
    for (const listId of listIds) {
      dispatch(restoreListSuccessAction(listId));
    }
  }
};

export {
  prepareActivitiesForFrontend,
  prepareActivityForBackend,
  prepareQuestionOptionForFrontend,
  prepareQuestionsForFrontend,
  prepareWorkflowForBackend,
  prepareWorkflowsForFrontend,
};
