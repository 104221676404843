import { FormFieldBackground } from "components/Forms/Form";
import InputBoolean from "components/Input/InputBoolean";
import InputSelect from "components/Input/InputSelect";
import { useTranslations } from "hooks";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient, TERRITORY_SCOPE } from "model/entities/Client";
import { getOptionsInputSelect } from "utils/getOptionsInputSelect";

export interface ITerritoriesManagement {
  viewMode?: TViewMode;
  client: IClient;
  onChange: (
    value: IClient["activate_territory"] | undefined,
    name: string
  ) => void;
  onSelectChange: (value: string, name: string) => void;
}
const TerritoriesManagement = ({
  viewMode,
  client,
  onChange,
  onSelectChange,
}: ITerritoriesManagement) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;
  const activateTerritoryValue = client.activate_territory;
  const territoryScope = client.scope_of_territory;
  return (
    <>
      <FormFieldBackground viewMode={viewMode ?? "EDIT"}>
        <InputBoolean
          defaultValue={activateTerritoryValue}
          onChange={onChange}
          viewMode={viewMode}
          lang={label.inputTerritoriesBoolean}
          name="activate_territory"
          dataTestId="activate_territory"
        />
        {activateTerritoryValue && (
          <>
            <InputBoolean
              defaultValue={client.enable_gfw}
              lang={{
                ...label.inputDeforestation,
                title: `\u00A0\u00A0${label.inputDeforestation.title}`,
              }}
              name="enable_gfw"
              onChange={onChange}
              viewMode={viewMode}
              dataTestId="enable_deforestation"
            />
            <InputSelect
              viewMode={viewMode}
              name="scope_of_territory"
              title={`\u00A0\u00A0${label.inputTerritoriesScope.title}`}
              onChange={onSelectChange}
              value={territoryScope}
              options={getOptionsInputSelect({
                listKeys: Object.keys(TERRITORY_SCOPE),
                lang: label.inputTerritoriesScope.options,
              })}
            />
          </>
        )}
      </FormFieldBackground>
    </>
  );
};

export default TerritoriesManagement;
