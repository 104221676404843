import { IClient } from "fieldpro-tools";

import { FormFieldBackground } from "components/Forms/Form";
import InputBoolean from "components/Input/InputBoolean";
import { useTranslations } from "hooks";
import { TViewMode } from "model/application/modal/CreateEditModal";

export interface IVisitsManagement {
  viewMode?: TViewMode;
  client: IClient;
  onChange: (
    value: IClient["enable_visit_planning"] | undefined,
    name: string
  ) => void;
}
function VisitsManagement({
  client,
  onChange,
  viewMode,
}: Readonly<IVisitsManagement>) {
  const enableVisitPlanningDefaultValue = client.enable_visit_planning;
  const lang = useTranslations();
  return (
    <FormFieldBackground viewMode={viewMode ?? "EDIT"}>
      <InputBoolean
        defaultValue={enableVisitPlanningDefaultValue}
        onChange={onChange}
        viewMode={viewMode}
        lang={
          lang.containers.clients.subCategories.clients.createEditModal
            .inputClientManagementEnablePlanning
        }
        name="enable_visit_planning"
      />
    </FormFieldBackground>
  );
}

export default VisitsManagement;
