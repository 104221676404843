import React from "react";

import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { Textfit } from "react-textfit";

import { Black, White } from "assets/colors";
import { getThousandSeparator } from "containers/authentication/redux/selector";

import { ChartDataUtils } from "../Chart/ChartDataUtils";
import styles from "./styles";
import { formatVariationAbsolute, formatVariationPercent } from "./utils";

const useStyles = makeStyles(styles as any);

export interface ICustomScorecardProps {
  unit?: string;
  style: any;
  title: string;
  description?: string;
  value: any;
  display_variation?: boolean;
  variationValue?: any;
}

export const CustomScorecard: React.FunctionComponent<
  ICustomScorecardProps
> = ({
  value,
  unit = "",
  style,
  title,
  description,
  display_variation,
  variationValue,
}) => {
  const classes = useStyles();

  const thousandSeparator = useSelector(getThousandSeparator);
  const formattedValue = ChartDataUtils.formatValue(value, thousandSeparator);
  return (
    <Paper
      className={[classes.root, classes.rounded, classes.scorecard].join(" ")}
      style={style}
      elevation={0}
    >
      <div className={classes.chartCard}>
        <div className={classes.chartCardLeft}>
          <span className={classes.scorecardTitle}>{title}</span>
          <Textfit
            mode="multi"
            style={{ color: style.color === White ? White : Black }}
            className={classes.scorecardScore}
          >
            {formattedValue}
          </Textfit>
          <p className={classes.scorecardUnit}>{unit}</p>
          {display_variation && (
            <div className={classes.chartCardLeftBottom}>
              {value > variationValue ? (
                <div className={classes.variationUpText}>
                  <span className={classes.spanText}>
                    <CallMadeIcon
                      fontSize="small"
                      className={classes.variationUp}
                    />
                    {formatVariationPercent(
                      value,
                      variationValue,
                      false,
                      thousandSeparator
                    )}
                  </span>

                  <span className={classes.spanText}>
                    {formatVariationAbsolute(
                      value,
                      variationValue,
                      false,
                      thousandSeparator
                    )}
                  </span>
                </div>
              ) : (
                <div className={classes.variationDownText}>
                  <span className={classes.spanText}>
                    <CallReceivedIcon
                      fontSize="small"
                      className={classes.variationDown}
                    />
                    {formatVariationPercent(
                      value,
                      variationValue,
                      false,
                      thousandSeparator
                    )}
                  </span>
                  <span className={classes.spanText}>
                    {formatVariationAbsolute(
                      value,
                      variationValue,
                      false,
                      thousandSeparator
                    )}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={classes.chartCardRight}>
          <Tooltip title={description || "."} sx={{ marginTop: "-5px" }}>
            <IconButton>
              <InfoOutlinedIcon
                className={
                  style.backgroundColor !== White
                    ? classes.whiteIcon
                    : undefined
                }
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Paper>
  );
};

export default CustomScorecard;
