import React from "react";

import { Box } from "@material-ui/core";

const TitledSection: React.FC<{ title: string }> = ({ children, title }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gridRowGap={"20px"}>
      <Box fontWeight={700} fontSize={"16px"} color={"#082127"}>
        {title}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default TitledSection;
