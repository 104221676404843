import {
  META_EXPRESSION_SCOPE,
  META_EXPRESSION_TARGET_TYPE,
  parseRawMetaExpression,
} from "fieldpro-tools";
import _ from "lodash";

import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import { isAlphanumericalStartingWithLetter } from "utils/utils";

import {
  CALLED_FROM,
  IMetaExpressionWizardAdditionnalProps,
  IMetaExpressionWizardAttributes,
} from "../../components/Input/InputMetaExpression/MetaExpressionWizard";

export const getLinearProgress = (
  currentStep: number,
  scope?: META_EXPRESSION_SCOPE,
  target_type?: META_EXPRESSION_TARGET_TYPE
) => {
  if (
    target_type &&
    [
      META_EXPRESSION_TARGET_TYPE.SQL,
      META_EXPRESSION_TARGET_TYPE.TEXT,
    ].includes(target_type)
  )
    return 100;
  else if (scope && scope === META_EXPRESSION_SCOPE.WORKFLOW_REPORT) {
    const maxStepNb = 4;
    return 100 * (currentStep / maxStepNb);
  } else {
    // if not in workflow report, we are "skipping" the 2nd step
    const maxStepNb = 3;
    return (
      100 * ((currentStep > 2 ? currentStep - 1 : currentStep) / maxStepNb)
    );
  }
};

export const getErrorMessagesForMetaExpression: TValidateFunction<
  IMetaExpressionWizardAttributes,
  IMetaExpressionWizardAdditionnalProps
> = ({ attributes, lang }) => {
  const {
    title = "",
    scope,
    field,
    custom_field1,
    step,
    target_type,
    target_content,
  } = attributes;
  //const { activities, lists } = additionnalProps;
  const result: any = {};
  const isTextLikeTarget =
    target_type &&
    [
      META_EXPRESSION_TARGET_TYPE.SQL,
      META_EXPRESSION_TARGET_TYPE.TEXT,
    ].includes(target_type);
  if (_.isEmpty(title)) {
    result["title"] = lang.components.inputErrors.empty;
  }

  if (_.isEmpty(scope) && !isTextLikeTarget) {
    result["scope"] = lang.components.inputErrors.empty;
  }

  if (scope && !(scope in META_EXPRESSION_SCOPE) && !isTextLikeTarget) {
    result["scope"] = lang.components.inputErrors.empty;
  }

  if (
    _.isEmpty(target_content) &&
    target_type &&
    [
      META_EXPRESSION_TARGET_TYPE.SQL,
      META_EXPRESSION_TARGET_TYPE.TEXT,
    ].includes(target_type)
  ) {
    result["target_content"] = lang.components.inputErrors.empty;
  }

  if (_.isEmpty(field) && _.isEmpty(custom_field1) && !isTextLikeTarget) {
    result["field"] = lang.components.inputErrors.empty;
    result["custom_field1"] = lang.components.inputErrors.empty;
  }

  if (!isAlphanumericalStartingWithLetter(title)) {
    if (title.length === 0) result["title"] = lang.components.inputErrors.empty;
    else
      result["title"] = lang.components.inputErrors.alphanumericMetaExpression;
  }

  if ((!field || field.length === 0) && !custom_field1 && !isTextLikeTarget) {
    result["field"] = lang.components.inputErrors.empty;
  }

  if (scope === META_EXPRESSION_SCOPE.WORKFLOW_REPORT && !step) {
    result["step"] = lang.components.inputErrors.empty;
  }
  return result;
};

const DEFAULT_REPORT_FIELDS_BY_STEP = {
  1: ["title", "scope"],
  2: ["field", "custom_field1", "option_tag", "custom_field2"],
};

const WORKFLOW_REPORT_FIELDS_BY_STEP = {
  1: ["title", "scope"],
  2: ["step"],
  3: ["field", "custom_field1", "option_tag", "custom_field2"],
};

interface IStepHasErrors {
  scope?: META_EXPRESSION_SCOPE;
  currentStep: number;
  errors: {
    [key: string]: string;
  };
}

export const stepHasErrors = ({
  scope,
  currentStep,
  errors,
}: IStepHasErrors) => {
  const FIELDS_BY_STEP =
    scope && scope === META_EXPRESSION_SCOPE.WORKFLOW_REPORT
      ? WORKFLOW_REPORT_FIELDS_BY_STEP
      : DEFAULT_REPORT_FIELDS_BY_STEP;

  const stepKeys = FIELDS_BY_STEP[currentStep];
  const stepErrors = _.pick(errors, stepKeys);
  return !_.isEmpty(stepErrors);
};

export const getAllowedMetaExpressionScopes = (
  calledFrom?: CALLED_FROM,
  includeCustomerScope?: boolean,
  includeIntegRecordScope?: boolean,
  inAMatrixCell?: boolean
) => {
  switch (calledFrom) {
    case CALLED_FROM.ACTIVITY: {
      return [
        META_EXPRESSION_SCOPE.ACTIVITY_REPORT,
        META_EXPRESSION_SCOPE.USER,
        META_EXPRESSION_SCOPE.CLIENT,
        ...(inAMatrixCell
          ? [META_EXPRESSION_SCOPE.MATRIX_CELL, META_EXPRESSION_SCOPE.ITEM_CELL]
          : []),
        ...(includeCustomerScope ? [META_EXPRESSION_SCOPE.CUSTOMER] : []),
      ];
    }
    case CALLED_FROM.ACTIVITY_OPERATION: {
      return [META_EXPRESSION_SCOPE.ACTIVITY_REPORT];
    }
    case CALLED_FROM.LIST: {
      return [
        META_EXPRESSION_SCOPE.ITEM,
        META_EXPRESSION_SCOPE.USER,
        META_EXPRESSION_SCOPE.CLIENT,
      ];
    }
    case CALLED_FROM.LIST_ITEM_NAME_TEMPLATE: {
      return [META_EXPRESSION_SCOPE.ITEM];
    }
    case CALLED_FROM.WORKFLOW_SETTINGS:
    case CALLED_FROM.WORKFLOW: {
      return [
        META_EXPRESSION_SCOPE.WORKFLOW_REPORT,
        // META_EXPRESSION_SCOPE.JOB_REPORT, // TODO: allow this too ? covered by workflow_report > step_id ?
        META_EXPRESSION_SCOPE.USER,
        META_EXPRESSION_SCOPE.CLIENT,

        // Example, see https://optimetriks.atlassian.net/browse/FP-4920
        ...(calledFrom === CALLED_FROM.WORKFLOW_SETTINGS || includeCustomerScope
          ? [META_EXPRESSION_SCOPE.CUSTOMER]
          : []),

        ...(includeIntegRecordScope
          ? [META_EXPRESSION_SCOPE.INTEG_RECORD]
          : []),
      ];
    }
    case CALLED_FROM.CUSTOMER_TO_USE: {
      return [META_EXPRESSION_SCOPE.WORKFLOW_REPORT];
    }
    case CALLED_FROM.MATRIX_CELL: {
      return [
        META_EXPRESSION_SCOPE.USER,
        META_EXPRESSION_SCOPE.CLIENT,
        META_EXPRESSION_SCOPE.ITEM_CELL,
        META_EXPRESSION_SCOPE.MATRIX_CELL,
        META_EXPRESSION_SCOPE.ACTIVITY_REPORT,
        ...(includeCustomerScope ? [META_EXPRESSION_SCOPE.CUSTOMER] : []),
      ];
    }
    case CALLED_FROM.JOB_NOTIFICATION: {
      return [META_EXPRESSION_SCOPE.CLIENT, META_EXPRESSION_SCOPE.USER];
    }
    case CALLED_FROM.JOB_TRANSFORMATION: {
      return [
        META_EXPRESSION_SCOPE.CLIENT,
        META_EXPRESSION_SCOPE.WORKFLOW_REPORT,
        // META_EXPRESSION_SCOPE.JOB_REPORT,
        // META_EXPRESSION_SCOPE.TRANSFORMATION,
      ];
    }
    case CALLED_FROM.TRIGGER: {
      return [
        META_EXPRESSION_SCOPE.CLIENT,
        META_EXPRESSION_SCOPE.USER,
        META_EXPRESSION_SCOPE.TRIGGER_EVENT,
      ];
    }
    default: {
      return [];
    }
  }
};

export const hasMetaExpressionString = (str?: string) => {
  if (!str) {
    return false;
  }
  str = str.trim();
  return (
    (str.startsWith("${") || str.startsWith("$^")) &&
    (str.endsWith("}") || str.endsWith("^"))
  );
};

export const getMetaExpressionString = (str?: string) => {
  if (!str || !hasMetaExpressionString(str)) {
    return "";
  }
  str = str.trim();

  // Replace "${" at the beginning
  if (str.startsWith("${")) {
    str = str.replace("${", "");
  }

  // Replace "$^" at the beginning
  if (str.startsWith("$^")) {
    str = str.replace("$^", "");
  }

  // Replace "}" at the end
  if (str.endsWith("}")) {
    str = str.slice(0, -1); // Remove the last character
  }

  // Replace "^" at the end
  if (str.endsWith("^")) {
    str = str.slice(0, -1); // Remove the last character
  }

  return str;
};

export const getFormattedExpressionFromString = (str?: string) => {
  const metaExpressionString = getMetaExpressionString(str);
  const formattedExpression = parseRawMetaExpression(metaExpressionString);
  return formattedExpression;
};
