import dateFormat from "dateformat";
import _ from "lodash";

import { CSV_ARRAY_SEPARATOR } from "containers/lists/subcategories/items/parseCsvFunctions";
import { OPTIMETRIKS_ROLES } from "model/application/ActionCode";
import { IOption } from "model/application/components";
import { IClient } from "model/entities/Client";
import {
  HierarchyDependencyColors,
  IHierarchyDependencyWithColor,
} from "model/entities/HierarchyDependency";
import { ITeamSelector } from "model/entities/Team";
import { IMobileUser, IWebUser } from "model/entities/User";

const getRowsAndCellsFromMobileUsers = (
  mobUsers: IMobileUser[],
  teams: ITeamSelector[]
) => {
  const result = [];
  const rowHeader = [
    "ID",
    "First Name",
    "Middle Name",
    "Last Name",
    "Email",
    "Phone",
    "Picture",
    "Date of birth",
    "Start date",
    "Internal ID",
    "Official ID",
    "Picture ID",
    "Gender",
    "Role",
    "Licensed",
    "Team Ids",
    "Team Names",
    "App Version",
    "Last Pull Sync",
    "Last Push Sync",
  ];
  result.push(rowHeader);
  _.each(mobUsers, (appUser) => {
    const teamsLinkedToTheUser = teams.filter((t) =>
      t.mobile_users.map((u) => u.key).includes(appUser.id)
    );
    const teamIds = `${teamsLinkedToTheUser
      .map((t) => t.id)
      .join(CSV_ARRAY_SEPARATOR)}`;

    const teamNames = `${teamsLinkedToTheUser
      .map((t) => t.name)
      .join(CSV_ARRAY_SEPARATOR)}`;
    const row = [
      appUser.id,
      appUser.first_name,
      appUser.middle_name ? appUser.middle_name : "",
      appUser.last_name,
      appUser.email,
      appUser.phone,
      appUser.picture,
      appUser.birthday,
      appUser.start_date,
      appUser.internal_id,
      appUser.official_id,
      appUser.id_picture,
      appUser.gender,
      appUser.role,
      appUser.licensed,
      teamIds,
      teamNames,
      appUser.app_version ? appUser.app_version : "",
      appUser.last_pull_sync ? appUser.last_pull_sync : "",
      appUser.last_push_sync ? appUser.last_push_sync : "",
    ];
    result.push(row);
  });
  return result;
};

/**
 * Build the list of options according to the list of privileges
 * @param {Array} privileges An array of privileges
 */
const buildOptions = (privileges: string[]): IOption[] => {
  const result: IOption[] = [];
  if (privileges && privileges.length > 0) {
    privileges.forEach((privilege) => {
      switch (privilege) {
        case OPTIMETRIKS_ROLES.SUPERADMIN: {
          result.push({ key: privilege, label: "Optimetriks Superadmin" });
          break;
        }
        case OPTIMETRIKS_ROLES.ADMIN: {
          result.push({ key: privilege, label: "Optimetriks Admin" });
          break;
        }
        case OPTIMETRIKS_ROLES.OPERATION: {
          result.push({ key: privilege, label: "Optimetriks Operations" });
          break;
        }
        default: {
          result.push({ key: privilege, label: privilege.toUpperCase() });
        }
      }
    });
  }
  return result;
};

const getRemainingLicences = (
  selectedClient: IClient,
  mobUsers: IMobileUser[]
) => {
  return (
    selectedClient.app_user_licenses - mobUsers.filter((u) => u.licensed).length
  );
};

const isColor = (hierarchyDependencies: IHierarchyDependencyWithColor[]) => {
  return hierarchyDependencies.some(
    (h) =>
      h.color === HierarchyDependencyColors.Green ||
      h.color === HierarchyDependencyColors.Yellow
  );
};

const getRowsAndCellsFromWebUsers = (
  webUsers: IWebUser[],
  teams: ITeamSelector[]
) => {
  const result = [];
  const rowHeader = [
    "ID",
    "First Name",
    "Middle Name",
    "Last Name",
    "Email",
    "Role",
    "Team Ids",
    "Team Names",
    "Last login",
  ];
  result.push(rowHeader);
  webUsers = webUsers.filter(
    (user) =>
      !["optimetriks_admin", "optimetriks_operations"].includes(user.role)
  );
  webUsers.forEach((appUser) => {
    const teamsLinkedToTheUser = teams.filter((t) =>
      t.web_users.map((u) => u.key).includes(appUser.id)
    );
    const teamIds = `${teamsLinkedToTheUser
      .map((t) => t.id)
      .join(CSV_ARRAY_SEPARATOR)}`;
    const teamNames = `${teamsLinkedToTheUser
      .map((t) => t.name)
      .join(CSV_ARRAY_SEPARATOR)}`;
    const row = [
      appUser.id,
      appUser.first_name,
      appUser.middle_name ? appUser.middle_name : "",
      appUser.last_name,
      appUser.email,
      appUser.role,
      teamIds,
      teamNames,
      dateFormat(appUser.last_login, "dd/mm/yyyy HH:MM"),
    ];
    result.push(row);
  });
  return result;
};

export {
  buildOptions,
  getRemainingLicences,
  getRowsAndCellsFromMobileUsers,
  getRowsAndCellsFromWebUsers,
  isColor,
};
