import React from "react";

import { Box, makeStyles } from "@material-ui/core";
import {
  CLIENT_CATEGORY,
  CLIENT_STATUS,
  isOptimetriksRole,
} from "fieldpro-tools";
import _ from "lodash";

import InputBoolean from "components/Input/InputBoolean";
import InputSelect from "components/Input/InputSelect";
import { isClientStatus } from "containers/authentication/utils/clientStatus";
import { useTranslations } from "hooks";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";
import { getOptionsInputSelect } from "utils/getOptionsInputSelect";

import styles from "./styles";
import ClientContact from "./Tabs/ClientContact";
import ClientLocation from "./Tabs/ClientLocation";

const useStyles = makeStyles(styles);

const ClientAndBusinessDetails: React.FC<{
  client: Partial<IClient>;
  viewMode?: TViewMode;
  user_role: string;
  handleInputChange: any;
  handleSelectChange: any;
  handleMultipleSelectChange: any;
  shouldMarkError: (key: string) => string | undefined;
}> = ({
  viewMode,
  client,
  user_role,
  handleInputChange,
  handleSelectChange,
  handleMultipleSelectChange,
  shouldMarkError,
}) => {
  const classes = useStyles();

  const {
    main_contact,
    status,
    region,
    category,
    country,
    is_enterprise,
    sector,
  } = client;

  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gridRowGap={"20px"}
      marginBottom={"20px"}
      className={classes.container}
    >
      <ClientContact
        main_contact={main_contact}
        viewMode={viewMode}
        onSelectChange={handleMultipleSelectChange}
      />

      {viewMode !== "CREATE" && (
        <InputSelect
          viewMode={viewMode}
          lang={
            lang.containers.clients.subCategories.clients.createEditModal
              .inputClientStatus
          }
          name={"status"}
          value={status}
          options={_.map(_.entries(CLIENT_STATUS), ([STATUS, status]) => ({
            key: status,
            label:
              lang.containers.clients.subCategories.clients.createEditModal
                .inputClientStatus.options[STATUS],
          }))}
          onChange={handleInputChange}
          // IMPORTANT: value is read-only, and is discarded by prepareClientForBackend anyway
          disabled
        />
      )}

      <InputSelect
        viewMode={viewMode}
        lang={label.inputClientManagementCategory}
        name={"category"}
        error={shouldMarkError("category")}
        value={category}
        options={getOptionsInputSelect({
          listKeys: Object.keys(CLIENT_CATEGORY),
          lang: label.inputClientManagementClientCategory.options,
        })}
        onChange={handleInputChange}
        required={true}
      />

      {isOptimetriksRole(user_role) && (
        <InputBoolean
          viewMode={viewMode}
          name="is_enterprise"
          onChange={handleInputChange}
          defaultValue={is_enterprise}
          lang={{
            title: label.clientManagement.options.isEntreprise,
            ...(!isClientStatus(client as IClient, CLIENT_STATUS.LIVE) && {
              tooltip: label.inputClientIsEnterprise.tooltip,
            }),
          }}
          error={shouldMarkError("is_enterprise")}
          disabled={
            is_enterprise &&
            !isClientStatus(client as IClient, CLIENT_STATUS.LIVE)
          }
        />
      )}

      <ClientLocation
        viewMode={viewMode}
        getFieldError={shouldMarkError}
        region={region}
        category={category}
        country={country}
        sector={sector}
        onSelectChange={handleSelectChange}
        onMultipleSelectChange={handleMultipleSelectChange}
      />
    </Box>
  );
};

export default ClientAndBusinessDetails;
