import React, {
  ComponentProps,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";

import { Badge, Box, IconButton, makeStyles, Menu } from "@material-ui/core";
import _ from "lodash";

import { OffWhite } from "assets/colors";

const useStyles = makeStyles({
  container: {
    transition: "background-color 0.1s",
    "&:hover": {
      backgroundColor: OffWhite + " !important",
    },
  },
  badge: {
    fontSize: "12px",
  },
});
export interface ICustomControlButtonProps {
  icon: React.ReactNode;
  children: React.ReactNode;
  menuProps?: Partial<ComponentProps<typeof Menu>>;
  buttonProps?: Partial<ComponentProps<typeof IconButton>>;
  badgeContent?: ComponentProps<typeof Badge>["badgeContent"];
}

const ControlMenuButton = forwardRef(
  (
    {
      icon,
      children,
      menuProps,
      buttonProps,
      badgeContent,
    }: ICustomControlButtonProps,
    ref
  ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({
      closeMenu: handleClose,
    }));

    return (
      <Box>
        <Badge
          badgeContent={badgeContent}
          color="primary"
          classes={{
            badge: classes.badge,
          }}
          overlap="rectangular"
        >
          <IconButton
            className={classes.container}
            style={{
              backgroundColor: "white",
              borderRadius: "15%",
              height: "29px",
              width: "29px",
              boxShadow: `rgb(108, 108, 108,0.2) 0px 0px 0px 2px`,
              ...(buttonProps?.style || {}),
            }}
            onClick={handleClick}
            {..._.omit(buttonProps || {}, "style")}
          >
            {icon}
          </IconButton>
        </Badge>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableScrollLock
          elevation={0}
          {...menuProps}
        >
          {children}
        </Menu>
      </Box>
    );
  }
);

export default ControlMenuButton;
