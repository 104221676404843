import { STEP_TYPE } from "fieldpro-tools";

import { IWorkflow } from "model/entities/Workflow";

export const getErrorMessagesForDeliveryWorkflows = ({
  attributes,
  lang: lang,
}: any) => {
  type TDeliveryWorkflowError = {
    workflow_type?: string;
    workflow_id?: string;
  };
  const result: TDeliveryWorkflowError = {};
  const { workflow_id, workflow_type } = attributes;
  if (!workflow_id || workflow_id.length === 0)
    result["workflow_id"] = lang.components.inputErrors.empty;

  if (!workflow_type || workflow_type.length === 0)
    result["workflow_type"] = lang.components.inputErrors.empty;
  if (Object.keys(result).length === 0) return {};
  return result;
};

export const getWorkflowsStartingWithActivityStep = (
  workflows: IWorkflow[]
) => {
  return workflows.filter((w) => {
    const step = w.steps.find((s) => s.id === w.starting_step_id);
    if (step) {
      if (step.type === STEP_TYPE.ACTIVITY) {
        return true;
      }
    }
  });
};
