import { IMetaExpressionOptions } from "fieldpro-tools";
import _ from "lodash";

import { getTagKey } from "components/Input/InputMatrix/utils/getQuestionColumns";
import { resolveMetaExpressionInString } from "containers/workflows/subcategories/workflows/utils/metaResolver";
import { IListSchema } from "model/entities/List";
import { IQuestion } from "model/entities/Workflow";

import { IMatrixAnswerForm } from "../MatrixEditorDrawer";

interface IResolveMatrixDefaultValues {
  schema: IQuestion[] | IListSchema[];
  matrixAnswers: IMatrixAnswerForm;
  metaExpressionOptions: IMetaExpressionOptions;
}

/**
 * Resolve any default fields containing Meta Expressions
 */
export const resolveMatrixDefaultValues = ({
  schema,
  matrixAnswers,
  metaExpressionOptions,
}: IResolveMatrixDefaultValues) => {
  const resolvedAnswers: IMatrixAnswerForm = { ...matrixAnswers };

  _.each<IQuestion | IListSchema>(schema, (customField) => {
    const tagKey = getTagKey(customField);
    const fieldTag = customField[tagKey];

    if (!resolvedAnswers[fieldTag] && customField.default_value) {
      resolvedAnswers[fieldTag] = resolveMetaExpressionInString(
        // Right now, only activity report fields have ME allowed in default values and need to resolve
        // but list do not have ME in their default values
        customField.default_value,
        {
          ...metaExpressionOptions,
          matrix_answers: _.clone(resolvedAnswers),
        }
      );
    }
  });

  return resolvedAnswers;
};
