/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import { INotification as IBENotification } from "fieldpro-tools";

import apiService from "api/apiService";
import { formatObjectToKeyValuePairs } from "containers/workflows/utils/";
import { INotification, STEP_TYPE } from "model/entities/Job";
import { IServerResponse } from "redux/actions/appActions";

import { prepareNotificationForBackend } from "./utils";

export const NOTIFICATIONS_ENDPOINT = `notifications`;
export const NOTIFICATION_ENDPOINT = "notification";

export type TCreateNotificationFunc = (
  actionName: string,
  notification: INotification
) => Promise<IServerResponse<ICreateNotificationResponse>>;
export interface ICreateNotificationResponse {
  id: string;
  notification: INotification;
}

export type TUpdateNotificationFunc = (
  actionName: string,
  notification: INotification
) => Promise<IServerResponse<IUpdateNotificationResponse>>;
export interface IUpdateNotificationResponse {
  notification: INotification;
}

export type TDeleteNotificationFunc = (
  actionName: string,
  notificationId: string
) => Promise<IServerResponse<IDeleteNotificationResponse>>;
export interface IDeleteNotificationResponse {}

export type TFetchNotificationsFunc = () => Promise<
  IServerResponse<IFetchNotificationsResponse>
>;
export interface IFetchNotificationsResponse {
  notifications: IBENotification[];
}
export type TRunNotificationFunc = (
  actionName: string,
  notification: INotification
) => Promise<IServerResponse<IRunNotificationResponse>>;

export interface ITestNotificationPayload {
  test_recipient: string;
  notification_object: INotification;
}
export type TReceiveTestEmailFunc = (
  actionName: string,
  payload: ITestNotificationPayload
) => Promise<
  IServerResponse<{
    success: boolean;
  }>
>;

export interface IRunNotificationResponse {
  success: boolean;
}
//api calls for notifications
/**
 * Creates a new notification
 * @param {Object} notification Notification details
 */
export const createNotificationApiCall: TCreateNotificationFunc = (
  actionName,
  notification
) => {
  const payload = prepareNotificationForBackend(notification);
  return apiService.post(`/${NOTIFICATIONS_ENDPOINT}`, payload, actionName);
};

/**
 * Update a targetted notification
 * @param {Object} notification Detail of the new notification
 */
export const updateNotificationApiCall: TUpdateNotificationFunc = (
  actionName,
  notification
) => {
  const payload = prepareNotificationForBackend(notification);
  return apiService.patch(
    `/${NOTIFICATIONS_ENDPOINT}/${notification.id}`,
    payload,
    actionName
  );
};

/**
 * Delete notification API call makes a POST request to delete a single notification
 * @param {String} notification Id of the notification to delete
 * @return {Promise}
 * */
export const deleteNotificationApiCall: TDeleteNotificationFunc = (
  actionName,
  notificationId
) => {
  return apiService.delete(
    `/${NOTIFICATIONS_ENDPOINT}/${notificationId}`,
    {},
    actionName
  );
};

/**
 * Api call to fetch all
 */
export const fetchNotificationsApiCall: TFetchNotificationsFunc = () => {
  return apiService.get(`/${NOTIFICATIONS_ENDPOINT}`);
};
/**
 * Creates a new notification
 * @param {Object} notification Notification details
 */
export const runNotificationJobApiCall: TRunNotificationFunc = (
  actionName,
  notification
) => {
  const notifId = notification.id;
  const payload = prepareNotificationForBackend(notification);
  return apiService.post(
    `/step`,
    {
      job_type: STEP_TYPE.NOTIFICATION,
      schema_id: notifId,
      parameters: formatObjectToKeyValuePairs(payload),
    },
    actionName
  );
};

/* -------------------------------------------------------------------------- */
/*                        receives a test notification                        */
/* -------------------------------------------------------------------------- */
export const receiveTestNotificationApiCall: TReceiveTestEmailFunc = (
  actionName,
  payload
) => {
  const notification = prepareNotificationForBackend(
    payload.notification_object
  );
  return apiService.post(
    `${NOTIFICATION_ENDPOINT}/test`,
    {
      notification_object: notification,
      test_recipient: payload.test_recipient,
    },
    actionName
  );
};
