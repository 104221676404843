import { IWorkflow, STEP_TYPE } from "fieldpro-tools";
import _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";

import { allDashboardsSelector } from "containers/dashboards/redux/selectors";
import { getLinkedListIds } from "containers/workflows/utils/";
import { IClient } from "model/entities/Client";
import ICustomer from "model/entities/Customer";
import { IDashboard } from "model/entities/Dashboard";
import { IList } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import { IActivity } from "model/entities/Workflow";
import IRootState from "redux/store/model";

import { customSelectorCreator } from "../../../redux/selectors/appSelector";
import { DATE_FORMAT } from "../../../utils/constants";
import { getSelectedClient } from "../../clients/redux/selectors";
import {
  getAllActivities,
  getAllWorkflows,
} from "../../workflows/redux/selectors";

/**
 * Get all lists from redux store
 * @param {Object} state
 * @returns {Array} Array of lists objects
 */
const getAllLists = (state: IRootState) => state.lists.allLists;
export const getSelectedList = (state: IRootState) => state.lists.selectedList;

interface IListsById {
  [id: string]: IList<IListItem>;
}

/**
 * Get lists by id
 * @param {Object} state Redux store state
 * @returns {Object}
 */
export const getListById = (state: IRootState): IListsById => state.lists.byId;

/**
 * Gets the progress state of fetching lists for a given client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingListsForClient = (state: IRootState) =>
  state.lists.fetchingListsForClient;

/**
 * Gets the progress state of fetching items for a given list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingItemsForList = (state: IRootState) =>
  state.lists.isFetchingItems;

/**
 * Gets the progress state of fetching single item
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingOneItem = (state: IRootState) =>
  state.lists.isFetchingOneItem;

/** Lists selector. Selects the lists from store*/
export const listsSelector = createSelector(getAllLists, (lists) => lists);

/**
 * Selector to get all lists
 * @param {Object} state Redux store state
 * @returns {Array} array of lists
 */
export const allListsSelector = customSelectorCreator(
  getAllLists,
  (allLists: IList[]) => allLists
);

/**
 * Composed Selector to get dashboards linked to a customer list
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const customerLinkedDashboardsSelector = customSelectorCreator(
  [allDashboardsSelector],
  (dashboards: IDashboard[]) => {
    return dashboards.filter((d) => d.list_id === "customer");
  }
);

/**
 * Composed Selector to get lists by the given client account
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const listsComposedSelector = customSelectorCreator(
  [getAllLists, getSelectedClient, getAllActivities],
  (lists: IList[], client: IClient, activities: IActivity[]) => {
    if (client) {
      const mapListIdActivities: any = {};
      activities.forEach((activity) => {
        getLinkedListIds(activity).forEach((listId) => {
          mapListIdActivities[listId] = mapListIdActivities[listId] || [];
          mapListIdActivities[listId].push({
            id: activity.id,
            name: activity.name,
          });
        });
      });
      // remove the "nplet" lists
      lists = lists.filter((l) => {
        return _.filter(l.schema, { column_tag: "_nplet_value" }).length === 0;
      });
      if (!lists) {
        return [];
      }
      return lists.map((l) => {
        const created_on = moment(l.created_at).format(DATE_FORMAT);
        const schema = l.schema.filter((a) => !a.deprecated);
        schema.sort((a, b) => a.index - b.index);

        return {
          ...l,
          schema,
          created_on,
          activities: mapListIdActivities[l.id] || [],
        };
      });
    } else {
      return [];
    }
  }
);

/**
 * Composed Selector to get lists by the given client account
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const customerComposedSelector = customSelectorCreator(
  [getAllLists, getSelectedClient],
  (lists: IList[], client: IClient): IList<ICustomer> | undefined => {
    if (client && lists) {
      // get only the "customer" lists
      const customer = lists.find((l) => l.list_type === "CUSTOMER");
      if (customer) {
        // additionnal cleaning
        //let created_on = moment(customer.created_at).format(DATE_FORMAT);
        customer.schema = customer.schema.filter((a) => !a.deprecated);
        //customer.created_on = created_on;
        return customer as IList<ICustomer>;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
);

/**
 * Get the list of Workflows that are linked to a list, either with workflow.attached_lists
 * or either via the activities (question(s) "on list" included in the questionnaire).
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const getWorkflowsLinkedToList = customSelectorCreator(
  [(state: any, listId: string) => listId, getAllWorkflows, getAllActivities],
  (listId: string, workflows: IWorkflow[], activities: IActivity[]) => {
    if (!listId) {
      return;
    }
    let linkedWorkflowIds: string[] = [];
    _.each(workflows, (workflow) => {
      if (isWorkflowLinkedtoList(listId, workflow, activities)) {
        linkedWorkflowIds = [...linkedWorkflowIds, workflow.id];
      }
    });
    return _.filter(workflows, ({ id }) => _.includes(linkedWorkflowIds, id));
  }
);

const isWorkflowLinkedtoList = (
  listId: string,
  workflow: IWorkflow,
  activities: IActivity[]
) => {
  if (_.includes(workflow.attached_lists, listId)) {
    return true;
  }

  const activitySteps = _.filter(
    workflow.steps,
    (step: any) => step.type === STEP_TYPE.ACTIVITY
  );
  const activityIds = _.map(activitySteps, "schema_id");

  for (const activityId of activityIds) {
    const activity = _.find(activities, { id: activityId });
    if (isActivityLinkedToList(listId, activity)) {
      return true;
    }
  }

  return false;
};

const isActivityLinkedToList = (listId: string, activity?: IActivity) => {
  if (!activity) {
    return false;
  }
  for (const question of activity?.questionnaire?.questions || []) {
    if (question.list_id === listId) {
      return true;
    }
  }
  return false;
};

// Status

/**
 * Gets the state of creating a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingList = (state: IRootState) =>
  state.lists.isCreatingList;

/**
 * Gets the state of assigning a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsAssignItems = (state: IRootState) =>
  state.lists.isAssigningItems;

/**
 * Gets the state of unassigning a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUnassignItems = (state: IRootState) =>
  state.lists.isUnassigningItems;

/**
 * Gets the state of finding the duplicates of a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFindDuplicatesItems = (state: IRootState) =>
  state.lists.isFindDuplicatesItems;

/**
 * Gets the state of deleting a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingList = (state: IRootState) =>
  state.lists.isDeletingList;

/**
 * Gets the state of downloading a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDownloadingList = (state: IRootState) =>
  state.lists.isDownloadingFile;

/**
 * Gets the state of updating a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingList = (state: IRootState) =>
  state.lists.isUpdatingList;

/**
 * Gets the state of archiving a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsArchivingList = (state: IRootState) =>
  state.lists.isArchivingList;

/**
 * Gets the state of restoring a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsRestoringList = (state: IRootState) =>
  state.lists.isRestoringList;

/**
 * Gets the state of creating an item
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingItems = (state: IRootState) =>
  state.lists.isCreatingItems;

/**
 * Gets the state of deleting items
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingItems = (state: IRootState) =>
  state.lists.isDeletingItems;

/**
 * Gets the state of updating an item
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingItems = (state: IRootState) =>
  state.lists.isUpdatingItems;

/**
 * Gets the state of archiving items
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsArchivingItems = (state: IRootState) =>
  state.lists.isArchivingItems;

/**
 * Gets the state of restoring items
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsRestoringItems = (state: IRootState) =>
  state.lists.isRestoringItems;

/**
 * Gets the state of uploading a file
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUploadingFile = (state: IRootState) =>
  state.lists.isUploadingFile;

/**
 * Gets the state of fetching customer's reports pictures
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFetchingCustomerReportsPictures = (state: IRootState) =>
  state.lists.isFetchingCustomerReportsPictures;
/**
 * Gets selected customer's reports pictures
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getSelectedCustomerReportsPictures = (state: IRootState) =>
  state.lists.selectedCustomerPictures;

/**
 * Get all tables from redux store
 * @param {Object} state
 * @returns {Array} Array of group objects
 */
export const getDbSchema = (state: IRootState) =>
  state.environments.dbSchema.allTables;

export const getListErrors = (state: IRootState) => state.lists.errors;
