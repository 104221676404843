import { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { object } from "prop-types";

import Axes from "./Axes";
import Chart from "./Chart";
import { ChartDataUtils } from "./ChartDataUtils";
import SliceTooltip from "./SliceTooltip";
import styles from "./styles";
export class OptimetriksScatterPlotChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      chart: {},
      nivoConfiguration: {},
    };
  }

  render() {
    const { chart, nivoConfiguration } = this.props;
    const yScale = Axes.getScale(
      nivoConfiguration.axeYType,
      nivoConfiguration.minValue,
      nivoConfiguration.maxValue
    );
    const formatNumber = (e) =>
      typeof e === "number"
        ? ChartDataUtils.addThousandSeparator(
            e,
            nivoConfiguration.thousandSeparator
          )
        : e;
    let axisBottom = Chart.defaultAxisBottom(nivoConfiguration.axeXType);
    axisBottom = nivoConfiguration.tickValues
      ? Object.assign(axisBottom, {
          tickValues: nivoConfiguration.tickValues,
        })
      : axisBottom;
    const enableYAxis = nivoConfiguration.enableYAxis;
    return (
      <ResponsiveScatterPlot
        data={chart.data}
        colors={nivoConfiguration.colors}
        xFormat={formatNumber}
        yFormat={formatNumber}
        margin={Chart.defaultMargin}
        yScale={yScale}
        axisLeft={enableYAxis ? Chart.defaultAxisLeft : null}
        pointLabel="y"
        enablePointLabel={!enableYAxis}
        enablePoints={!enableYAxis}
        enableGridX={false}
        enableGridY={false}
        axisBottom={Object.assign(axisBottom, {
          format: (value) => {
            return Axes.formatAxisValue(
              value,
              nivoConfiguration.axeXType,
              nivoConfiguration.thousandSeparator
            );
          },
        })}
        axisTop={null}
        axisRight={null}
        sliceTooltip={({ node }) => {
          const points = node.points.map((point) => {
            return {
              id: point.data.xFormatted,
              indexValue: point.serieId,
              value: point.data.yFormatted,
            };
          });
          return (
            <SliceTooltip
              slice={points}
              separator={nivoConfiguration.thousandSeparator}
            />
          );
        }}
      />
    );
  }
}
OptimetriksScatterPlotChart.propTypes = {
  classes: object,
  chart: object,
  nivoConfiguration: object,
};

export default withStyles(styles)(OptimetriksScatterPlotChart);
