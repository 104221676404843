export const FETCH_ALL_ERP_ORDERS_BEGIN = "FETCH_ALL_ERP_ORDERS_BEGIN";
export const FETCH_ALL_ERP_ORDERS_SUCCESS = "FETCH_ALL_ERP_ORDERS_SUCCESS";
export const FETCH_ALL_ERP_ORDERS_FAILURE = "FETCH_ALL_ERP_ORDERS_FAILURE";

export const UPDATE_ERP_ORDER_STATUS_BEGIN = "UPDATE_ERP_ORDER_STATUS_BEGIN";
export const UPDATE_ERP_ORDER_STATUS_SUCCESS =
  "UPDATE_ERP_ORDER_STATUS_SUCCESS";
export const UPDATE_ERP_ORDER_STATUS_FAILURE =
  "UPDATE_ERP_ORDER_STATUS_FAILURE";

export const FETCH_SFA_DATA_SUCCESS = "FETCH_SFA_DATA_SUCCESS";

export const DELETE_ORDER_LINES_BEGIN = "DELETE_ORDER_LINES_BEGIN";
export const DELETE_ORDER_LINES_SUCCESS = "DELETE_ORDER_LINES_SUCCESS";
export const DELETE_ORDER_LINES_FAILURE = "DELETE_ORDER_LINES_FAILURE";

export const UPDATE_ORDER_LINES_BEGIN = "UPDATE_ORDER_LINES_BEGIN";
export const UPDATE_ORDER_LINES_SUCCESS = "UPDATE_ORDER_LINES_SUCCESS";
export const UPDATE_ORDER_LINES_FAILURE = "UPDATE_ORDER_LINES_FAILURE";
