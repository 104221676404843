import React from "react";

import {
  IAccessRightProfile,
  MOBILE_LOGIN_MODE,
  WEB_LOGIN_MODE,
} from "fieldpro-tools";
import _ from "lodash";

import FormFieldBackground from "components/Forms/Form/FormField/FormFieldBackground";
import InputBoolean from "components/Input/InputBoolean";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";
import { IMobileUser, IWebUser } from "model/entities/User";
import { getOptionsInputSelect } from "utils/getOptionsInputSelect";

import InputAccessRightProfiles from "./InputAccessRightProfiles";

export interface IUsersTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  onSetClientLive: any;
  selectedClientWebUsers: IWebUser[];
  selectedClientMobileUsers: IMobileUser[];
  onInputChange: any;
  onChangeWebProfiles: (profiles: IAccessRightProfile[]) => void;
  onChangeMobileProfiles: (profiles: IAccessRightProfile[]) => void;
  onSelectChange: any;
  shouldMarkError: any;
  handleChangeMobileLoginModes: (value: IOption<MOBILE_LOGIN_MODE>[]) => void;
  handleChangeWebLoginModes: (value: IOption<WEB_LOGIN_MODE>[]) => void;
}

export const UsersTab: React.FunctionComponent<IUsersTabProps> = ({
  viewMode = "CREATE",
  web_access_right_profiles,
  mobile_access_right_profiles,
  unique_log_in,
  phone_regex,
  email_regex,
  web_token_duration,
  daily_status_query,
  magic_number,
  selectedClientWebUsers,
  selectedClientMobileUsers,
  onInputChange,
  onChangeWebProfiles,
  onChangeMobileProfiles,
  onSelectChange,
  shouldMarkError,
  mobile_login_modes,
  web_login_modes,
  handleChangeMobileLoginModes,
  handleChangeWebLoginModes,
}) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  return (
    <>
      <FormFieldBackground viewMode={viewMode}>
        <InputAccessRightProfiles
          isMobile={false}
          viewMode={viewMode}
          profiles={web_access_right_profiles}
          selectedClientUsers={selectedClientWebUsers}
          onChangeProfiles={onChangeWebProfiles}
        />
      </FormFieldBackground>

      <FormFieldBackground viewMode={viewMode}>
        <InputAccessRightProfiles
          isMobile={true}
          viewMode={viewMode}
          profiles={mobile_access_right_profiles}
          selectedClientUsers={selectedClientMobileUsers}
          onChangeProfiles={onChangeMobileProfiles}
        />
      </FormFieldBackground>

      <InputBoolean
        viewMode={viewMode}
        defaultValue={unique_log_in}
        lang={label.inputUniqueLogin}
        name={"unique_log_in"}
        onChange={onInputChange}
      />

      <FormFieldBackground viewMode={viewMode}>
        <InputText
          viewMode={viewMode}
          defaultValue={phone_regex}
          name="phone_regex"
          lang={label.inputPhoneRegex}
          onChange={onInputChange}
          aria-label="PhoneRegexInputText"
          required={false}
        />
      </FormFieldBackground>

      <FormFieldBackground viewMode={viewMode}>
        <InputText
          viewMode={viewMode}
          defaultValue={email_regex}
          name="email_regex"
          lang={label.inputEmailRegex}
          onChange={onInputChange}
          aria-label="EmailRegexInputText"
          required={false}
        />
      </FormFieldBackground>

      <FormFieldBackground viewMode={viewMode}>
        <InputText
          viewMode={viewMode}
          defaultValue={web_token_duration}
          name="web_token_duration"
          lang={label.inputWebTokenDuration}
          onChange={onInputChange}
          error={shouldMarkError("web_token_duration")}
          type={INPUT_TEXT_TYPE.INTEGER}
          aria-label="WebTokenDurationInputText"
          required={false}
        />
      </FormFieldBackground>

      <FormFieldBackground viewMode={viewMode}>
        <InputText
          viewMode={viewMode}
          defaultValue={daily_status_query}
          name="daily_status_query"
          lang={label.inputDailyStatusQuery}
          onChange={onInputChange}
          aria-label="DailyStatusInputText"
          required={false}
        />
      </FormFieldBackground>

      <InputText
        viewMode={viewMode}
        defaultValue={magic_number}
        name="magic_number"
        error={shouldMarkError("magic_number")}
        lang={label.inputMagicNumber}
        onChange={onSelectChange}
        required={false}
      />
      <InputMultipleSelect
        viewMode={viewMode}
        name={"mobile_login_modes"}
        defaultValue={_.map(mobile_login_modes ?? [], (j) => ({
          key: j,
          label: j,
        }))}
        options={getOptionsInputSelect({
          listKeys: Object.keys(MOBILE_LOGIN_MODE),
          lang: label.inputMobileLoginModes.options,
        })}
        onChange={
          handleChangeMobileLoginModes as (
            optSelected: IOption<string>[],
            name: string,
            uncheckOptions?: IOption<string>[],
            undeterminedOptions?: IOption<string>[]
          ) => void
        }
        error={shouldMarkError("mobile_login_modes")}
        multipleSelection={true}
        langlabel={label.inputMobileLoginModes}
        lang={lang}
        required={true}
      />
      <InputMultipleSelect
        viewMode={viewMode}
        name={"web_login_modes"}
        defaultValue={_.map(web_login_modes ?? [], (j) => ({
          key: j,
          label: j,
        }))}
        options={getOptionsInputSelect({
          listKeys: Object.keys(WEB_LOGIN_MODE),
          lang: label.inputWebLoginModes.options,
        })}
        onChange={
          handleChangeWebLoginModes as (
            optSelected: IOption<string>[],
            name: string,
            uncheckOptions?: IOption<string>[],
            undeterminedOptions?: IOption<string>[]
          ) => void
        }
        error={shouldMarkError("web_login_modes")}
        multipleSelection={true}
        langlabel={label.inputWebLoginModes}
        lang={lang}
        required={true}
      />
    </>
  );
};

export default UsersTab;
