import _ from "lodash";
import { createSelector } from "reselect";

import { IClient } from "model/entities/Client";
import {
  IWorkflow,
  IWorkflowReportWithStepOverview,
} from "model/entities/Workflow";
import { customSelectorCreator } from "redux/selectors/appSelector";
import IRootState from "redux/store/model";
import { clone } from "utils/utils";

import {
  getIsFetchingEntities,
  getSelectedClient,
} from "../../clients/redux/selectors";

/**
 * gets state of all workflows
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingAllWorkflows = (state: IRootState) =>
  state.workflows.isFetchingAll;

/**
 * get state of deleting a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsDeletingWorkflow = (state: IRootState) =>
  state.workflows.isDeleting;

/**
 * get state of archiving a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsArchivingWorkflow = (state: IRootState) =>
  state.workflows.isArchiving;

/**
 * get state of restoring a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsRestoringWorkflow = (state: IRootState) =>
  state.workflows.isRestoring;
/**
 * get state of deleting a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsDeletingWorkflowReport = (state: IRootState) =>
  state.workflows.isDeletingWorkflowReport;

/**
 * Gets the last updated value of workflows This should return a Date object or null if it has not been update
 * @param {Object} state
 * @returns {Object}
 */
export const getWorkflowsLastUpdated = (state: IRootState) =>
  state.workflows.lastUpdated;

/**
 * get state of creating a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 *  */
export const getIsCreatingWorkflow = (state: IRootState) =>
  state.workflows.isCreating;

/**
 * gets state of updating a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsUpdatingWorkflow = (state: IRootState) =>
  state.workflows.isUpdating;

export const getIsFetchingAugmentedActivityReports = (state: IRootState) =>
  state.workflows.isFetchingAugmentedActivityReports;

/**
 * Gets all workflows from redux store
 * @param {Object} state
 * @returns {Array}
 */
export const getAllWorkflows = (state: IRootState): IWorkflow[] =>
  state.workflows.allWorkflows;

/**
 * Get workflows by id
 * @param {Object} state Redux store state
 * @returns {Object}
 */
const getWorkflowsById = (state: IRootState) => state.workflows.byId;

/**
 * Selector to get all workflows
 * @param {Object} state Redux store state
 * @returns {Array} array of workflows
 */
export const allWorkflowsSelector = customSelectorCreator(
  getAllWorkflows,
  (allWorkflows: IWorkflow[]) => allWorkflows
);

/**
 * Selector to get all workflows
 * @param {Object} state Redux store state
 * @returns {Array} array of workflows
 */
export const allActiveWorkflowsSelector = customSelectorCreator(
  getAllWorkflows,
  (allWorkflows: IWorkflow[]) => allWorkflows //.filter((w) => w.active)
);

/**
 * Selector to get workflows by id
 * @param {Object} state
 * @returns {Object}
 */
export const workflowsByIdSelector = createSelector(
  getWorkflowsById,
  (workflowsById) => workflowsById
);

/**
 * Select to get the id of all active workflows for a given client
 * @param {Object} state
 * @returns {Array}
 */
export const workflowSimplifiedSelector = customSelectorCreator(
  [getSelectedClient, getAllWorkflows, getIsFetchingEntities],
  (
    client: IClient,
    allWorkflows: IWorkflow[],
    isFetchingAllEntities: boolean
  ): { id: string; name: string }[] => {
    if (isFetchingAllEntities) return [];
    if (client) {
      const workflows: IWorkflow[] = clone(allWorkflows);
      return (
        workflows
          //.filter((w) => w.active)
          .map((w) => ({
            id: w.id,
            name: w.name,
          }))
      );
    } else return [];
  }
);

/**
 * Select to get the workflows for a given client
 * @param {Object} state
 * @returns {Array}
 */
export const workflowsComposedSelector = customSelectorCreator(
  [getSelectedClient, getAllWorkflows, getIsFetchingEntities],
  (
    client: IClient,
    allWorkflows: IWorkflow[],
    isFetchingAllEntities: boolean
  ): IWorkflow[] => {
    if (isFetchingAllEntities) return [];
    if (client) {
      const workflows: IWorkflow[] = clone(allWorkflows);
      if (!workflows) {
        return [];
      }
      const preResult = workflows;
      return preResult as IWorkflow[];
    } else {
      return [];
    }
  }
);

/**
 * get state of creating an activity
 * @param {Object} state Redux store state
 * @returns {Boolean}
 *  */
export const getIsCreatingActivity = (state: IRootState) =>
  state.workflows.isCreatingActivity;

/**
 * gets state of updating an activity
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsUpdatingActivity = (state: IRootState) =>
  state.workflows.isUpdatingActivity;

/**
 * get state of deleting an activity
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsDeletingActivity = (state: IRootState) =>
  state.workflows.isDeletingActivity;

/**
 * gets state of fetching activities
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingActivities = (state: IRootState) =>
  state.workflows.isFetchingActivities;

/**
 * Gets the activities from Redux Store
 * @param {Object} state Redux Store state for Clients
 * @returns {Array} All clients
 */
export const getAllActivities = (state: IRootState) =>
  state.workflows.allActivities;

/**
 * Activities selector
 * @returns {Array}
 */
export const allActivitiesSelector = createSelector(
  getAllActivities,
  (allActivities) => allActivities
);

/**
 * gets state of fetching an activityreport
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsDeletingActivityReport = (state: IRootState) =>
  state.workflows.isDeletingActivityReport;

/**
 * gets state of creating an activity_report
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsCreatingActivityReport = (state: IRootState) =>
  state.workflows.isCreatingActivityReport;
/**
 * gets state of updating an activity_report
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsUpdatingActivityReport = (state: IRootState) =>
  state.workflows.isUpdatingActivityReport;
/**
 * gets state of creating a workflow_report
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsCreatingWorkflowReport = (state: IRootState) =>
  state.workflows.isCreatingWorkflowReport;
/**
 * gets state of updating a workflow_report
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsUpdatingWorkflowReport = (state: IRootState) =>
  state.workflows.isUpdatingWorkflowReport;
/**
 * Gets the activity_reports from Redux Store
 * @param {Object} state Redux Store state for Clients
 * @returns {Array} All clients
 */
const getAllActivityReports = (state: IRootState) =>
  state.workflows.allActivityReports;

const getAllAugmentedActivityReports = (state: IRootState) =>
  state.workflows.allAugmentedActivityReports;

const getActivityReportsCount = (state: IRootState) =>
  state.workflows.totalReportCount;

const getAugmentedActivityReportsCount = (state: IRootState) =>
  state.workflows.totalAugmentedReportCount;

/**
 * gets state of downloading activity_report raw data
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsDownloadingReportRawData = (state: IRootState) =>
  state.workflows.isDownloadingReportRawData;

/**
 * ActivityReports selector
 * @returns {Array}
 */
export const allActivityReportsSelector = createSelector(
  getAllActivityReports,
  (reports) => reports
);

export const totalActivityReportsSelector = createSelector(
  getActivityReportsCount,
  (total) => total
);

/**
 * AugmentedActivityReports
 */

export const augmentedActivityReportsSelector = createSelector(
  getAllAugmentedActivityReports,
  (reports) => reports
);

export const totalAugmentedActivityReportsSelector = createSelector(
  getAugmentedActivityReportsCount,
  (total) => total
);

export const getActivityReportSorting = (state: IRootState) =>
  state.workflows.activityReportSorting;

export const getIsFetchingWorkflowReports = (state: IRootState) =>
  state.workflows.isFetchingWorkflowReports;

/**
 * Gets the workflow_reports from Redux Store
 * @param {Object} state Redux Store state for Clients
 * @returns {Array} All workflow_reports
 */
export const getAllWorkflowReports = (state: IRootState) =>
  state.workflows.allWorkflowReports;

export const getIsFetchingJobLogs = (state: IRootState) =>
  state.workflows.isFetchingJobLogs;
/**
 * Gets the steps_overview from Redux Store
 * @param {Object} state Redux Store state for Clients
 * @returns {Array} All steps_overview
 */
const getAllJobLogs = (state: IRootState) => state.workflows.stepsOverview;

/**
 * WorkflowReports selector
 * @returns {Array}
 */
export const allWorkflowReportsSelector = createSelector(
  getAllWorkflowReports,
  (allWorkflowReports) => allWorkflowReports
);

export const workflowReportByIdSelector = createSelector(
  getAllWorkflowReports,
  (state: IRootState, reportId: string) => reportId,
  (reports: IWorkflowReportWithStepOverview[], reportId: string) =>
    _.find<IWorkflowReportWithStepOverview>(reports, { id: reportId })
);

export const getIsFetchingActivityVersionSchema = (state: IRootState) =>
  state.workflows.isFetchingActivityVersionSchema;

/**
 * JobLogs selector
 * @returns {Array}
 */
export const stepsOverviewSelector = createSelector(
  getAllJobLogs,
  (stepsOverview) => stepsOverview
);
