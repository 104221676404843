import { Component } from "react";

import {
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  Stepper,
  Typography,
} from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import { withStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import clsx from "clsx";
import {
  CUSTOM_FIELD_TYPE,
  IMetaExpression,
  META_EXPRESSION_SCOPE,
  META_EXPRESSION_TARGET_TYPE,
  TRIGGER_EVENT,
} from "fieldpro-tools/dist/src/types";
import _ from "lodash";
import { connect } from "react-redux";

import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "components/Buttons/CustomButton";
import { getLang } from "containers/authentication/redux/selector";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { allListsSelector } from "containers/lists/redux/selectors";
import { getAllActivities } from "containers/workflows/redux/selectors";
import { getAllWebhooks } from "containers/workflows/redux/webhooks/selectors";
import {
  getJobsFromType,
  TAnyJob,
} from "containers/workflows/subcategories/jobs/Jobscreen/utils";
import { allJobsComposedSelector } from "containers/workflows/subcategories/jobs/redux/selectors";
import * as l from "lang";
import TLang from "model/application/Lang";
import { ICreateEditModalProps } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";
import { TExpressionType } from "model/entities/Expression";
import { ITransformation, STEP_TYPE } from "model/entities/Job";
import { IList } from "model/entities/List";
import { ITrigger } from "model/entities/Trigger";
import { IWebhook } from "model/entities/Webhook";
import { IActivity, IWorkflow } from "model/entities/Workflow";
import IRootState from "redux/store/model";
import { activityNeedsCustomer } from "utils/businessUtils";
import { getLinearProgress, stepHasErrors } from "utils/metaExpressions";

import { MetaExpressionWizardFirstStep } from "./MetaExpressionWizardFirstStep";
import { MetaExpressionWizardFourthStep } from "./MetaExpressionWizardFourthStep";
import { MetaExpressionWizardSecondStep } from "./MetaExpressionWizardSecondStep";
import { MetaExpressionWizardThirdStep } from "./MetaExpressionWizardThirdStep";
import { stepTypeToMeScope } from "./utils/stepTypeToMeScope";

const styles = () => ({
  stepLabel: {
    color: "green",
  },
});

const MyConnector = withStyles({
  root: {
    borderWidth: 1.5,
    borderColor: "#febd55",
    color: "#febd55",
  },
  line: {},
})(StepConnector);

const useMetaStepIconStyles = makeStyles({
  root: {
    color: "#febd55",
  },
  active: {
    color: "#febd55",
  },
  completed: {
    color: "#febd55",
  },
});

function MetaStepIcon() {
  const classes = useMetaStepIconStyles();
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: true,
      })}
    >
      <FiberManualRecordIcon className={classes.completed} />
    </div>
  );
}

export interface IMetaExpressionWizardAttributes extends IMetaExpression {}

export interface IMetaExpressionWizardState extends IMetaExpression {
  _error?: any;
  currentStep: number;
  useDefaultFields: boolean;
  useSecondDefaultFields: boolean;
  subscope: META_EXPRESSION_SCOPE | undefined;
  stepRessourceId?: string;
}

export enum CALLED_FROM {
  LIST = "LIST",
  LIST_ITEM_NAME_TEMPLATE = "LIST_ITEM_NAME_TEMPLATE",
  ACTIVITY = "ACTIVITY",
  ACTIVITY_OPERATION = "ACTIVITY_OPERATION",
  JOB = "JOB",
  JOB_NOTIFICATION = "JOB_NOTIFICATION",
  JOB_TRANSFORMATION = "JOB_TRANSFORMATION",
  WORKFLOW = "WORKFLOW",
  WORKFLOW_SETTINGS = "WORKFLOW_SETTINGS",
  CUSTOMER_TO_USE = "CUSTOMER_TO_USE",
  MATRIX_CELL = "MATRIX_CELL",
  TRIGGER = "TRIGGER",
}

export interface IMetaExpressionWizardAdditionnalProps {
  currentWorkflow?: IWorkflow; // possibly current object in manipulation. Not possible to find it in the store
  currentActivity?: IActivity; // possibly current object in manipulation. Not possible to find it in the store
  currentList?: IList; // possibly current object in manipulation. Not possible to find it in the store
  currentTrigger?: ITrigger;
  matrixTag?: string; // only if we are scoped "ITEM_CELL" or "MATRIX_CELL"
  expressionType?: TExpressionType; // small changes in the options if it's an operation (number only) or a condition
  calledFrom?: CALLED_FROM; // the original place where the input meta expression was called
  type?: CUSTOM_FIELD_TYPE;
  bindedInteg?: ITransformation;
}

export interface IMetaExpressionWizardProps
  extends ICreateEditModalProps<
    IMetaExpressionWizardAdditionnalProps,
    IMetaExpression
  > {
  activities: IActivity[];
  lists: IList[];
  jobs: TAnyJob[];
  webhooks: IWebhook[];
  currentClient: IClient;
  classes: any;
  numberIndex?: number;
  lang: TLang;
}

class MetaExpressionWizard extends Component<
  IMetaExpressionWizardProps,
  IMetaExpressionWizardState
> {
  constructor(props: IMetaExpressionWizardProps) {
    super(props);
    let stepRessourceId: undefined | string = undefined;
    let subscope: META_EXPRESSION_SCOPE | undefined;
    if (props.additionnalProps.currentWorkflow && props.attributes.step) {
      const stepSchema = props.additionnalProps.currentWorkflow.steps.find(
        (s) => s.id === props.attributes.step
      );
      if (stepSchema) {
        stepRessourceId = stepSchema.schema_id;
        switch (stepSchema.type) {
          case STEP_TYPE.TRANSFORMATION: {
            subscope = META_EXPRESSION_SCOPE.TRANSFORMATION;
            break;
          }
          case STEP_TYPE.CREATE_ITEM:
          case STEP_TYPE.EDIT_ITEM:
          case STEP_TYPE.SCRIPT: {
            subscope = META_EXPRESSION_SCOPE.JOB_REPORT;
            break;
          }
        }
      }
    }
    this.state = {
      step: undefined,
      ...props.attributes,
      currentStep: 1,
      useDefaultFields: false,
      useSecondDefaultFields: false,
      subscope,
      stepRessourceId,
      _error: props.errors,
    };
  }

  /**
   * Handles input changes
   * @param {Object} e Event Object
   */
  handleInputChange = (value: any, name: string) => {
    const { onChangeAttributeValues } = this.props;

    let newState: Partial<IMetaExpressionWizardState> = {};

    if (name === "custom_field1") {
      newState = {
        custom_field3: undefined,
        custom_field2: undefined,
        option_tag: undefined,
        field: undefined,
      };
    }

    if (name === "custom_field2") {
      newState = {
        custom_field3: undefined,
        option_tag: undefined,
        field: undefined,
      };
    }

    if (name === "scope") {
      newState = {
        custom_field1: undefined,
        custom_field2: undefined,
        custom_field3: undefined,
        option_tag: undefined,
        cell: undefined,
        field: undefined,
        step: undefined,
        stepRessourceId: undefined,
      };
      // special case if we select the integ mode: it's as if we selected the step also
      if (value === META_EXPRESSION_SCOPE.INTEG_RECORD) {
        newState.stepRessourceId = this.props.additionnalProps.bindedInteg?.id;
      }
    }

    if (name === "useDefaultFields") {
      newState = {
        custom_field1: undefined,
        custom_field2: undefined,
        custom_field3: undefined,
        option_tag: undefined,
        field: undefined,
      };
    }

    if (name === "useSecondDefaultFields") {
      newState = {
        custom_field2: undefined,
        custom_field3: undefined,
        option_tag: undefined,
        field: undefined,
      };
    }

    if (name === "step" && this.props.additionnalProps.currentWorkflow) {
      const selectedWorkflowStep = _.find(
        this.props.additionnalProps.currentWorkflow.steps,
        {
          id: value,
        }
      );
      if (selectedWorkflowStep) {
        const stepType = selectedWorkflowStep.type;
        switch (stepType) {
          case STEP_TYPE.ACTIVITY:
          case STEP_TYPE.TRANSFORMATION:
            newState = {
              stepRessourceId: selectedWorkflowStep.schema_id,
              custom_field1: undefined,
              custom_field2: undefined,
              custom_field3: undefined,
            };
            break;
          default:
            break;
        }
        newState.subscope = stepTypeToMeScope(stepType);
      }
    }
    const cleanValue = value || undefined; // replace "" with undefined so it is not sent to backend
    newState[name] = cleanValue;

    this.setState({
      ...newState,
    } as IMetaExpressionWizardState);

    _.forEach(_.keys(newState), (key) => {
      onChangeAttributeValues(key, newState[key]);
    });
  };

  onChangeDefaultMEValue = (default_value: string) => {
    const { onChangeAttributeValues } = this.props;

    this.setState((state) => ({
      ...state,
      default_value,
    }));

    onChangeAttributeValues("default_value", default_value);
  };

  buildStepper = () => {
    const {
      title,
      scope,
      field,
      currentStep,
      step,
      custom_field1,
      custom_field2,
      option_tag,
      target_type,
    } = this.state;

    const { activities } = this.props;

    const selectedStepForWorkflow = _.find(activities, (a) => a.id === step);
    return (
      <Stepper orientation="vertical" connector={<MyConnector />}>
        <Step active>
          <StepLabel StepIconComponent={MetaStepIcon}>
            <h4>Details</h4>
          </StepLabel>
          <StepContent>
            <Typography>
              Title : <strong>{title}</strong>
            </Typography>
            <Typography>
              Target type : <strong>{target_type}</strong>
            </Typography>
            {scope && (
              <Typography>
                Scope : <strong>{scope}</strong>
              </Typography>
            )}
          </StepContent>
        </Step>

        {currentStep == 2 && (
          <Step active>
            <StepLabel StepIconComponent={MetaStepIcon}>
              <h4>Active workflow report</h4>
            </StepLabel>
            <StepContent>
              <Typography>
                Step :
                <strong>
                  {selectedStepForWorkflow ? selectedStepForWorkflow.name : ""}
                </strong>
              </Typography>
            </StepContent>
          </Step>
        )}

        {/* First level of the 3rd+ step */}
        {currentStep >= 3 && (
          <Step active>
            <StepLabel StepIconComponent={MetaStepIcon}>
              <h4>Field</h4>
            </StepLabel>

            <StepContent>
              {field && (
                <Typography>
                  Metadata : <strong>{field}</strong>
                </Typography>
              )}

              {custom_field1 && (
                <Typography>
                  Custom field : <strong>{custom_field1}</strong>
                </Typography>
              )}
            </StepContent>

            {option_tag && !custom_field2 && (
              <StepContent>
                <Typography>
                  Option : <strong>{option_tag}</strong>
                </Typography>
              </StepContent>
            )}
          </Step>
        )}

        {/* Second level of the 3rd+ step */}
        {currentStep >= 3 && custom_field2 && (
          <Step active>
            <StepLabel StepIconComponent={MetaStepIcon}>
              <h4>Sub-Field</h4>
            </StepLabel>

            <StepContent>
              {field && (
                <Typography>
                  Metadata : <strong>{field}</strong>
                </Typography>
              )}

              {custom_field1 && (
                <Typography>
                  Custom field : <strong>{custom_field2}</strong>
                </Typography>
              )}
            </StepContent>

            {option_tag && (
              <StepContent>
                <Typography>
                  Option : <strong>{option_tag}</strong>
                </Typography>
              </StepContent>
            )}
          </Step>
        )}
      </Stepper>
    );
  };

  render() {
    const {
      title,
      scope,
      field,
      currentStep,
      custom_field1,
      custom_field2,
      custom_field3,
      step,
      option_tag,
      default_value,
      useDefaultFields,
      useSecondDefaultFields,
      subscope,
      target_type,
      target_content,
      stepRessourceId,
      disabled,
      verified,
    } = this.state;

    const {
      lang,
      additionnalProps: {
        calledFrom,
        expressionType,
        currentWorkflow,
        currentActivity,
        currentList,
        currentTrigger,
        matrixTag,
        bindedInteg,
      },
      currentClient,
      activities,
      lists,
      jobs,
      webhooks,
      errors,
      numberIndex,
    } = this.props;

    const shouldMarkError = (field: any) => {
      const hasError = errors[field];
      return hasError;
    };

    const labelToDisplay =
      lang.containers.workflows.subCategories.metaExpressions.createEditModal;

    const includeCustomerScope = currentActivity
      ? activityNeedsCustomer(currentActivity)
      : false;

    const getRessourceOfCurrentTrigger = () => {
      switch (currentTrigger?.event_type) {
        case TRIGGER_EVENT.WEBHOOK_EVENT:
          return currentTrigger?.webhook_id;
        case TRIGGER_EVENT.FIELDPRO_CREATE_ITEM:
        case TRIGGER_EVENT.FIELDPRO_EDIT_ITEM:
          return currentTrigger?.list_id;
        case TRIGGER_EVENT.FIELDPRO_CREATE_MOBILE_USER:
        default:
          return undefined;
      }
    };
    const currentRessourceId = stepRessourceId
      ? stepRessourceId
      : currentTrigger
      ? getRessourceOfCurrentTrigger()
      : currentActivity
      ? currentActivity.id
      : currentList
      ? currentList.id
      : undefined;

    return (
      <Grid container spacing={0}>
        <Grid item={true} xs={4}>
          {this.buildStepper()}
        </Grid>
        <Grid item={true} xs={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item={true} xs={7}>
          <LinearProgress
            variant="determinate"
            value={getLinearProgress(currentStep, scope, target_type)}
          />
          <br />

          {currentStep === 1 && (
            <MetaExpressionWizardFirstStep
              scope={scope}
              shouldMarkError={shouldMarkError}
              labelToDisplay={labelToDisplay}
              title={title ?? ""}
              target_type={target_type}
              target_content={target_content}
              calledFrom={calledFrom}
              includeCustomerScope={includeCustomerScope}
              includeIntegRecordScope={bindedInteg ? true : false}
              onChange={this.handleInputChange}
              sqlQueryStatus={{
                disabled,
                verified,
              }}
            />
          )}

          {currentStep === 2 && (
            <MetaExpressionWizardSecondStep
              step={step}
              currentWorkflow={currentWorkflow}
              shouldMarkError={shouldMarkError}
              labelToDisplay={labelToDisplay}
              onChange={this.handleInputChange}
            />
          )}

          {currentStep === 3 && (
            <MetaExpressionWizardThirdStep
              step={step}
              scope={stepRessourceId && subscope ? subscope : scope}
              subscope={subscope}
              calledFrom={calledFrom}
              useDefaultFields={useDefaultFields}
              useSecondDefaultFields={useSecondDefaultFields}
              currentRessourceId={currentRessourceId}
              mobileUserRoleOptions={(
                currentClient.mobile_access_right_profiles ?? []
              ).map((m) => ({
                key: m.name,
                label: m.name,
              }))}
              // If currentActivity exists, replace the matching activity in the array
              activities={_.map(activities, (activity) => {
                return activity.id === currentActivity?.id
                  ? currentActivity
                  : activity;
              })}
              // Same with currentList
              lists={_.map(lists, (list) =>
                list.id === currentList?.id ? currentList : list
              )}
              webhooks={_.map(webhooks, (webhook) =>
                webhook.id === currentTrigger?.id ? currentTrigger : webhook
              )}
              transformations={
                getJobsFromType(
                  jobs,
                  STEP_TYPE.TRANSFORMATION
                ) as ITransformation[]
              }
              numberIndex={numberIndex}
              expressionType={expressionType}
              custom_field1={custom_field1}
              custom_field2={custom_field2}
              custom_field3={custom_field3}
              option_tag={option_tag}
              field={field}
              shouldMarkError={shouldMarkError}
              labelToDisplay={labelToDisplay}
              // calledFromMatrixTag should only have a value if we chose MATRIX_CELL scope
              calledFromMatrixTag={
                calledFrom === CALLED_FROM.MATRIX_CELL ||
                scope === META_EXPRESSION_SCOPE.MATRIX_CELL ||
                scope === META_EXPRESSION_SCOPE.ITEM_CELL
                  ? matrixTag
                  : undefined
              }
              onChange={this.handleInputChange}
              type={this.props.additionnalProps.type}
              triggerEvent={currentTrigger?.event_type}
            />
          )}

          {currentStep === 4 && (
            <MetaExpressionWizardFourthStep
              defaultMEValue={default_value}
              onChangeDefaultMEValue={this.onChangeDefaultMEValue}
            />
          )}

          <Grid container style={{ paddingBottom: "8px" }}>
            {currentStep !== 1 && (
              <CustomButton
                type={BUTTON_TYPES_OUTSIDE_TABLE.DEFAULT}
                name={"back"}
                style={{ marginRight: "8px" }}
                onClick={() => {
                  let newStep = currentStep - 1;
                  if (
                    scope !== META_EXPRESSION_SCOPE.WORKFLOW_REPORT &&
                    newStep == 2
                  ) {
                    newStep = 1;
                  }
                  this.setState({
                    currentStep: newStep,
                    useDefaultFields: field ? true : false,
                  });
                }}
              >
                Back
              </CustomButton>
            )}

            {currentStep !== 4 &&
              target_type &&
              ![
                META_EXPRESSION_TARGET_TYPE.SQL,
                META_EXPRESSION_TARGET_TYPE.TEXT,
              ].includes(target_type) && (
                <CustomButton
                  type={BUTTON_TYPES_OUTSIDE_TABLE.DEFAULT}
                  name={"next"}
                  onClick={() => {
                    let newStep = currentStep + 1;
                    if (
                      scope !== META_EXPRESSION_SCOPE.WORKFLOW_REPORT &&
                      newStep == 2
                    ) {
                      newStep = newStep + 1;
                    }

                    this.setState({
                      currentStep: newStep,
                      useDefaultFields: field ? true : false,
                    });
                  }}
                  disabled={stepHasErrors({ scope, currentStep, errors })}
                >
                  Next
                </CustomButton>
              )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state: IRootState) {
  return {
    activities: getAllActivities(state), // we read the activities directly from the store. /!\ the "currentActivity" needs to be replaced!
    lists: allListsSelector(state), // we read the lists directly from the store. /!\ the "currentList" needs to be replaced!
    jobs: allJobsComposedSelector(state), // we read the transformations directly from the store.
    webhooks: getAllWebhooks(state),
    currentClient: getSelectedClient(state),
    lang: l[getLang(state)],
  };
}

/* istanbul ignore next */
export default withStyles(styles as any)(
  connect(mapStateToProps)(MetaExpressionWizard)
);
