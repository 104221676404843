import { IMetaExpression, Query } from "fieldpro-tools";
import _ from "lodash";

import TLang from "model/application/Lang";

import { replaceMePointersWithTags } from "../../utils/replaceMePointersWithTags";
import { replaceHTMLTags } from "./replaceHTMLTags";
import { replaceQueryPointersWithTags } from "./replaceQueryPointersWithTags";

interface IPrepareCodeForTagify {
  code: string;
  queries: Query[];
  metaExpressions: IMetaExpression[];
  lang?: TLang;
}
/* -------------------------------------------------------------------------- */
/*                            prepareCodeForTagify                            */
/* -------------- format the html code to be readable by tagify ------------- */
/* -------------------- 1 - replaceQueryPointersWithTags -------------------- */
/* -------------------- 2 - replaceMePointersWithTags ----------------------- */
/* -------------------- 3 - replaceHTMLTags --------------------------------- */
/* -------------------- 4 - trimEnd ----------------------------------------- */
/* -------------------------------------------------------------------------- */
// 1 - $queryname$ --> [[query : actual query ,value : queryname, ...anykey]]
// 2 - ${meta expression} --> [[expression : expression, .... ]]
// 3 - tagify doesnt like '<' and '>' , so we change it to &gt; and &lt;
// 4 - remove weird trailing space at the end

export function prepareCodeForTagify({
  code,
  queries,
  metaExpressions,
  lang,
}: IPrepareCodeForTagify) {
  return _.trimEnd(
    replaceHTMLTags(
      replaceQueryPointersWithTags(
        replaceMePointersWithTags(code, metaExpressions),
        queries,
        lang
      )
    )
  );
}
