import React, { ComponentProps } from "react";

import { Box, IconButton } from "@material-ui/core";
import _ from "lodash";

interface ICustomControlButtonProps {
  icon: React.ReactNode;
  onClick: ComponentProps<typeof IconButton>["onClick"];
  buttonProps?: Partial<ComponentProps<typeof IconButton>>;
}
function ControlButton({
  icon,
  onClick,
  buttonProps,
}: ICustomControlButtonProps) {
  return (
    <Box>
      <IconButton
        style={{
          backgroundColor: "white",
          borderRadius: "15%",
          height: "29px",
          width: "29px",
          boxShadow: `rgb(108, 108, 108,0.2) 0px 0px 0px 2px`,
          ...(buttonProps?.style ?? {}),
        }}
        onClick={onClick}
        {..._.omit(buttonProps || {}, "style")}
      >
        {icon}
      </IconButton>
    </Box>
  );
}

export default ControlButton;
