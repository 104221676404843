import React from "react";

import _ from "lodash";

import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";

export const useHooks = (filter: IFilter) => {
  const [checkedOptions, setCheckedOptions] = React.useState<string[]>(
    filter.value
  );
  const [options, setOptions] = React.useState<IOption[]>(filter.options || []);

  const handleCheckboxChange = (opt: string, value: boolean) => {
    //if checkbox is selected
    if (value) {
      setCheckedOptions([opt]);
    } else {
      //remove the unselected filter
      setCheckedOptions(checkedOptions.filter((f: string) => f !== opt));
    }
  };

  //search
  const onChangeSearch = (search: string) => {
    if (!filter.options) {
      setOptions([]);
      return;
    }

    setOptions(
      search.length > 0
        ? _.filter(filter.options, (o) =>
            _.includes(_.toLower(o.label), _.toLower(search))
          )
        : filter.options
    );
  };

  return {
    checkedOptions,
    onChangeSearch,
    handleCheckboxChange,
    setCheckedOptions,
    options,
  };
};
