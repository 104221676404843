import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { BarDatumWithColor, ComputedDatum, ResponsiveBar } from "@nivo/bar";
import {
  interpolateBlues,
  interpolateBrBG,
  interpolateBuGn,
  interpolateBuPu,
  interpolateCividis,
  interpolateCool,
  interpolateCubehelixDefault,
  interpolateGnBu,
  interpolateGreens,
  interpolateGreys,
  interpolateInferno,
  interpolateMagma,
  interpolateOranges,
  interpolateOrRd,
  interpolatePiYG,
  interpolatePlasma,
  interpolatePRGn,
  interpolatePuBu,
  interpolatePuBuGn,
  interpolatePuOr,
  interpolatePuRd,
  interpolatePurples,
  interpolateRainbow,
  interpolateRdBu,
  interpolateRdGy,
  interpolateRdPu,
  interpolateRdYlBu,
  interpolateRdYlGn,
  interpolateReds,
  interpolateSinebow,
  interpolateSpectral,
  interpolateTurbo,
  interpolateViridis,
  interpolateWarm,
  interpolateYlGn,
  interpolateYlGnBu,
  interpolateYlOrBr,
  interpolateYlOrRd,
} from "d3-scale-chromatic";
import _, { clone, isNumber } from "lodash";

import { teal } from "assets/colors";
import { IKPI, IScale, KPI_TYPE } from "model/entities/Dashboard";

import Legend, { ILegend } from "../Legend/Legend";
import Axes from "./Axes";
import Chart, { PERCENTAGE_KEY } from "./Chart";
import { ChartDataUtils } from "./ChartDataUtils";
import ChartTick from "./ChartTick";
import Colors from "./Colors";
import styles from "./styles";

const lineGraphSettings = {
  theme: {
    axis: {
      textColor: teal,
      fontSize: 12,
      tickColor: teal,
      legendColor: teal,
      legendFontSize: 12,
    },
    tooltip: {
      container: { padding: null },
    },
  },
};

export interface ICustomBarChartProps {
  classes?: any;
  chart: IKPI;
  nivoConfiguration: any;
  uid: string;
  thousandSeparator?: string;
}

interface ICustomBarChartState {
  legends: ILegend[];
}

export class CustomBarChart extends React.Component<
  ICustomBarChartProps,
  ICustomBarChartState
> {
  private hTop: string | undefined;

  constructor(props: ICustomBarChartProps) {
    super(props);
    this.state = {
      legends: [],
    };
  }
  /**
   * Formats data to be compatible with Nivo bar chart type
   * See https://nivo.rocks/bar/
   */
  formatData = (chart: IKPI) => {
    let result: any[] = [];
    let concatData: any[] = [];
    if (ChartDataUtils.is3DChart(chart)) {
      chart.data.forEach((item: any) => {
        if (item.data) {
          item.data = item.data.map((pointValue: any) => {
            const point = result.find((r) => r.x === pointValue.x);
            const pointKey = item.title ? item.title : item.label;
            if (point) {
              point[pointKey] = pointValue.y;
            } else {
              const obj = { x: pointValue.x };
              obj[pointKey] = pointValue.y;
              result.push(obj);
            }
            return pointValue;
          });
          concatData = concatData.concat(item.data);
        }
      });
    } else {
      result = chart.data;
    }
    if (chart.is_percentage && chart.type !== KPI_TYPE.STACKED_BAR_CHART) {
      result = chart.data.map((point: any) =>
        this.addPercentageValueForPoint(point, chart)
      );
    }
    return result;
  };

  private addPercentageValueForPoint(pointData: any, chart: IKPI): any {
    // Adds percentage in addition of existing point values
    if (!pointData) {
      return;
    }
    const matchingKeys = Object.keys(pointData).filter(
      (d) => d !== "x" && d !== PERCENTAGE_KEY
    );
    let sum: any = matchingKeys
      .map((key) => pointData[key])
      .reduce((acc: any, cur: any) => acc + cur, 0);
    sum = isNumber(sum) ? sum : 100;
    let percentage = 0;
    if (chart.type !== KPI_TYPE.BAR_CHART_HORIZONTAL) {
      percentage = sum <= 100 ? 100 - sum : 0;
    }
    return {
      ...pointData,
      percentage: percentage,
    };
  }

  /**
   * Returns the maximum number of values per set
   */
  getMaxNumberValuesPerItem = (data: any[]) => {
    if (data) {
      const values = data.map((item: any) => {
        const newItem = Object.assign({}, item);
        delete newItem.x;
        return newItem;
      });
      return values.reduce(
        (acc, curr) =>
          Object.keys(curr).length > acc ? Object.keys(curr).length : acc,
        0
      );
    } else {
      return 0;
    }
  };

  /**
   * Determines the max value of the dataset and multiply by the given factor or 1 if not
   */
  getScaleValue = (chart: IKPI, factor: number): IScale => {
    factor = factor ? factor : 1;
    const scale: IScale = { maxValue: 0, minValue: 0 };
    if (chart && chart.data) {
      const data: any[] = chart.data;
      if (chart.type !== KPI_TYPE.STACKED_BAR_CHART) {
        scale.maxValue =
          data.reduce((acc, curr) => {
            return acc > curr.y ? acc : curr.y;
          }, -Infinity) * factor;
        scale.minValue =
          data.reduce((acc, curr) => {
            return acc < curr.y ? acc : curr.y;
          }, +Infinity) * factor;
      } else if (
        chart.type === KPI_TYPE.STACKED_BAR_CHART &&
        !chart["grouped_mode"]
      ) {
        const values: { y: number }[] = data.map((item) => {
          const newItem = Object.assign({}, item);
          delete newItem.x;
          return newItem;
        });
        let maxValue = 0;
        let minValue = 0;
        values.forEach((item) => {
          const itemValues = Object.values(item);
          let maxResult = 0;
          let minResult = 0;
          if (itemValues && itemValues.length > 0) {
            const max = Math.max(...itemValues);
            const min = Math.min(...itemValues);
            if (max > 0) {
              //Get only positive values
              const values = itemValues.filter((number) => number > 0);
              maxResult = values.reduce(
                (acc: number, curr: number) => curr + acc
              );
            }
            if (min < 0) {
              //Get only negative values
              const values = itemValues.filter((number) => number < 0);
              minResult = values.reduce(
                (acc: number, curr: number) => curr - acc
              );
            }
            maxValue = maxValue > maxResult ? maxValue : maxResult;
            minValue = minValue < minResult ? minValue : minResult;
          }
        });
        scale.maxValue = maxValue * factor;
        scale.minValue = minValue * factor;
      }
    }
    if (chart.is_percentage) {
      scale.maxValue = Math.ceil(scale.maxValue / 10) * 10;
    }
    scale.minValue = scale.minValue > 0 ? 0 : scale.minValue;
    return scale;
  };

  private getColors(chart: IKPI<any>, colors: any) {
    const percentageColors = [teal];
    if (chart.is_percentage && chart.type !== KPI_TYPE.STACKED_BAR_CHART) {
      percentageColors.push(Colors.interpolateHexColors("#fff", teal, 0.4));
      return percentageColors;
    }
    return colors;
  }

  /**
   * Returns all unique keys
   */
  getKeys = (chart: IKPI): string[] => {
    const keys: string[] = [];
    if (chart && chart.type !== KPI_TYPE.STACKED_BAR_CHART) {
      return ["y", PERCENTAGE_KEY];
    }
    if (chart && chart.data) {
      chart.data.forEach((item: { x: number; y: number }) => {
        Object.keys(item).forEach((key) => {
          if (keys.findIndex((index) => key === index) < 0 && key !== "x") {
            keys.push(key);
          }
        });
      });
    }
    return keys;
  };

  componentDidMount() {
    setTimeout(() => {
      const barChartUid = `barChartContainer${this.props.uid}`;
      const result = this.updateLabels(barChartUid);
      const elem = document.getElementById(barChartUid);
      let svgChartElement;
      // Applies the top correction for horizontal barchart
      if (elem) {
        svgChartElement = elem.firstElementChild;
        if (svgChartElement && this.hTop) {
          svgChartElement.setAttribute(
            "style",
            `position: relative; top: ${this.hTop}`
          );
        }
      }
      this.setState({
        legends: result,
      });
    }, 20);
  }

  private updateLabels(uid: string): ILegend[] {
    return ChartDataUtils.updateLabels(uid);
  }

  render() {
    this.hTop = undefined;
    const { uid, chart, nivoConfiguration } = this.props;
    const colors = ChartDataUtils.getChartColors(chart.data);
    const modifiedChart: IKPI = clone(chart);

    modifiedChart.data = this.formatData(modifiedChart);

    const legends = this.state ? this.state.legends : [];
    const keys = this.getKeys(modifiedChart);
    const scaleFactor = modifiedChart.is_percentage ? 1 : 1.2;
    const scale: IScale = this.getScaleValue(modifiedChart, scaleFactor);
    const yScale = Axes.getScale(
      nivoConfiguration.axeYType,
      modifiedChart.is_percentage ? 0 : nivoConfiguration.maxValue,
      modifiedChart.is_percentage ? 100 : nivoConfiguration.maxValue
    );
    let axisBottom: any = Chart.defaultAxisBottom(nivoConfiguration.axeXType);
    axisBottom = nivoConfiguration.enableXAxis
      ? nivoConfiguration.tickValues
        ? Object.assign(axisBottom, {
            tickValues: nivoConfiguration.tickValues,
          })
        : axisBottom
      : null;
    const enableYAxis = nivoConfiguration.enableYAxis;

    let height = Chart.getChartHeight(modifiedChart.type);
    const xCount = ChartDataUtils.countXValues(modifiedChart);

    const useCustomColors =
      ChartDataUtils.hasColorField(modifiedChart.data) ||
      (colors && colors.length > 0);

    if (
      modifiedChart.type === KPI_TYPE.BAR_CHART_HORIZONTAL &&
      ChartDataUtils.countXValues(modifiedChart) >
        Chart.settings[KPI_TYPE.BAR_CHART_HORIZONTAL].overflowActivationCount
    ) {
      height = xCount * 16;
      /* Nivo creates a large empty space at the top of the horizontal barcharts
      and above de bottom axis when there are a lot of values.
      This function has been empirically determined in Excel and aims to find
      the right top position according to the number of values */
      this.hTop = `${-Math.floor(0.4939 * xCount - 18)}px`;
    } else if (modifiedChart.type === KPI_TYPE.STACKED_BAR_CHART) {
      this.hTop = `${-Math.floor(0.4939 * xCount - 18)}px`;
    }

    const margin = Chart.settings[modifiedChart.type].margin
      ? Chart.settings[modifiedChart.type].margin
      : Chart.defaultMargin;
    margin.top =
      (modifiedChart.is_percentage &&
        modifiedChart.type === KPI_TYPE.BAR_CHART) ||
      modifiedChart.type === KPI_TYPE.STACKED_BAR_CHART
        ? 0
        : margin.top;
    margin.right =
      modifiedChart.is_percentage &&
      modifiedChart.type === KPI_TYPE.BAR_CHART_HORIZONTAL
        ? 16
        : margin.right;
    let groupMode;
    if (modifiedChart.type === KPI_TYPE.STACKED_BAR_CHART) {
      groupMode = modifiedChart["grouped_mode"] ? "grouped" : "stacked";
    }

    if (modifiedChart.type === KPI_TYPE.BAR_CHART_HORIZONTAL) {
      modifiedChart.data = _.sortBy(modifiedChart.data, "y");
    }

    const d3ScaleChromaticMap = {
      brown_blueGreen: interpolateBrBG,
      purpleRed_green: interpolatePRGn,
      pink_yellowGreen: interpolatePiYG,
      purple_orange: interpolatePuOr,
      red_blue: interpolateRdBu,
      red_grey: interpolateRdGy,
      red_yellow_blue: interpolateRdYlBu,
      red_yellow_green: interpolateRdYlGn,
      spectral: interpolateSpectral,
      blues: interpolateBlues,
      greens: interpolateGreens,
      greys: interpolateGreys,
      oranges: interpolateOranges,
      purples: interpolatePurples,
      reds: interpolateReds,
      turbo: interpolateTurbo,
      viridis: interpolateViridis,
      inferno: interpolateInferno,
      magma: interpolateMagma,
      plasma: interpolatePlasma,
      cividis: interpolateCividis,
      warm: interpolateWarm,
      cool: interpolateCool,
      cubehelixDefault: interpolateCubehelixDefault,
      blue_green: interpolateBuGn,
      blue_purple: interpolateBuPu,
      green_blue: interpolateGnBu,
      orange_red: interpolateOrRd,
      purple_blue_green: interpolatePuBuGn,
      purple_blue: interpolatePuBu,
      purple_red: interpolatePuRd,
      red_purple: interpolateRdPu,
      yellow_green_blue: interpolateYlGnBu,
      yellow_green: interpolateYlGn,
      yellow_orange_brown: interpolateYlOrBr,
      yellow_orange_red: interpolateYlOrRd,
      rainbow: interpolateRainbow,
      sinebow: interpolateSinebow,
      default: interpolateBlues,
    };

    const customColors = () => {
      if (modifiedChart.type === KPI_TYPE.STACKED_BAR_CHART) {
        return colors?.map((item) => item.color);
      } else {
        return (d: ComputedDatum<BarDatumWithColor>) => d.data.color;
      }
    };

    const responsiveBarProps = {
      theme: lineGraphSettings.theme,
      groupMode,
      data: modifiedChart.data,
      colors: useCustomColors ? customColors() : undefined,
      margin: margin,
      keys: keys,
      indexBy: "x",
      height,
      layout: nivoConfiguration.direction
        ? nivoConfiguration.direction
        : "vertical",
      colorBy:
        modifiedChart.type === KPI_TYPE.STACKED_BAR_CHART ||
        modifiedChart.is_percentage
          ? "id"
          : "index",
      xScale: Axes.getScale(nivoConfiguration.axeXType),
      yScale: yScale,
      axisTop: null,
      axisRight: null,
      axisBottom: axisBottom
        ? Object.assign(axisBottom, {
            format: (value: any) => {
              return Axes.formatAxisValue(
                value,
                nivoConfiguration.axeXType,
                nivoConfiguration.thousandSeparator,
                undefined,
                modifiedChart.is_percentage &&
                  modifiedChart.type === KPI_TYPE.BAR_CHART_HORIZONTAL
                  ? "%"
                  : "",
                modifiedChart
              );
            },
            ...(modifiedChart.x_axis_title &&
              modifiedChart.x_axis_title.length != 0 && {
                legend: modifiedChart.x_axis_title,
                legendPosition: "middle",
                legendOffset: 35,
              }),
            tickSize: 0,
          })
        : null,
      labelSkipWidth: 12,
      labelSkipHeight: 15,
      enableGridX: false,
      enableGridY: false,
      enableLabel: true,
      label: function (point: { id: string; value: string }) {
        if (point.id === PERCENTAGE_KEY) {
          return;
        }
        return modifiedChart.is_percentage ? `${point.value}%` : point.value;
      },
      labelTextColor: "#fff",
      axisLeft: enableYAxis
        ? {
            ...Chart.defaultAxisLeft,
            ...(modifiedChart.value_format &&
              modifiedChart.type === KPI_TYPE.BAR_CHART &&
              modifiedChart.value_format.enabled && {
                format: modifiedChart.value_format?.format,
              }),
            ...(modifiedChart.type != KPI_TYPE.BAR_CHART && {
              renderTick: (props: any) => {
                const maxLineLength =
                  modifiedChart.type === KPI_TYPE.BAR_CHART_HORIZONTAL ? 16 : 8;
                return <ChartTick {...props} maxLineLength={maxLineLength} />;
              },
            }),
            ...(modifiedChart.y_axis_title &&
              modifiedChart.y_axis_title.length != 0 && {
                legend: modifiedChart.y_axis_title,
                legendPosition: "middle",
                legendOffset: -80,
              }),
          }
        : null,
      enablePointLabel: !enableYAxis,
      pointLabel: function (e: { x: string; y: string }) {
        const yFormatted = ChartDataUtils.formatValue(
          e.y,
          nivoConfiguration.thousandSeparator
        );
        return yFormatted;
      },
      legends:
        modifiedChart.type !== KPI_TYPE.STACKED_BAR_CHART
          ? []
          : Chart.multiLegendSettings,
      animate: false,
    };
    if (!modifiedChart.grouped_mode) {
      responsiveBarProps["maxValue"] = scale.maxValue;
      responsiveBarProps["minValue"] = scale.minValue;
    }
    if (!height) delete responsiveBarProps.height;
    if (modifiedChart.heatmap_color) {
      if (modifiedChart.type != KPI_TYPE.STACKED_BAR_CHART) {
        const f = d3ScaleChromaticMap[modifiedChart.heatmap_color];
        const maxValue = Math.max(...modifiedChart.data.map((o: any) => o.y));
        const minValue = Math.min(...modifiedChart.data.map((o: any) => o.y));
        if (!useCustomColors) {
          responsiveBarProps["colors"] = (bar: any) => {
            return f((bar.value - minValue) / (maxValue - minValue));
          };
        }
      } else {
        if (!useCustomColors) {
          responsiveBarProps["colors"] = modifiedChart.grouped_mode
            ? _.range(0.2, 1, 0.1).map((n) =>
                d3ScaleChromaticMap[modifiedChart.heatmap_color ?? ""]?.(n)
              )
            : nivoConfiguration.colors;
        }
      }
    } else {
      if (!useCustomColors) {
        responsiveBarProps["colors"] =
          modifiedChart.type === KPI_TYPE.BAR_CHART_HORIZONTAL &&
          modifiedChart.is_percentage
            ? teal
            : nivoConfiguration.colors;
      }
    }

    return (
      <>
        {legends && modifiedChart.type === KPI_TYPE.STACKED_BAR_CHART && (
          <div id={`legendContainer${uid}`}>
            <Legend legends={colors ?? legends}></Legend>
          </div>
        )}
        <span id={`barChartContainer${uid}`}>
          <ResponsiveBar
            {...(responsiveBarProps as any)}
            valueFormat={
              modifiedChart.value_format?.enabled
                ? modifiedChart.value_format.format
                : (e: any) =>
                    typeof e === "number"
                      ? ChartDataUtils.addThousandSeparator(
                          e,
                          nivoConfiguration.thousandSeparator
                        )
                      : e
            }
            label={(e: any) => e.formattedValue}
            innerPadding={modifiedChart.grouped_mode ? 2 : undefined}
          />
        </span>
      </>
    );
  }
}

export default withStyles(styles)(CustomBarChart);
