export function getMobileUserFieldOptions() {
  const mobileUserFieldOptions = [
    { key: "id", label: "id" },
    { key: "phone", label: "phone" },
    { key: "first_name", label: "first_name" },
    { key: "middle_name", label: "middle_name" },
    { key: "last_name", label: "last_name" },
    { key: "email", label: "email" },
    { key: "created_at", label: "created_at" },
    { key: "created_by", label: "created_by" },
    { key: "imei", label: "imei" },
    { key: "licensed", label: "licensed" },
    { key: "licence_expiracy_date", label: "licence_expiracy_date" },
    { key: "picture", label: "picture" },
    { key: "birthday", label: "birthday" },
    { key: "start_date", label: "start_date" },
    { key: "internal_id", label: "internal_id" },
    { key: "official_id", label: "official_id" },
    { key: "id_picture", label: "id_picture" },
    { key: "gender", label: "gender" },
    { key: "role", label: "role" },
    { key: "last_pull_sync", label: "last_pull_sync" },
    { key: "last_push_sync", label: "last_push_sync" },
    { key: "app_version", label: "app_version" },
    { key: "registration_token", label: "registration_token" },
  ];

  return mobileUserFieldOptions;
}
